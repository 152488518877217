/* CARD SCROLLER*/
.device-card-wrap {
	position: relative;
}

.device-card--slider-enabled .device-card-wrap {
	padding: 0 40px;
}

.device-card__content {
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.device-card--slider-enabled .device-card__content {
	padding-bottom: 20px;
}

.device-card-controls-prev {
	background: var(--modul-border);
	background-clip: content-box;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
	display: none;
	height: 60px;
	left: 0;
	margin: -50px 0 0 0;
	padding: 6px 6px 6px 0;
	position: absolute;
	text-indent: -9999px;
	top: 50%;
	width: 30px;
	z-index: 5;

	@media (--desktop-xl) {
		padding: 0;
	}

	&:hover {
		@media (--desktop-xl) {
			background: var(--modul-border-hover);
		}
	}

	&:active {
		@media (--desktop-xl) {
			background: var(--modul-border-active);
		}
	}
}

.device-card--slider-enabled .device-card-controls-prev {
	display: block;
}

.device-card-controls-prev::after {
	border: solid 2px #fff;
	border-right: none;
	border-top: none;
	content: '';
	display: block;
	height: 9px;
	left: 50%;
	margin: -5px 0 0 -6px;
	position: absolute;
	top: 50%;
	transform: rotate(45deg);
	width: 9px;

	@media (--desktop-xl) {
		height: 18px;
		margin-top: -9px;
		width: 18px;
	}
}

.device-card-controls-next {
	background: var(--modul-border);
	background-clip: content-box;
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
	display: none;
	height: 60px;
	margin: -50px 0 0 0;
	padding: 6px 0 6px 6px;
	position: absolute;
	right: 0;
	text-indent: -9999px;
	top: 50%;
	width: 30px;
	z-index: 5;

	@media (--desktop-xl) {
		padding: 0;
	}

	&:hover {
		@media (--desktop-xl) {
			background: var(--modul-border-hover);
		}
	}

	&:active {
		@media (--desktop-xl) {
			background: var(--modul-border-active);
		}
	}
}

.device-card--slider-enabled .device-card-controls-next {
	display: block;
}

.device-card-controls-next::after {
	border: solid 2px #fff;
	border-bottom: none;
	border-left: none;
	content: '';
	display: block;
	height: 9px;
	margin: -5px -6px 0 0;
	position: absolute;
	right: 50%;
	top: 50%;
	transform: rotate(45deg);
	width: 9px;

	@media (--desktop-xl) {
		height: 18px;
		margin-top: -9px;
		width: 18px;
	}
}

.device-card-scrollbar {
	background: var(--modul-border);
	display: none;
	height: 1px;
	margin: 0 -5px;
	position: relative;
}

.device-card--slider-enabled .device-card-scrollbar {
	display: block;
}

.swiper-scrollbar-drag {
	border-radius: 4px;
	cursor: grab;
	display: block;
	height: 2.5rem;
	margin: -1.25rem 0 0 0;
	position: absolute;
	top: 50%;

	&::before {
		background: var(--modul-border);
		border-radius: 3px;
		content: '';
		display: block;
		height: 5px;
		left: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all 0.3s ease;
		width: 100%;
	}

	&:hover {
		&::before {
			background: var(--modul-border-hover);
			height: 9px;
		}
	}

	&:active {
		&::before {
			background: var(--modul-border-active);
			height: 9px;
		}
	}
}

.device-card .swiper-wrapper {
	flex-direction: row;

	@media (--tabletOnly) {
		flex-wrap: nowrap;
	}
}

/* CARD SCROLLER*/
