/* SERVICE CARD D (MODIFIER CLASS FOR A MOBILE VIEW WITHOUT IMG)*/

.device-card-item--a.device-card--service-d {
	@media (--mobileOnly) {
		text-align: left;
	}
}

.device-card-item--a.device-card--service-d .device-card-item__text-wrap {
	flex-basis: 100%;

	@media (--mobileOnly) {
		flex-grow: 0;
		flex-shrink: 1;
		padding: 0 1rem 0 0;
	}
}

.device-card-item--a.device-card--service-d .device-card-item__img-wrap + .device-card-item__text-wrap {
	@media (--mobileOnly) {
		padding: 0 1rem 0 1.5rem;
	}
}

.device-card-item--a.device-card--service-d .device-card-item__text-wrap-top {
	@media (--mobileOnly) {
		flex: 1 1 auto;
	}
}

.device-card-item--a.device-card--service-d .device-card-item__text-wrap-bottom {
	@media (--mobileOnly) {
		align-items: flex-end;
		justify-content: flex-end;
		width: 100%;
	}
}

/*SLIDER*/
.device-card--slider .device-card-item--a.device-card--service-d {
	@media (--tabletOnly) {
		text-align: left;
	}
}

.device-card--slider .device-card-item--a.device-card--service-d .device-card-item__text-wrap {
	@media (--tabletOnly) {
		padding: 0;
	}
}
.device-card--slider .device-card-item--a.device-card--service-d .device-card-item__text-wrap-bottom > div {
	@media (--tabletOnly) {
		align-items: flex-start;
	}
}

.device-card--slider .device-card-item--a.device-card--service-d *[class*='btn-2'],
.device-card--slider .device-card-item--a.device-card--service-d *[class*='btn-2-5'],
.device-card--slider .device-card-item--a.device-card--service-d *[class*='btn-3'] {
	@media (--tabletOnly) {
		align-self: flex-start;
		margin: 0;
	}
}
/*SLIDER*/

/* SERVICE CARD D (MODIFIER CLASS FOR A MOBILE VIEW WITHOUT IMG)*/
