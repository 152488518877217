.header-promo {
	overflow: hidden;
}

.header-promo__background {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.header-promo__background object {
	height: 100%;
	width: 100%;
}

.header-promo .row {
	@media (--tablet) {
		align-content: stretch;
		align-items: stretch;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
}

.header-promo__text-col {
	align-content: stretch;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 216px;
	padding: 1.5rem;

	.parent-container & {
		padding-left: 0;
		padding-right: 0;
	}

	& *:last-child {
		margin-bottom: 0;
	}

	@media (--tablet) {
		flex: 1 1 50%;
	}

	@media (--desktop) {
		padding: 1.75rem 1.5rem;

		.parent-container & {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.module-link-single {
		display: table;
	}
}

/* Facebook like gomb*/

.header-promo--social-like {
	.header-promo__title {
		padding-right: 0;

		@media (--tablet) {
			padding-right: 9.7rem;
		}
		@media (--desktop) {
			padding-right: 9.76rem;
		}
		@media (--desktop-xl) {
			padding-right: 9.76rem;
		}
	}
}

.header-promo--wide.header-promo--social-like {
	.header-promo__title {
		padding-right: 0;

		@media (--tablet) {
			padding-right: 9.7rem;
		}

		@media (--desktop) {
			padding-right: 0;
		}
	}
}

/* Facebook like gomb /*/

.header-promo__img-col {
	align-content: center;
	align-items: center;
	display: flex;
	flex: 1 1 50%;
	justify-content: center;
	min-height: 216px;
	padding: 0 1.5rem;
	position: relative;

	@media (--tablet) {
		padding: 0;
	}
}

.parent-container .header-promo__img-col {
	padding-left: 0;
	padding-right: 0;

	@media (--tablet) {
		padding-left: 0;
		padding-right: 1.5rem;
	}
}

.parent-container .header-promo__img-col + .header-promo__img-col {
	padding-left: 0;
	padding-right: 0;

	@media (--tablet) {
		padding-left: 1.5rem;
		padding-right: 0;
	}
}

.header-promo__img-col > div {
	align-self: stretch;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: 0;
}
.header-promo__img-col object {
	align-self: stretch;
	display: flex;
	flex: 1 1 auto;
	height: 100%;
}
.header-promo__img-col img {
	height: auto;
	max-width: 100%;
}

/* TTITLE*/
.header-promo__title {
	color: var(--secondary-color);
	line-height: 1.75rem;
	padding: 0 0 1.2rem;
	text-transform: uppercase;

	@media (--mobileOnly) {
		font-size: 1.75rem;
	}

	@media (--tablet) {
		line-height: 2.5rem;
	}
	@media (--desktop) {
		padding: 0 0 1.9rem;
	}
}
/* TTITLE*/

.header-promo__text-lead {
	color: var(--default-text);
	padding: 0 0 0.95rem;

	@media (--desktop) {
		padding: 0 0 1.8rem;
	}
}

.header-promo__text {
	color: var(--default-text);
	padding: 0 0 0.95rem;

	@media (--mobileOnly) {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	@media (--desktop) {
		padding: 0 0 1.49rem;
	}
}

.header-promo__link {
	clear: both;
}

.header-promo__link--wide {
	align-self: center;
	margin: 0 0 1.6rem;
}

.header-promo__button {
	margin: 0 0 1.6rem;
}

.header-promo__button--wide {
	align-self: center;
	margin: 0 0 1.6rem;
}

.header-promo--wide {
	text-align: center;

	.header-promo--social-like {
		@media (--desktop) {
			padding: 1.75rem 12rem;
		}

		@media (--mobileOnly) {
			padding: 3.75rem 1.5rem;
		}
	}
}
