/*************** SCROLLBAR BASE CSS ***************/
.scroll-wrapper {
	display: none;
	overflow: hidden !important;/* stylelint-disable-line */
	padding: 0 !important;/* stylelint-disable-line */
	position: relative;
}
.scroll-wrapper > .scroll-content {
	border: none !important;/* stylelint-disable-line */
	box-sizing: content-box !important;/* stylelint-disable-line */
	height: auto;
	left: 0;
	margin: 0;
	max-height: none;
	max-width: none !important;/* stylelint-disable-line */
	overflow: scroll !important;/* stylelint-disable-line */
	padding: 0;
	position: relative !important;/* stylelint-disable-line */
	top: 0;
	width: auto !important;/* stylelint-disable-line */
}
.scroll-wrapper > .scroll-content::-webkit-scrollbar {
	height: 0;
	width: 0;
}
.scroll-element {
	box-sizing: content-box;
	display: none;
}
.scroll-element div {
	box-sizing: content-box;
}
.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
	cursor: default;
}
.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
	display: block;
}

.scroll-textarea {
	border: 1px solid #ccc;
	border-top-color: #999;
}

.scroll-textarea > .scroll-content {
	overflow: hidden !important;/* stylelint-disable-line */
}

.scroll-textarea > .scroll-content > textarea {
	border: none !important;/* stylelint-disable-line */
	box-sizing: border-box;
	height: 100% !important;/* stylelint-disable-line */
	margin: 0;
	max-height: none !important;/* stylelint-disable-line */
	max-width: none !important;/* stylelint-disable-line */
	outline: none;
	overflow: scroll !important;/* stylelint-disable-line */
	padding: 2px;
	position: relative !important;/* stylelint-disable-line */
	top: 0;
	width: 100% !important;/* stylelint-disable-line */
}
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
	height: 0;
	width: 0;
}

/*************** SCROLLBAR DYNAMIC ***************/

.scrollbar-dynamic {
	background: #fff;
	border: 1px solid var(--modul-border);
	border-radius: 4px;
	box-shadow: 0 4px 4px rgba(93, 100, 130, 0.12);
	margin-top: 4px;
	max-height: 196px;
	overflow: auto;
	position: absolute !important;/* stylelint-disable-line */
	top: 44px;
	width: 100%;
	z-index: 1;
}

.scrollbar-dynamic > .scroll-element,
.scrollbar-dynamic > .scroll-element div {
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
}

.scrollbar-dynamic > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}

.scrollbar-dynamic > .scroll-element.scroll-x {
	bottom: 2px;
	height: 7px;
	left: 0;
	min-width: 100%;
	width: 100%;
}

.scrollbar-dynamic > .scroll-element.scroll-y {
	height: 'calc(100% - 16px)';
	right: 8px;
	top: 0;
	width: 4px;
}

.scrollbar-dynamic > .scroll-element .scroll-element_outer {
	border-radius: 12px;
}

.scrollbar-dynamic > .scroll-element .scroll-element_size {
	background-color: transparent;
}

.scrollbar-dynamic > .scroll-element .scroll-bar {
	background-color: var(--modul-border-hover);
	border-radius: 4px;
}

/* scrollbar height/width & offset from container borders */
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-bar {
	bottom: 0;
	height: 4px;
	min-width: 24px;
	top: auto;
}

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-bar {
	left: auto;
	min-height: 24px;
	right: 0;
	width: 4px;
}

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_outer {
	bottom: 0;
	left: 2px;
	top: auto;
}

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_outer {
	left: auto;
	right: 0;
	top: 8px;
}

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_size {
	left: -4px;
}

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_size {
	top: -4px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
	left: -11px;
}

.scrollbar-dynamic > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
	top: -11px;
}

/* hover & drag */
.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer {
	overflow: hidden;
}

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-element_size,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size {
	opacity: 1;
}

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar {
	border-radius: 12px;
	height: 100%;
	width: 100%;
}

.scrollbar-dynamic > .scroll-element.scroll-x:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer {
	height: 4px;
	min-height: 4px;
}

.scrollbar-dynamic > .scroll-element.scroll-y:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer {
	min-width: 4px;
	width: 4px;
}
