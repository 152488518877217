.tabbed-container-extended {
	.tab-list-desktop {
		display: none;
	}

	.tab-list-desktop .tab-list {
		align-content: stretch;
		display: flex;
		font-size: 0;
		width: 100%;

		.tab-list-item {
			align-items: center;
			background-color: rgba(51, 51, 51, 0.05);
			color: var(--secondary-color);
			margin-right: 1rem;
			min-height: 72px;
			padding: 1.5rem;
			position: relative;
			text-align: left;
			text-decoration: none;
			vertical-align: bottom;
			width: calc(25% - 0.75rem);

			&:hover {
				background-color: var(--modul-bg-hover);
				cursor: pointer;
			}

			&:active {
				background-color: var(--modul-bg-active);
			}

			&:last-child {
				margin-right: 0;
			}

			a {
				color: inherit;
				text-decoration: none;
			}
		}

		.tab-list-item--active {
			background-color: #fff;
			border-top: 0.5rem solid var(--primary-color);
			color: var(--primary-color);
			margin-top: -6px;

			&:hover {
				background-color: #fff;
				cursor: auto;
			}

			&:active {
				background-color: #fff;
			}

			a {
				cursor: default;
				font-family: var(--font-bold);
			}
		}
	}

	.tab-list-desktop .tab-list-content {
		background-color: #fff;
		padding: 2rem 1.5rem;
		position: relative;
	}

	.tab-list-desktop .tab-list-content .module-container {
		padding: 0;
	}

	.tab-list-desktop .tab-list-content > .module-container:last-child {
		margin-bottom: 0;
	}

	@media (--desktop) {
		.tab-list-mobile {
			display: none;
		}
		.tab-list-desktop {
			display: block;
		}
	}
} /* tabbed-container-extended */
