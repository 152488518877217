.app-heartbeat {
	background-color: transparent;
	background-image: url('/static/c3/g/modules/m25-flowtext/app-heartbeat/rsz_bg_640.png');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	position: relative;
}

.app-heartbeat .heartbeat-content {
	color: #fff;
	font-family: var(--font-akziden-super);
	height: 270px;
	padding: 20px 90px 20px 20px;
	position: relative;
	text-transform: uppercase;
}

.app-heartbeat .heartbeat-content a {
	border: 3px solid #fff;
	color: #fff;
	display: inline-block;
	text-decoration: none;
	&:active {
		background-color: #fff;
		color: #e20074;
	}

	@media (--desktop-960) {
		&:hover {
			background-color: #fff;
			color: #e20074;
		}
	}
}

.app-heartbeat .heartbeat-content.heartbeat-content--thankyou {
	background-color: #408f9a;

	.center-container {
		position: relative;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);

		a:hover {
			color: #408f9a;
		}
	}
}

.app-heartbeat .heartbeat-content.heartbeat-content--active .center-container {
	left: 50%;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);

	.heartbeat-counter {
		background-color: transparent;
		background-image: url('/static/c3/g/modules/m25-flowtext/app-heartbeat/heartbeat.png');
		background-position: 0 50%;
		background-repeat: no-repeat;
		background-size: contain;
		left: 50%;
		position: relative;
		transform: translateX(-50%);

		span {
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
		}
	}
}

/* col-xs-6 */
.interactive-content.col-xs-6 .app-heartbeat .heartbeat-content {
	height: 270px;
	padding: 20px;

	@media (--tablet) {
		color: #fff;
		padding-right: 20px;
	}

	h2 {
		font: 21px/24px var(--font-akziden-super);
		margin-bottom: 10px;

		@media (--tablet) {
			font-size: 21px;
			line-height: 24px;
		}

		@media (--desktop-960) {
			font-size: 24px;
			line-height: 27px;
		}
	}

	h3 {
		font: 15px/18px var(--font-akziden-super);
		margin-bottom: 19px;

		@media (--tablet) {
			font-size: 15px;
			line-height: 18px;
		}

		@media (--desktop-960) {
			font-size: 18px;
			line-height: 21px;
		}
	}

	a {
		font: 10px/12px var(--font-akziden-super);
		padding: 8px 17px;

		@media (min-width: 355px) {
			font-size: 12px;
			line-height: 15px;
		}

		@media (--tablet) {
			font-size: 11px;
			line-height: 11px;
			padding: 5px;
		}

		@media (--desktop-960) {
			font-size: 15px;
			line-height: 18px;
			padding: 8px;
		}
	}

	&--thankyou {
		padding: 20px;

		.center-container {
			h2 {
				margin-bottom: 48px;
			}

			a:hover {
				color: #408f9a;
			}
		}
	}

	&--active .center-container {
		h2 {
			margin-bottom: 48px;
		}

		.heartbeat-counter {
			height: 172px;
			margin-bottom: 15px;
			width: 235px;

			span {
				font-size: 40px;
				line-height: 42px;
				margin-top: 65px;
			}
		}

		a {
			@media (--desktop-960) {
				border: 3px solid #fff;
			}
		}
		a:hover {
			color: var(--primary-color);
		}
	}
}

.interactive-content.col-xs-6 + * {
	padding-top: 0;
}
