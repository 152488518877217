/* font family-ket meghatározó mixin-ek */
:root {
	--ff-thin: {
		font-family: var(--font-thin);
		font-style: normal;
		font-weight: normal;
	}

	--ff-reg: {
		font-family: var(--font-reg);
		font-style: normal;
		font-weight: normal;
	}

	--ff-regit: {
		font-family: var(--font-regit);
		font-style: normal;
		font-weight: normal;
	}

	--ff-med: {
		font-family: var(--font-med);
		font-style: normal;
		font-weight: normal;
	}

	--ff-medit: {
		font-family: var(--font-medit);
		font-style: normal;
		font-weight: normal;
	}

	--ff-bold: {
		font-family: var(--font-bold);
		font-style: normal;
		font-weight: normal;
	}

	--ff-ult: {
		font-family: var(--font-ult);
		font-style: normal;
		font-weight: normal;
	}
}
/* font family class-ok definiálása mixin-ekkel */

.ff-thin.ff-thin {
	@apply --ff-thin;
}

.ff-reg.ff-reg {
	@apply --ff-reg;
}

.ff-regit.ff-regit {
	@apply --ff-regit;
}

.ff-med.ff-med {
	@apply --ff-med;
}

.ff-medit.ff-medit {
	@apply --ff-medit;
}

.ff-bold.ff-bold {
	@apply --ff-bold;
}

.ff-ult.ff-ult {
	@apply --ff-ult;
}

/*
a.ff-thin, a.ff-reg, a.ff-regit, a.ff-med, a.ff-medit, a.ff-bold, a.ff-ult {
	display: inline-block;
	width: initial;
}
*/

.fc {
	&-magenta.fc-magenta {
		color: var(--primary-color);
	}

	&-grey.fc-grey {
		color: var(--secondary-color);
	}

	&-white.fc-white {
		color: #fff;
	}
}

.non-breaking-text {
	white-space: nowrap;
}

.module-link {
	.fc-magenta,
	.fc-grey,
	.fc-white {
		text-decoration: underline;
	}
}

.module-link:hover {
	.fc-magenta,
	.fc-grey,
	.fc-white {
		text-decoration: none;
	}
}

.module-container {
	strong,
	b {
		font-family: var(--font-bold);
		font-style: normal;
		font-weight: normal;
	}

	i,
	em {
		font-family: var(--font-medit);
		font-style: normal;
		font-weight: normal;
	}
}
