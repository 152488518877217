/* SERVICE CARD ICON */

/*CARD TYPE A*/
.device-card--service-icon .device-card-item--a .device-card-item__img-wrap {
	align-items: flex-start;
	height: 84px;
	width: 84px;

	@media (--mobileOnly) {
		margin: 0 auto 2rem;
	}

	@media (--tablet) {
		flex: 0 0 auto;
		height: 84px;
		width: 102px;
	}
}

.device-card--service-icon .device-card-item--a .device-card-item__img-wrap img {
	max-height: 84px;
	max-width: 84px;
}

.device-card--slider.device-card--service-icon .device-card-item--a .device-card-item__img-wrap {
	@media (--tabletOnly) {
		flex: 0 1 100%;
		height: 84px;
		width: 100%;
	}
}

.device-card--service-icon .device-card-item--a .device-card-item__text-wrap {
	@media (--tablet) {
		flex: 0 1 100%;
		width: 100%;
	}
}
/*CARD TYPE A*/

/*CARD TYPE B2*/

.device-card--service-icon.device-card--b-3 .device-card-item__panel-wrap,
.device-card--service-icon.device-card--b-4 .device-card-item__panel-wrap {
	@media (--tabletOnly) {
		flex-wrap: nowrap;
	}
}

.device-card--slider.device-card--service-icon.device-card--b-3 .device-card-item__panel-wrap,
.device-card--slider.device-card--service-icon.device-card--b-4 .device-card-item__panel-wrap {
	@media (--tabletOnly) {
		flex-wrap: wrap;
	}
}

.device-card--service-icon.device-card--b-3 .device-card-item__img-wrap,
.device-card--service-icon.device-card--b-4 .device-card-item__img-wrap {
	align-items: flex-start;
	height: 84px;
	width: 84px;

	@media (--mobileOnly) {
		margin: 0 auto 2rem;
	}

	@media (--tabletOnly) {
		flex: 0 0 auto;
		height: 84px;
		width: 102px;
	}

	@media (--desktop) {
		margin: 0 auto 2rem;
	}

	&.is-img-content {
		display: block;
	}
}

.device-card--service-icon.device-card--b-3 .device-card-item__img-wrap img,
.device-card--service-icon.device-card--b-4 .device-card-item__img-wrap img {
	max-height: 84px;
	max-width: 84px;
}

.device-card--slider.device-card--service-icon.device-card--b-3 .device-card-item__img-wrap,
.device-card--slider.device-card--service-icon.device-card--b-4 .device-card-item__img-wrap {
	@media (--tabletOnly) {
		flex: 0 1 100%;
		height: 84px;
		width: 100%;
	}

	&.is-img-content {
		display: block;
		overflow: hidden;
	}
}

.device-card--service-icon.device-card--b-3 .device-card-item__text-wrap,
.device-card--service-icon.device-card--b-4 .device-card-item__text-wrap {
	@media (--tabletOnly) {
		flex: 0 1 100%;
		width: 100%;
	}

	&.is-img-content {
		display: block;
		overflow: hidden;
	}
}
/*CARD TYPE B2*/

/*CARD TYPE C*/
.device-card--service-icon.device-card--service-c .device-card-item__img-wrap {
	align-items: flex-start;
	height: 84px;
	width: 84px;

	@media (--mobileOnly) {
		align-self: center;
	}

	@media (--tabletOnly) {
		align-self: center;
	}

	@media (--desktop) {
		flex: 0 0 auto;
		width: 102px;
	}
}

.device-card--service-icon.device-card--service-c .device-card-item__img-wrap img {
	max-height: 84px;
	max-width: 84px;
}
/*CARD TYPE C*/

/* SERVICE CARD ICON */
