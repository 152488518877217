@import '_value-molecule-fixed.pcss';

.one-column-descriptive {
	padding: 0 0.5rem;

	.parent-container & {
		padding-left: 0;
		padding-right: 0;
	}

	/* Settings of molecules*/

	.list-bullet-points {
		padding-bottom: 2rem;
	}

	.list-bullet-points li {
		padding-bottom: 1.4rem;
	}

	.device-price,
	.service-price {
		margin-bottom: 0;
		padding-bottom: 1.2rem;
	}

	/* Settings of molecules over*/
}

.one-column-descriptive__title {
	color: var(--secondary-color);
	padding-bottom: 2.5rem;
	padding-left: 1.5rem;
}

.parent-container .one-column-descriptive__title {
	padding-left: 0;
}

.one-column-descriptive-wrapper {
	padding-bottom: 6rem;

	&::after {
		background-color: rgba(51, 51, 51, 0.2);
		content: '';
		display: block;
		height: 1px;
		left: 0;
		position: relative;
		top: 3rem;
		width: 100%;
	}

	&:last-child {
		padding-bottom: 3rem;

		&::after {
			content: normal;
		}
	}
}

.one-column-descriptive-illustration-wrap.one-column-descriptive-illustration-wrap {
	padding-bottom: 1.5rem;
	padding-left: 0.5rem;

	@media (--desktop) {
		float: right;
		padding-bottom: 0;
	}

	img,
	object {
		width: auto;
	}

	&.aspect-ratio-16-9 object,
	&.aspect-ratio-4-3 object {
		display: block;
		height: 100%;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		width: 100%;

		@media (--desktop) {
			left: 0.5rem;
			width: calc(100% - 0.5rem);
		}
	}

	&.aspect-ratio-16-9::before {
		@apply --aspect-16-9;
	}

	&.aspect-ratio-4-3::before {
		@apply --aspect-4-3;
	}
}

.one-column-descriptive-wrapper--two-third .one-column-descriptive-illustration-wrap {
	padding-left: 0;

	@media (--desktop) {
		float: left;
		margin-left: 1rem;
	}
}

.parent-container .one-column-descriptive-illustration-wrap.one-column-descriptive-illustration-wrap {
	padding-left: 0;

	@media (--desktop) {
		padding-left: 0.5rem;
	}
}

.one-column-descriptive-text-wrap {
	/* color: var(--secondary-color); */
	color: var(--default-text);
	padding-left: 1.5rem;
	padding-right: 0.5rem;

	& > *:not(.one-column-descriptive__button):last-child {
		padding-bottom: 0;
	}

	& > .one-column-descriptive__button:last-child {
		margin-bottom: 0;
	}
}

.one-column-descriptive-wrapper--two-third .one-column-descriptive-text-wrap {
	@media (--desktop) {
		margin-left: -1rem;
		padding-left: 3rem;
	}
}

.parent-container .one-column-descriptive-text-wrap {
	padding-left: 0;
}

.parent-container one-column-descriptive-illustration-wrap + .one-column-descriptive-text-wrap {
	padding-left: 0.5rem;
	padding-right: 0;
}

.one-column-descriptive__super-title {
	color: var(--secondary-color);
	padding-bottom: 1.5rem;
	text-transform: uppercase;
}

.one-column-descriptive__sub-title {
	color: var(--secondary-color);
	padding-bottom: 1.5rem;
}

.one-column-descriptive__text {
	padding-bottom: 2rem;
}

.one-column-descriptive__button {
	margin-bottom: 2rem;
}

.one-column-descriptive__link {
	padding-bottom: 2rem;
}

.one-column-descriptive__link-item {
	list-style-type: none;
	padding-bottom: 1.5rem;

	&:last-child {
		padding-bottom: 0;
	}
}

.one-column-descriptive__next-wrapper {
	height: 1px;
	left: -10000em;
	overflow: hidden;
	position: absolute;
	top: auto;
	width: 1px;
}
