/* LOADER*/
.m17p-inline-promo-loader {
	background: #fff;
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 100;
}

.m17p-inline-promo-loader-wrap {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.m17p-inline-promo-loader-effect {
	height: 65px;
	margin: 0 auto 0;
	position: relative;
	width: 100%;
}

.m17p-loader {
	height: 65px;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 65px;
}

.m17p-loader-forward,
.m17p-loader-backward {
	height: 65px;
	left: 0;
	position: absolute;
	width: 65px;
}

.m17p-loader-forward {
	animation: forward 1.5s linear infinite;
	background: url('/static/c3/g/modules/m17p-inline-promo/loader-blue.svg') no-repeat;
	background-size: contain;
}

.m17p-loader-backward {
	animation: backward 1.5s linear infinite;
	background: url('/static/c3/g/modules/m17p-inline-promo/loader-mag.svg') no-repeat;
	background-size: contain;
}

.m17p-inline-promo-loader-message {
	font-family: var(--font-reg);
	font-size: 18px;
	line-height: 21px;
	padding-top: 6px;
	text-align: center;
}
