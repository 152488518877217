.frame-container {
	clear: both;
	display: block;
	margin: 0 auto 40px;
	max-width: 1296px;
	min-width: 1px;
	width: 100%;

	.frame-content {
		margin: 0 auto;
		min-width: 280px;
		position: relative;
		width: 100%;
	}

	.frame-content .module-container {
		max-width: 1152px;
	}
}

/* Ha a third party modulnak az oldal tetejére kell igazodni. Keret magasságával minusz. Pl.: carousel modul */
body.third-party-module-to-page-top .frame-container {
	margin-top: -86px;

	@media (--desktop-960) {
		margin-top: -130px;
	}
}

body.third-party-module-to-page-top #frameContainer {
	margin-top: -108px !important; /* stylelint-disable-line */

	@media (--desktop-960) {
		margin-top: -152px !important; /* stylelint-disable-line */
	}
}

/* A third party modul beszúrása esetén van rá szükség. */
body.third-party-module-full-width .frame-container {
	max-width: none;
}

/* Az RWM001-ben meg kell engedni a teljes szélességet, mert hiába állítjuk a third party modulban, az oldalsablon nem engedi, ezért van szükség erre */
.third-party-module-full-width #frameContainer {
	max-width: 100%;
}

.third-party-module-full-width #frameContainer .module-container {
	max-width: 1152px;
}
