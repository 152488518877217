.drop-down-textblock {
	color: var(--secondary-color);
	margin: 0 2rem 0 2rem;

	.parent-container & {
		margin-left: 0;
		margin-right: 0;
	}
}

.drop-down-textblock__closed-text {
	display: none;
}

.drop-down-textblock__opened-text {
	display: block;
}

.drop-down-textblock__open {
	color: var(--secondary-color);
	cursor: pointer;
	padding: 0 0 0 3.5rem;
	position: relative;
	text-decoration: underline;
	width: auto;

	&::before {
		border: 0.166rem solid #317cb3;
		border-radius: 0.25rem;
		content: '';
		display: block;
		height: 2.5rem;
		left: 0;
		position: absolute;
		top: -0.25rem;
		width: 2.5rem;
	}

	&::after {
		border-right: 0.166rem solid #317cb3;
		border-top: 0.166rem solid #317cb3;
		content: '';
		display: block;
		height: 0.833rem;
		left: 0.833rem;
		position: absolute;
		top: 0.833rem;
		transform: rotate(315deg);
		width: 0.833rem;
	}

	&:hover::before {
		background-color: #317cb3;
	}

	&:hover::after,
	&:active::after {
		border-right: 0.166rem solid #fff;
		border-top: 0.166rem solid #fff;
	}

	&:active::before {
		background-color: #0a8bbc;
		border: 0.166rem solid #0a8bbc;
	}

	&:hover,
	&:active {
		text-decoration: none;
	}
}

.drop-down-textblock__content {
	display: none;
	margin-bottom: 0;
	padding: 2.5rem 0 0 3.666rem;
	position: relative;
	width: 100%;

	&:first-child {
		padding: 0 0 0 3.666rem;
	}

	.drop-down-textblock__link {
		color: var(--secondary-color);
		display: table;
		margin-bottom: 1rem;
		width: auto;
	}

	.drop-down-textblock__close {
		color: var(--secondary-color);
		cursor: pointer;
		display: block;
		left: -1.833rem;
		margin: 0 0 0 0.333rem;
		position: relative;
		width: auto;

		&::before {
			border-right: 0.166rem solid #317cb3;
			border-top: 0.166rem solid #317cb3;
			content: '';
			display: block;
			height: 0.79rem;
			left: -1.666rem;
			position: absolute;
			top: 0.8333rem;
			transform: rotate(315deg);
			width: 0.833rem;
		}
	}

	.drop-down-textblock__subtitle {
		padding: 0 0 2.5rem 0;
		text-transform: none;
	}

	.drop-down-textblock__text {
		color: var(--default-text);
		padding-bottom: 2.83333rem;
	}
}

.drop-down-textblock__link + .drop-down-textblock__close {
	margin: 2.5rem 0 0 0.333rem;
}

.drop-down-textblock__link + .drop-down-textblock__text {
	padding-top: 1rem;
}

/** CLOSE TAB **/

.drop-down-textblock.closed {
	padding-bottom: 0;

	.drop-down-textblock__closed-text {
		display: block;
	}

	.drop-down-textblock__opened-text {
		display: none;
	}
	.drop-down-textblock__open::after {
		border-right: 0.166rem solid #317cb3;
		border-top: 0.166rem solid #317cb3;
		content: '';
		display: block;
		height: 0.833rem;
		left: 0.833rem;
		position: absolute;
		top: 0.5rem;
		transform: rotate(135deg);
		width: 0.833rem;
	}

	.drop-down-textblock__open:hover::after,
	.drop-down-textblock__open:active::after {
		border-right: 0.166rem solid #fff;
		border-top: 0.166rem solid #fff;
	}

	&.smallsize .drop-down-textblock__open::after {
		top: 0.5rem;
	}
}

.drop-down-textblock.opened .drop-down-textblock__content {
	margin-bottom: 0;
}

/** SMALL SIZE **/

.drop-down-textblock.smallsize {
	/* MODIFY FONT-SIZE-MATRIX */
	.fs-1-5,
	.fs-1-75 {
		font-size: 1.25rem;
		line-height: 1.5rem;
	}
	/* /MODIFY FONT-SIZE-MATRIX */

	.drop-down-textblock__content {
		padding-left: 3rem;

		.link-close {
			&::before {
				height: 0.583rem;
				top: 0.666rem;
				width: 0.583rem;
			}
		}

		ul li::before {
			top: 1.91667rem;
		}

		ul li ul li::before {
			top: 1rem;
		}
	}

	.drop-down-textblock__close::before {
		height: 0.583rem;
		left: -1.66rem;
		top: 0.666rem;
		width: 0.583rem;
	}

	.drop-down-textblock__open {
		font-size: 1.333rem;
		line-height: 1.16rem;
		padding: 0.166rem 0 0.166rem 3rem;

		&::before {
			height: 2rem;
			top: -0.166rem;
			width: 2rem;
		}

		&::after {
			height: 0.583rem;
			left: 0.75rem;
			top: 0.666rem;
			width: 0.583rem;
		}

		&:hover::before {
			background-color: #317cb3;
		}

		&:hover::after,
		&:active::after {
			border-right: 0.166rem solid #fff;
			border-top: 0.166rem solid #fff;
		}

		&:active::before {
			background-color: #0a8bbc;
			border: 0.166rem solid #0a8bbc;
		}
	}
}
