.flowtext.flowtext--hidden-mobile-interactive-content .interactive-content {
	@media (--mobileOnly) {
		display: none;
	}
}

.flowtext-content .interactive-content {
	&.left + *,
	&.right + * {
		padding-top: 0;
	}

	&.left + ul,
	&.right + ul {
		overflow: hidden;
		padding-top: 0;
	}

	&.left ~ .list-bullet-points,
	&.left ~ .list-numbered-points {
		display: table; /* Másképpen a bullet vagy számlista számozása a float-olt elemre lapolódik, a sor elejére. */
	}

	&.left ~ .list-bullet-points li,
	&.left ~ .list-numbered-points li,
	&.right ~ .list-bullet-points li,
	&.right ~ .list-numbered-points li {
		display: list-item; /* másképpen letörik új sorba a float-olt elem alá */
	}
}
