.module-container .list-bullet-points {
	display: block;
	list-style-type: none;
	margin-bottom: -0.333333rem;
	padding-bottom: 2rem;

	li {
		color: var(--default-text);
		padding: 0 0 2rem 1.55rem;
		position: relative;

		&::before {
			color: var(--primary-color);
			content: '\2022';
			display: block;
			left: 0;
			position: absolute;
			top: 0;
		}

		ul {
			padding: 2rem 0 1rem 0;

			li {
				list-style-type: none;
				padding-bottom: 1.33334rem;

				&::before {
					color: var(--secondary-color);
				}
			}
		}
	}

	*:last-child {
		padding-bottom: 0;
	}
}
