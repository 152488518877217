/*alapértelmezett távtartó a "modul-container"-en*/
.module-container {
	margin-bottom: 2rem;
	position: relative;

	&.module-spacer-line {
		margin-bottom: 4rem;
	}
}

/*távtartók lehetséges méretei*/
.module-spacer {
	&-0-5 {
		margin-bottom: 0.5rem;
	}
	&-1 {
		margin-bottom: 1rem;
	}
	&-1-5 {
		margin-bottom: 1.5rem;
	}
	&-2 {
		margin-bottom: 2rem;
	}
	&-3 {
		margin-bottom: 3rem;
	}
	&-4 {
		margin-bottom: 4rem;
	}
	&-5 {
		margin-bottom: 5rem;
	}
}

/*távtartó elválasztóval és annak lehetséges méretei*/
.module-spacer-line {
	/*a 0.5 és 5 méretnél az alapértelmezett jelenik meg, mivel ezek a design alapján nem engedélyezettek*/
	&:not(.module-spacer-0-5),
	&:not(.module-spacer-5),
	&.module-spacer-1,
	&.module-spacer-1-5,
	&.module-spacer-2,
	&.module-spacer-3,
	&.module-spacer-4 {
		&::after {
			background-color: rgba(51, 51, 51, 0.2);
			bottom: -2rem;
			content: '';
			display: block;
			height: 1px;
			left: 1rem;
			position: absolute;
			width: ~'calc(100% - 2rem)';
		}
	}

	&.module-spacer-1 {
		margin-bottom: 2rem;
		&::after {
			bottom: -1rem;
		}
	}

	&.module-spacer-1-5 {
		margin-bottom: 3rem;
		&::after {
			bottom: -1.5rem;
		}
	}

	&.module-spacer-2 {
		margin-bottom: 4rem;
		&::after {
			bottom: -2rem;
		}
	}

	&.module-spacer-3 {
		margin-bottom: 6rem;
		&::after {
			bottom: -3rem;
		}
	}

	&.module-spacer-4 {
		margin-bottom: 8rem;
		&::after {
			bottom: -4rem;
		}
	}
}
