#frameContent {
	position: relative;
}

.social-like {
	background: #f4f2ed;
	display: none;
	margin: 0;
	padding: 1.5rem 2rem;
	position: absolute;
	right: 0;
	top: -1.5rem;
	width: auto;
	z-index: 100;

	@media (--desktop) {
		display: block;
	}
}
