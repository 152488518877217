.brutto-netto__title {
	/* color: var(--primary-color); */
	color: var(--secondary-color);
	padding: 0 0 2.5rem 0;
}

.brutto-netto__label {
	color: var(--secondary-color);
	float: left;
	line-height: 1.5rem;
	padding: 0 1rem 1.35rem 0;
	width: 100%;

	@media (--tablet) {
		padding: 0 1rem 0 0;
		width: auto;
	}
}

.brutto-netto__radio {
	cursor: pointer;
	display: block;
	float: left;
	line-height: 1.5rem;
	min-height: 20px;
	padding: 0 2rem 0 2.667rem;
	position: relative;
	width: auto;
}

.brutto-netto__radio input[type='radio'] {
	display: none;
}

.brutto-netto__radio input[type='radio']:checked + .brutto-netto__radio-box {
	border-color: var(--primary-color);
}

.brutto-netto__radio input[type='radio']:checked + .brutto-netto__radio-box::after {
	display: block;
}

.brutto-netto__radio input[type='radio']:checked + .brutto-netto__radio-box + .brutto-netto__radio-text {
	color: var(--primary-color);
	font-family: var(--font-bold);
	letter-spacing: 0;
}

.brutto-netto__radio-box {
	/* border: 3px solid #a7a6a3; */
	border: 3px solid var(--secondary-color);
	border-radius: 15px;
	display: block;
	float: left;
	height: 20px;
	left: 0;
	margin: 0;
	position: absolute;
	top: 0;
	transition: all 0.3s ease-in-out;
	width: 20px;
}

.brutto-netto__radio-box::after {
	background: var(--primary-color);
	border-radius: 10px;
	content: '';
	display: none;
	height: 10px;
	left: 50%;
	margin: -5px 0 0 -5px;
	position: absolute;
	top: 50%;
	transition: all 0.3s ease-in-out;
	width: 10px;
}

.brutto-netto__radio-text {
	color: var(--secondary-color);
	letter-spacing: 0.3px;
}

/* show and hide prices */

html {
	&.show-netto-price {
		.brutto-price {
			display: none;
		}

		.netto-price {
			display: inline-block;
		}
	}

	&.show-brutto-price {
		.brutto-price {
			display: inline-block;
		}

		.netto-price {
			display: none;
		}
	}
}
