/*DEVICE CARD NO BG WITH IMG*/
.device-card-no-bg .device-card-item__panel {
	background: transparent;
	box-shadow: none;
}

.device-card-no-bg .device-card-item__panel:active {
	background: #e0dfda;
}
/*DEVICE CARD NO BG WITH IMG*/

/*DEVICE CARD NO BG WITH ICON*/
.device-card-no-bg .device-card--service-icon .device-card-item__panel {
	background: transparent;
}

.device-card-no-bg .device-card--service-icon .device-card-item__img-wrap:hover img {
	cursor: pointer;
	filter: saturate(102%) brightness(93%);
	position: relative;
}

.device-card-no-bg .device-card--service-icon .device-card-item__img-wrap:active img {
	filter: saturate(105%) brightness(86%);
}
/*DEVICE CARD NO BG WITH ICON*/

/*DEVICE CARD NO BG WITHOUT CTA*/
.device-card-no-bg .device-card-item__panel.device-card-item__panel--info:active {
	background: transparent;
}

.device-card-no-bg .device-card--service-icon .device-card-item__panel.device-card-item__panel--info .device-card-item__img-wrap:hover img {
	cursor: auto;
	filter: none;
}

.device-card-no-bg .device-card--service-icon .device-card-item__panel.device-card-item__panel--info .device-card-item__img-wrap:active img {
	filter: none;
}
/*DEVICE CARD NO BG WITHOUT CTA*/
