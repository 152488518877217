.tabbed-container-extended {
	padding: 3rem 0 0 0;
	> .row {
		padding: 0 0.5rem;
	}

	.tab-list-mobile {
		background-color: #fff;
	}

	.tab-list-mobile .tab-list .tab-list-item {
		border-top: 0.083333rem solid var(--modul-bg-active);
		color: var(--secondary-color);
		display: block;
		position: relative;
		text-decoration: none;

		.tab-list-item__title {
			padding: 2rem 8.1rem 1.5rem 1.5rem;
			position: relative;

			&:hover {
				background-color: var(--modul-bg-hover);
				cursor: pointer;
			}

			&:active {
				background-color: var(--modul-bg-active);
			}

			&::after {
				border-right: 0.166667rem solid var(--secondary-color);
				border-top: 0.166667rem solid var(--secondary-color);
				content: '';
				display: block;
				height: 0.833333rem;
				position: absolute;
				right: 1.66667rem;
				top: 1.91667rem;
				transform: rotate(135deg);
				width: 0.833333rem;
			}

			a {
				color: inherit;
				text-decoration: none;
			}
		}

		.tab-list-content {
			display: none;
			padding: 2rem 1.5rem;
		}

		.tab-list-content .module-container {
			padding: 0;
		}

		.tab-list-content > .module-container:last-child {
			margin-bottom: 0;
		}

		&:last-child {
			border-bottom: 0.083333rem solid var(--modul-border);
		}
	}

	.tab-list-mobile .tab-list .tab-list-item.tab-list-item--active {
		border-top: 0.5rem solid var(--primary-color);

		.tab-list-item__title {
			color: var(--primary-color);

			&::after {
				border-right: 0.166667rem solid var(--primary-color);
				border-top: 0.166667rem solid var(--primary-color);
				content: '';
				display: block;
				height: 0.833333rem;
				position: absolute;
				right: 1.66667rem;
				top: 1.91667rem;
				transform: rotate(315deg);
				width: 0.833333rem;
			}

			a {
				font-family: var(--font-bold);
			}
		}
		.tab-list-content {
			display: block;
		}
	}
} /* tabbed-container-extended */

.tabbed-container-extended.tabbed-container-extended--single {
	padding-top: 0;
}

.tabbed-container-extended.tabbed-container-extended--single .tab-list-mobile .tab-list .tab-list-item .tab-list-content {
	display: block;
	height: auto !important; /* stylelint-disable-line */
}

.tabbed-container-extended.tabbed-container-extended--single .tab-list-mobile .tab-list .tab-list-item,
.tabbed-container-extended.tabbed-container-extended--single .tab-list-mobile .tab-list .tab-list-item:last-child {
	border: none;
}
