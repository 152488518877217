@import '_value-molecule-fixed.pcss';

.multi-column-descriptive {
	padding: 0 0.5rem;

	.parent-container & {
		padding-left: 0;
		padding-right: 0;
	}

	/* Settings of molecules */

	.list-bullet-points {
		padding-bottom: 2rem;
	}

	.device-price,
	.service-price {
		padding-bottom: 1.5rem;
	}

	/* Settings of molecules over*/
}

.parent-container .multi-column-descriptive > .row {
	margin-left: -0.5rem;
}

.multi-column-descriptive__title {
	/* color: var(--primary-color); */
	color: var(--secondary-color);
	padding-bottom: 2.5rem;
	padding-left: 1.5rem;
}

.parent-container .multi-column-descriptive__title {
	padding-left: 0.5rem;
}

.multi-column-descriptive-wrapper {
	padding-bottom: 6rem;

	&::after {
		background-color: rgba(51, 51, 51, 0.2);
		content: '';
		display: block;
		height: 1px;
		left: 0;
		position: relative;
		top: 3rem;
		width: 100%;

		@media (--desktop-xl) {
			content: normal;
		}
	}

	&:last-child {
		padding-bottom: 3rem;

		&::after {
			content: normal;
		}
	}
}

.multi-column-descriptive-illustration-wrap {
	margin-bottom: 1.5rem;

	@media (--tablet) {
		float: right;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	@media (--desktop-xl) {
		margin-bottom: 1.5rem;
		width: calc(100% - 4px);
	}

	img,
	object {
		width: auto;
	}

	&.aspect-ratio-21-9 object,
	&.aspect-ratio-16-9 object,
	&.aspect-ratio-4-3 object {
		display: block;
		height: 100%;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		width: 100%;

		@media (--desktop) {
			left: 0.5rem;
			width: 'calc(100% - 0.5rem)';
		}
	}

	&.aspect-ratio-21-9::before {
		@apply --aspect-21-9; /* stylelint-disable-line */
	}

	&.aspect-ratio-16-9::before {
		@apply --aspect-16-9; /* stylelint-disable-line */
	}

	&.aspect-ratio-4-3::before {
		@apply --aspect-4-3; /* stylelint-disable-line */
	}
}

/* A képek magasságával probléma volt, fent egy másik megoldás a ratio-ra
.multi-column-descriptive-illustration-wrap.aspect-ratio-21-9,
.multi-column-descriptive-illustration-wrap.aspect-ratio-16-9,
.multi-column-descriptive-illustration-wrap.aspect-ratio-4-3 {
	margin-bottom: 1.5rem;
	margin-left: auto;
	margin-right: auto;
	object-fit: cover;
	overflow: hidden;
	padding-bottom: 0;
	padding-left: 0;
	position: relative;

	@media (--tablet) {
		margin-bottom: 0;
	}

	@media (--desktop) {
		width: calc(100% - 4px);
	}

	@media (--desktop-xl) {
		padding-bottom: 0;
		margin-bottom: 1.5rem;
	}
}

.multi-column-descriptive-illustration-wrap.aspect-ratio-21-9::before {
	.aspect-ratio(21,19);
}

.multi-column-descriptive-illustration-wrap.aspect-ratio-16-9::before {
	.aspect-ratio(16,19);
}

.multi-column-descriptive-illustration-wrap.aspect-ratio-4-3::before {
	.aspect-ratio(4,3);
}

.multi-column-descriptive-illustration-wrap.aspect-ratio-21-9 img,
.multi-column-descriptive-illustration-wrap.aspect-ratio-16-9 img,
.multi-column-descriptive-illustration-wrap.aspect-ratio-4-3 img {
	left: 50%;
	position: absolute;
	top: 0;
	transform: translateX(-50%);
}

.multi-column-descriptive-illustration-wrap.aspect-ratio-21-9 object,
.multi-column-descriptive-illustration-wrap.aspect-ratio-16-9 object,
.multi-column-descriptive-illustration-wrap.aspect-ratio-4-3 object {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
*/
.multi-column-descriptive-text-wrap {
	/* color: var(--secondary-color); */
	color: var(--default-text);
	padding-left: 1.5rem;
	padding-right: 0.5rem;

	& > *:not(.multi-column-descriptive__button):last-child {
		padding-bottom: 0;
	}

	& > .multi-column-descriptive__button:last-child {
		margin-bottom: 0;
	}
}

.parent-container .multi-column-descriptive-text-wrap {
	padding-left: 0.5rem;

	@media (--desktop-xl) {
		padding-left: 1.5rem;
	}
}

.multi-column-descriptive__super-title {
	color: var(--secondary-color);
	padding-bottom: 1.5rem;
	text-transform: uppercase;
}

.multi-column-descriptive__sub-title {
	color: var(--secondary-color);
	padding-bottom: 1.5rem;
}

.multi-column-descriptive__text {
	padding-bottom: 2rem;
}

.multi-column-descriptive__button {
	margin-bottom: 1.5rem;
}

.multi-column-descriptive__link {
	padding-bottom: 2rem;
}

.multi-column-descriptive__link-item {
	list-style-type: none;
	padding-bottom: 1.5rem;

	&:last-child {
		padding-bottom: 0;
	}
}

.multi-column-descriptive__next-wrapper {
	height: 1px;
	left: -10000em;
	overflow: hidden;
	position: absolute;
	top: auto;
	width: 1px;
}
