.secondary-link-collection {
	h3 {
		color: var(--secondary-color);
		padding: 0 2rem 2.5rem 2rem;

		.parent-container & {
			padding-left: 0;
		}
	}

	&--item {
		color: var(--secondary-color);
		padding: 0 1rem 2rem 2rem;

		.parent-container & {
			padding-left: 0;
		}

		@media (--tablet) {
			margin: 0;
			padding: 0 0 2rem 2rem;

			.parent-container & {
				padding-left: 0;
			}

			.parent-container &:nth-child(2n) {
				padding-left: 2rem;
			}
		}

		a {
			color: var(--secondary-color);
			padding-left: 2.4rem;
			position: relative;

			&:active {
				padding-left: 2.4rem;
			}

			&::before {
				background-color: var(--primary-color);
				border-radius: 1rem 0 0 1rem;
				content: '';
				display: inline-block;
				height: 2px;
				left: 8px;
				position: absolute;
				top: 7px;
				transform: rotate(45deg);
				transform-origin: 50% 50%;
				width: 8px;
			}

			&::after {
				background-color: var(--primary-color);
				border-radius: 0 1rem 1rem 0;
				content: '';
				display: inline-block;
				height: 2px;
				left: 8px;
				position: absolute;
				top: 12px;
				transform: rotate(135deg);
				transform-origin: 50% 50%;
				width: 8px;
			}

			@media (--desktop-xl) {
				&::before {
					height: 2px;
					left: 7px;
					top: 7px;
					width: 11px;
				}

				&::after {
					height: 2px;
					left: 7px;
					top: 14px;
					width: 11px;
				}
			}
		}
	}

	ul li {
		display: inline-block;
		list-style-type: none;
		width: 100%;

		a {
			display: block;
			padding: 0 0 2rem 0;
			width: 100%;

			@media (--tablet) {
				padding: 0 0 2rem 0;
			}

			@media (--desktop) {
				padding: 0 0 2rem 0;
			}

			@media (--desktop-xl) {
				padding: 1rem 0 1rem 0;
			}
		}
	}

	.row {
		&:last-of-type {
			.secondary-link-collection--item {
				@media (--desktop) {
					padding-bottom: 0;
				}
			}
		}

		.last {
			.secondary-link-collection--item {
				padding-bottom: 0;

				@media (--tablet) {
					padding-bottom: 0;
				}
			}
		}

		.penult {
			.secondary-link-collection--item {
				@media (--tablet) {
					padding-bottom: 0;
				}
			}
		}
	}
}
