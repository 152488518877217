.module-container .value-molecule {
	&__value {
		color: var(--primary-color);
		padding-bottom: 0.666667rem;
	}

	&__additional-text {
		color: var(--secondary-color);
		padding-bottom: 0.666667rem;

		&--marked {
			color: var(--primary-color);
		}
	}

	> *:last-child {
		padding-bottom: 0;
	}
}
