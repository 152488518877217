html {
	font-size: 12px;
	scroll-behavior: smooth;
}

body {
	background: var(--background-color);
}

html.rf-beige body {
	background-color: var(--background-color); /* stylelint-disable-line */
}

html.responsiveFrame.rf-reduced section#frameContainer.noAside,
html.responsiveFrame.rf-reduced section#frameContainer.wLeftAside,
html.responsiveFrame.rf-reduced section#frameContainer.wRightAside {
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 1152px;
}

html.responsiveFrame.rf-reduced section#frameContainer * {
	box-sizing: border-box;
}

.module-container {
	* {
		margin: 0;
		padding: 0;
	}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	img {
		height: auto;
		max-width: 100%;
	}
}

*::-moz-focus-inner {
	border: 0;
}

.visuallyhidden {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
