.clickable-list {
	counter-reset: item-num;
	padding: 0 6px;
	width: 100%;

	.parent-container & {
		padding-left: 0;
		padding-right: 0;
	}

	.clickable-list__list-item {
		border-top: 1px solid var(--modul-bg-active);
		cursor: pointer;
		display: block;
		padding: 3rem 5rem 2rem 2rem;
		position: relative;

		&::after {
			border: 2px solid var(--primary-color);
			border-left: none;
			border-top: none;
			content: '';
			display: block;
			height: 14px;
			position: absolute;
			right: 18px;
			top: 50%;
			transform: rotate(-45deg) translateY(-50%);
			width: 14px;
		}

		&:hover {
			background-color: var(--modul-bg-hover);
			border-color: var(--modul-border-hover);

			+ .clickable-list__list-item {
				border-top-color: var(--modul-border-hover);
			}

			&:last-child {
				border-bottom: 1px solid var(--modul-border-active);
			}
		}

		&:active {
			background-color: var(--modul-bg-active);
			border-color: var(--modul-border-active);

			+ .clickable-list__list-item {
				border-top-color: var(--modul-border-active);
			}

			&:last-child {
				border-bottom: 1px solid var(--modul-border-active);
			}
		}

		&:last-child {
			border-bottom: 1px solid var(--modul-bg-active);
		}
	}

	.clickable-list__list-item--has-icon {
		padding-left: 6.5rem;

		@media (--desktop-xl) {
			padding-left: 7.5rem;
		}
	}

	.clickable-list__icon {
		display: block;
		height: auto;
		left: 18px;
		max-height: 36px;
		position: absolute;
		top: 2rem;
		width: 36px;

		@media (--desktop) {
			max-height: 48px;
			top: 1.5rem;
			width: 48px;
		}
	}

	.clickable-list__title {
		color: var(--secondary-color);
		padding-bottom: 2rem;
		text-transform: none;

		&:last-child {
			padding-bottom: 1rem;
		}
	}

	.clickable-list__text {
		color: var(--default-text);
		padding-bottom: 3rem;

		&:last-child {
			padding-bottom: 0;
		}
	}

	ul {
		list-style-type: none;
		margin: 0 0 9px;
		padding: 0;

		&:last-child {
			margin-bottom: 14px;
		}
	}

	ul li {
		margin: 0;
		padding: 0 0 5px 18px;
		position: relative;
	}

	&.clickable-list--numbered-list {
		.clickable-list__list-item {
			padding-left: 6.5rem;

			@media (--desktop-xl) {
				padding-left: 7.5rem;
			}

			.clickable-list__title {
				&::before {
					background-color: var(--primary-color);
					border-radius: 50%;
					color: #fff;
					content: counter(item-num);
					counter-increment: item-num;
					display: block;
					height: 2.5rem;
					left: 1.5rem;
					margin: 0;
					padding: 0.25rem 0 0 0.083rem;
					position: absolute;
					text-align: center;
					top: 2rem;
					width: 2.5rem;

					@media (--desktop-xl) {
						height: 3rem;
						top: 2rem;
						width: 3rem;
					}
				}
			}
		}
	}
}
