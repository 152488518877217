/* device-price font méretek kivételkezelése*/
.inline-promo {
	.device-price__description.fs-1-25 {
		display: inline-block;
		font-size: 1.25rem;
		line-height: 1.5rem;
		margin-bottom: -0.333333rem;
		margin-top: -0.333333rem;
		padding-bottom: 1rem;
		width: 100%;

		@media (--desktop-xl) {
			font-size: 1.5rem;
			line-height: 1.75rem;
			margin-bottom: -0.333333rem;
			margin-top: -0.416667rem;
		}
	}
	.device-price__netto-brutto-text.fs-1-25 {
		display: inline-block;
		font-size: 1.25rem;
		line-height: 1.5rem;
		margin-bottom: -0.333333rem;
		margin-top: -0.333333rem;
		padding-bottom: 1rem;
		width: 100%;

		@media (--desktop-xl) {
			font-size: 1.5rem;
			line-height: 1.75rem;
			margin-bottom: -0.333333rem;
			margin-top: -0.416667rem;
		}
	}
	.device-price__old-price.fs-1-25 {
		display: inline-block;
		font-size: 1.25rem;
		line-height: 1.5rem;
		margin-bottom: -0.333333rem;
		margin-top: -0.333333rem;
		padding-bottom: 1rem;
		width: 100%;

		@media (--desktop-xl) {
			font-size: 1.5rem;
			line-height: 1.75rem;
			margin-bottom: -0.333333rem;
			margin-top: -0.416667rem;
		}
	}
	.device-price__price.fs-2-5 {
		display: inline-block;
		font-size: 3rem;
		line-height: 3.5rem;
		margin-bottom: -0.666667rem;
		margin-top: -0.833333rem;
		padding-bottom: 1rem;
		width: 100%;

		@media (--desktop-xl) {
			font-size: 4rem;
			line-height: 4.5rem;
			margin-bottom: -0.833333rem;
			margin-top: -1rem;
		}
	}
	.device-price__additional-text.fs-1-25 {
		display: inline-block;
		font-size: 1.5rem;
		line-height: 1.75rem;
		margin-bottom: -0.333333rem;
		margin-top: -0.416667rem;
		padding-bottom: 1rem;
		width: 100%;

		@media (--desktop-xl) {
			font-size: 1.75rem;
			line-height: 2rem;
			margin-bottom: -0.416667rem;
			margin-top: -0.416667rem;
		}
	}

	/* device-price font family-k kivételkezelése*/
	.device-price__description.ff-reg {
		@apply --ff-med;
	}
	.device-price__netto-brutto-text.ff-reg {
		@apply --ff-med;
	}
	.device-price__old-price.ff-reg {
		@apply --ff-med;
	}
	.device-price__additional-text.device-price__additional-text--marked.ff-bold {
		@apply --ff-ult;
	}
	.device-price__additional-text.ff-reg {
		@apply --ff-med;
	}
}
