.quick-link {
	padding: 0 2rem 0 2rem;

	&__title {
		color: var(--secondary-color);
		padding-bottom: 2rem;
	}

	&__wrapper {
		padding: 0;

		@media (--tablet) {
			height: 100%;
			padding: 0 0 2rem 0;
			text-align: center;
			width: 100%;
		}

		@media (--desktop) {
			padding: 0 2rem 1.16666rem 2rem;
		}
	}

	&__link {
		color: var(--secondary-color);
		display: table;
		height: 100%;
		padding: 1rem 0 1rem 0;
		width: 100%;

		@media (--desktop) {
			display: inline-block;
		}

		&:active {
			border-radius: 4px;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&__img {
		display: inline-block;
		height: 48px;
		width: 48px;

		@media (--desktop) {
			padding-bottom: 0.66666rem;
		}

		img {
			float: left;

			@media (--desktop) {
				float: none;
				padding: 0;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	&__text {
		display: table-cell;
		height: auto;
		padding-left: 1rem;
		text-align: left;
		vertical-align: middle;
		width: 100%;

		@media (--desktop) {
			padding-left: 0;
		}

		@media (--desktop) {
			display: block;
			text-align: center;
		}
	}
}
