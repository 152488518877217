@import '_m11p-loader.pcss';

.m11p-device-card {
	.row {
		display: flex;
		flex-direction: column;

		@media (--desktop) {
			flex-direction: row;
		}
	}

	.device-card__title {
		color: var(--secondary-color);
		padding: 0 1.667rem 3rem;
	}

	.device-card-item {
		display: flex;
		flex-shrink: 0;
		padding: 0 0.5rem 1rem;

		&.hide {
			display: none;
		}
	}

	.device-card-item__panel {
		background: #fff;
		border-radius: 8px;
		box-shadow: 0 1px 2px var(--box-shadow);
		display: flex;
		flex-direction: column;
		padding: 2rem 1.5rem;
		transition: all 0.3s ease;
		width: 100%;

		&:hover {
			box-shadow: 0 10px 10px var(--box-shadow-hover);
		}

		&:active {
			box-shadow: 0 3px 6px var(--box-shadow-hover);
		}

		&::after {
			clear: both;
			content: '';
			display: table;
		}
	}

	.device-card-item__title {
		color: var(--secondary-color);
		padding-bottom: 2.1rem;
	}

	.device-card-item__panel-wrap {
		display: flex;
		flex: 1 1 auto;
	}

	.device-card-item__img-wrap object {
		height: 100%;
		pointer-events: none;
		width: 100%;
	}

	.device-card-item__text-wrap-top {
		flex-direction: column;
	}

	.device-card-item__text-wrap-bottom > div {
		display: block;
		width: 100%;
	}

	.device-card-item__text {
		padding: 0 0 1.5rem;
	}

	.list-bullet-points {
		padding-bottom: 2.1rem;
	}

	.list-bullet-points li {
		padding: 0 0 0.7rem 1.25rem;
	}

	.device-card-item .device-price {
		margin: 0 0 1.5rem;
	}

	.device-card-item__img-wrap div {
		display: block;
	}

	/* CARD A TYPE*/
	.device-card-item__img-wrap {
		align-content: stretch;
		display: block;
		justify-content: flex-start;
		position: relative;

		&::before {
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	.device-card-item--a .device-card-item__img-wrap {
		flex: 0 1 50%;
		padding: 0 1.5rem 0 0;
		width: 50%;
	}

	.device-card-item--a .device-card-item__text-wrap {
		display: flex;
		flex: 0 1 50%;
		flex-direction: column;
		padding: 0 1rem 0 0;
		width: 50%;
	}

	.device-card-item--a .device-card-item__img-wrap + .device-card-item__text-wrap {
		padding: 0 1rem 0 1.5rem;
	}

	.device-card-item--a .device-card-item__text-wrap-top {
		display: flex;
		width: 100%;

		@media (--tablet) {
			flex: 1 1 auto;
		}
	}
	.device-card-item--a .device-card-item__text-wrap-bottom {
		align-items: flex-end;
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}
	/* CARD A TYPE*/

	/* CARD B TYPE (3 CARDS)*/
	.device-card-item--b {
		text-align: center;
	}

	.device-card-item--b .device-card-item__panel-wrap {
		display: flex;
		flex-direction: column;
		width: 100%;

		@media (--tabletOnly) {
			flex-direction: row;
		}
	}

	.device-card-item--b .device-card-item__img-wrap {
		margin: 0 0 2rem;
	}

	.device-card-item--b .device-card-item__img-wrap img {
		max-width: 184px;
	}

	.device-card--b-3 .row {
		flex-direction: column;

		@media (--desktop) {
			flex-direction: row;
		}
	}

	.device-card--slider.device-card--b-3 .row {
		flex-direction: row;
	}

	.device-card--b-3 .device-card-item--b {
		@media (--tabletOnly) {
			text-align: left;
		}
	}

	.device-card--b-3 .device-card-item__img-wrap {
		@media (--tabletOnly) {
			flex: 0 1 50%;
			margin: 0;
			max-width: 184px;
			padding: 0 1.5rem 0 0;
			width: 50%;
		}
	}

	.device-card--b-3 .device-card-item__text-wrap {
		@media (--tabletOnly) {
			display: flex;
			flex: 0 1 50%;
			flex-direction: column;
			padding: 0 1rem 0 1.5rem;
			width: 50%;
		}
	}

	.device-card-item__title--hide-tablet {
		display: block;

		@media (--tabletOnly) {
			display: none;
		}
	}

	.device-card-item__title--tablet-only {
		display: none;

		@media (--tabletOnly) {
			display: block;
		}
	}

	.device-card--b-3 .device-card-item__text-wrap-top {
		@media (--tabletOnly) {
			display: flex;
			width: 100%;
		}
	}

	.device-card--b-3 .device-card-item__text-wrap-bottom {
		@media (--tabletOnly) {
			align-items: flex-end;
			display: flex;
			flex: 1 1 50%;
			width: 100%;
		}
	}

	/* CARD B TYPE (3 CARDS)*/

	.device-card--m11p-6-4-4 .row {
		flex-flow: row wrap;

		@media (--desktop) {
			flex-flow: row;
		}
	}

	.device-card--m11p-6-4-4 .device-card-item--b {
		@media (--tabletOnly) {
			width: 49.9%;
		}
	}
	.device-card--m11p-6-4-4 .device-card-item--b .device-card-item__panel-wrap {
		flex-direction: column;
	}
}
