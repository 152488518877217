.fs-1-25,
.fs-1-5,
.fs-1-75,
.fs-2,
.fs-2-5,
.fs-3,
.fs-4,
.fs-5,
.fs-6 {
	display: inline-block;
	width: 100%;
}

/*font méreteket meghatározó mixin-ek*/
.fs-1-25 {
	display: inline-block;
	font-size: 1rem;
	line-height: 1.25rem;
	margin-bottom: -0.333333rem;
	margin-top: -0.166666rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -0.333333rem;
	margin-bottom: -0.25rem; */

	/* TeleNeo */
	/* margin-top: -0.166666rem;
	margin-bottom: -0.416666rem; */

	@media (--desktop-xl) {
		font-size: 1.25rem;
		line-height: 1.5rem;
		margin-bottom: -0.333333rem;
		margin-top: -0.25rem;

		/* Grotesk Screen */
		/* margin-top: -0.333333rem;
		margin-bottom: -0.333333rem; */

		/* TeleNeo */
		/* margin-top: -0.166666rem;
		margin-bottom: -0.5rem; */
	}
}

/* stylelint-disable */
@supports (-webkit-appearance: none) {
	.fs-1-25 {
		/* margin-top: 0; */

		@media (--tablet) {
			/* margin-top: -0.333333rem; */
		}
	}
}
/* stylelint-enable */

.fs-1-5 {
	display: inline-block;
	font-size: 1.25rem;
	line-height: 1.5rem;
	margin-bottom: -0.333333rem;
	margin-top: -0.25rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -0.333333rem;
	margin-bottom: -0.333333rem; */

	@media (--desktop-xl) {
		font-size: 1.5rem;
		line-height: 1.75rem;
		margin-bottom: -0.416666rem;
		margin-top: -0.25rem;

		/* Grotesk Screen */
		/* margin-top: -0.416667rem;
		margin-bottom: -0.333333rem; */

		/* TeleNeo */
		/* margin-top: -0.25rem;
		margin-bottom: -0.5rem; */
	}
}

.fs-1-75 {
	display: inline-block;
	font-size: 1.5rem;
	line-height: 1.75rem;
	margin-bottom: -0.416666rem;
	margin-top: -0.25rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -0.416667rem;
	margin-bottom: -0.333333rem; */

	@media (--desktop-xl) {
		font-size: 1.75rem;
		line-height: 2rem;
		margin-bottom: -0.416666rem;
		margin-top: -0.333333rem;

		/* Grotesk Screen */
		/* margin-top: -0.416667rem;
		margin-bottom: -0.416667rem; */

		/* TeleNeo */
		/* margin-top: -0.25rem;
		margin-bottom: -0.583333rem; */
	}
}

.fs-2 {
	display: inline-block;
	font-size: 1.75rem;
	line-height: 2rem;
	margin-bottom: -0.416666rem;
	margin-top: -0.333333rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -0.416667rem;
	margin-bottom: -0.416667rem; */

	/* TeleNeo */
	/* margin-top: -0.25rem;
	margin-bottom: -0.583333rem; */

	@media (--desktop-xl) {
		font-size: 2rem;
		line-height: 2.25rem;
		margin-bottom: -0.416666rem;
		margin-top: -0.416666rem;

		/* Grotesk Screen */
		/* margin-top: -0.5rem;
		margin-bottom: -0.416667rem; */

		/* TeleNeo */
		/* margin-top: -0.25rem;
		margin-bottom: -0.666666rem; */
	}
}

.fs-2-5 {
	display: inline-block;
	font-size: 2rem;
	line-height: 2.25rem;
	margin-bottom: -0.416666rem;
	margin-top: -0.416666rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -0.5rem;
	margin-bottom: -0.416667rem; */

	/* TeleNeo */
	/* margin-top: -0.25rem;
	margin-bottom: -0.666666rem; */

	@media (--desktop-xl) {
		font-size: 2.5rem;
		line-height: 3rem;
		margin-bottom: -0.666666rem;
		margin-top: -0.5rem;

		/* Grotesk Screen */
		/* margin-top: -0.75rem;
		margin-bottom: -0.583333rem; */

		/* TeleNeo */
		/* margin-top: -0.5rem;
		margin-bottom: -0.833333rem; */
	}
}

.fs-3 {
	display: inline-block;
	font-size: 2.5rem;
	line-height: 3rem;
	margin-bottom: -0.666666rem;
	margin-top: -0.5rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -0.75rem;
	margin-bottom: -0.583333rem; */

	/* TeleNeo */
	/* margin-top: -0.75rem;
	margin-bottom: -0.583333rem; */

	@media (--desktop-xl) {
		font-size: 3rem;
		line-height: 3.5rem;
		margin-bottom: -0.75rem;
		margin-top: -0.583333rem;

		/* Grotesk Screen */
		/* margin-top: -0.833333rem;
		margin-bottom: -0.666667rem; */

		/* TeleNeo */
		/* margin-top: -0.5rem;
		margin-bottom: -1rem; */
	}
}

.fs-4 {
	display: inline-block;
	font-size: 3rem;
	line-height: 3.5rem;
	margin-bottom: -0.75rem;
	margin-top: -0.583333rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -0.833333rem;
	margin-bottom: -0.666667rem; */

	/* TeleNeo */
	/* margin-top: -0.833333rem;
	margin-bottom: -0.666667rem; */

	@media (--desktop-xl) {
		font-size: 4rem;
		line-height: 4.5rem;
		margin-bottom: -0.916666rem;
		margin-top: -0.666666rem;

		/* Grotesk Screen */
		/* margin-top: -1rem;
		margin-bottom: -0.833333rem; */

		/* TeleNeo */
		/* margin-top: -0.583333rem;
		margin-bottom: -1.25rem; */
	}
}

.fs-5 {
	display: inline-block;
	font-size: 4rem;
	line-height: 4.5rem;
	margin-bottom: -0.916666rem;
	margin-top: -0.666666rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -1rem;
	margin-bottom: -0.833333rem; */

	/* TeleNeo */
	/* margin-top: -0.583333rem;
	margin-bottom: -1.25rem; */

	@media (--desktop-xl) {
		font-size: 5rem;
		line-height: 5.5rem;
		margin-bottom: -1.083333rem;
		margin-top: -0.833333rem;

		/* Grotesk Screen */
		/* margin-top: -1.25rem;
		margin-bottom: -0.916667rem; */

		/* TeleNeo */
		/* margin-top: -0.666666rem;
		margin-bottom: -1.5rem; */
	}
}

.fs-6 {
	display: inline-block;
	font-size: 5rem;
	line-height: 5.5rem;
	margin-bottom: -1.083333rem;
	margin-top: -0.833333rem;
	width: 100%;

	/* Grotesk Screen */
	/* margin-top: -1.25rem;
	margin-bottom: -0.916667rem; */

	/* TeleNeo */
	/* margin-top: -0.666666rem;
	margin-bottom: -1.5rem; */

	@media (--desktop-xl) {
		font-size: 6rem;
		line-height: 6.5rem;
		margin-bottom: -1.166666rem;
		margin-top: -1rem;

		/* Grotesk Screen */
		/* margin-top: -1.41667rem;
		margin-bottom: -1.08333rem; */

		/* TeleNeo */
		/* margin-top: -0.75rem;
		margin-bottom: -1.75rem; */
	}
}
