@import 'app-roaming/_scrollbar.pcss';
@import 'app-roaming/_dropdown.pcss';
@import 'app-roaming/_hero.pcss';
@import 'app-roaming/_table.pcss';
@import 'app-roaming/_texts.pcss';

.roaming-app {
	margin-top: -100px;
}

.roaming-app.module-container {
	max-width: 100% !important; /* stylelint-disable-line */
}

.roaming-app__select-country {
	display: block;
}
.roaming-app__select-carrier {
	display: none;
}

.roaming-app-hero-title__text-container-subline {
	display: none;
}

.roaming-app__link {
	display: none;
}

.roaming-app-hero-title__text-container-headline {
	margin-bottom: 36px;
	@media (--xl-size) {
		margin-bottom: 42px;
	}
}

.roaming-app__table {
	display: none;
}

/* .another-country-button {
	display: none;
} */

.button--choose-country {
	display: none;
}

.roaming-app__link--change-carrier {
	display: none;
}

.roaming-app__second-step {
	.roaming-app__select-country {
		display: none;
	}
	.roaming-app__select-carrier {
		display: block;
	}
	.roaming-app-hero-title__text-container-subline {
		display: block;
	}
	.roaming-app-hero-title__text-container-headline {
		margin-bottom: 19px;
	}
	.roaming-app__table {
		display: table;
	}
	.roaming-app__link {
		display: inline-block;
	}
	.roaming-app__link--change-carrier {
		display: inline-block;
	}
	.button--choose-country {
		display: block;
	}
}
