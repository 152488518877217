.tabbed-container-extended {
	.tab-list-desktop.tab-list-desktop--hasicon .tab-list .tab-list-item {
		.tab-list-item__icon {
			left: 1.41667rem;
			position: absolute;
			/* top: 1.5rem; */
			top: 50%;
			transform: translateY(-50%);
			width: 2.33333rem;
		}

		a {
			padding-left: 3.5rem;
			padding-right: 0;
			position: relative;
		}

		.tab-list-item__icon--active {
			display: none;
		}
	}

	.tab-list-desktop.tab-list-desktop--hasicon .tab-list .tab-list-item.tab-list-item--active {
		.tab-list-item__icon {
			display: none;
		}
		.tab-list-item__icon--active {
			display: block;
		}
	}
} /* tabbed-container-extended */
