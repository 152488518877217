.numbered-list {
	counter-reset: item-num;
	margin-bottom: 4rem;
	padding: 0 2rem;
	width: 100%;

	.parent-container & {
		padding-left: 0;
		padding-right: 0;
	}

	.numbered-list__list-item {
		display: block;
		margin-bottom: 3rem;
		position: relative;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.numbered-list__title {
		/* color: var(--primary-color); */
		color: var(--secondary-color);
		padding: 0 0 2rem 4.5rem;

		@media (--desktop-xl) {
			padding: 0 0 2rem 4.91667rem;
		}

		&::before {
			background-color: var(--primary-color);
			border-radius: 50%;
			color: #fff;
			content: counter(item-num);
			counter-increment: item-num;
			display: block;
			height: 2.5rem;
			left: 0;
			margin: 0;
			padding: 0.25rem 0 0 0.083rem;
			position: absolute;
			text-align: center;
			top: -0.333rem;
			width: 2.5rem;

			@media (--desktop-xl) {
				height: 3rem;
				top: -0.833rem;
				width: 3rem;
			}
		}
	}

	.numbered-list__text {
		color: var(--default-text);
		padding: 0 0 3rem 4.5rem;

		@media (--desktop-xl) {
			padding: 0 0 3rem 4.916rem;
		}

		&:last-child {
			padding-bottom: 0;
		}

		span {
			color: var(--primary-color);
		}
	}

	.list-bullet-points {
		padding: 0 0 3rem 4.5rem;

		@media (--desktop-xl) {
			padding: 0 0 3rem 4.916rem;
		}
	}
}
