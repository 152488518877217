.tabbed-container {
	.tab-list-mobile .tab-list .tab-list-item {
		border-top: 0.083333rem solid var(--modul-border);
		color: var(--secondary-color);
		display: block;
		position: relative;
		text-decoration: none;

		.tab-list-item__title {
			padding: 2rem 3rem 1.5rem 1.5rem;
			position: relative;

			&:hover {
				background-color: var(--modul-bg-hover);
				cursor: pointer;
			}

			&:active {
				background-color: var(--modul-bg-active);
			}

			&::after {
				border-right: 0.166667rem solid var(--secondary-color);
				border-top: 0.166667rem solid var(--secondary-color);
				content: '';
				display: block;
				height: 0.833333rem;
				position: absolute;
				right: 1.66667rem;
				top: 1.91667rem;
				transform: rotate(135deg);
				width: 0.833333rem;
			}

			a {
				color: inherit;
				text-decoration: none;
			}
		}

		.tab-list-content {
			display: none;
			margin: 0 1.5rem 2rem 1.5rem;
			padding: 2rem;
		}

		.tab-list-content .module-container {
			padding: 0;
		}

		&--active {
			border-top: 0.5rem solid var(--primary-color);

			.tab-list-item__title {
				color: var(--primary-color);
				padding: 1.5rem 3rem 1.5rem 1.5rem;

				&::after {
					border-right: 0.166667rem solid var(--primary-color);
					border-top: 0.166667rem solid var(--primary-color);
					position: absolute;
					right: 1.41667rem;
					top: 50%;
					transform: rotate(315deg) translateY(-50%);
				}

				a {
					font-family: var(--font-bold);
				}
			}
			.tab-list-content {
				display: block;
			}
		}

		&:last-child {
			border-bottom: 0.083333rem solid var(--modul-border);
		}
	}
}

.tabbed-container .tab-list-mobile.tab-list-mobile--single .tab-list .tab-list-item .tab-list-content {
	display: block;
	height: auto !important;/* stylelint-disable-line */
}

.tabbed-container .tab-list-mobile.tab-list-mobile--single .tab-list .tab-list-item:last-child {
	border: none;
}
