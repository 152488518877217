/* BUTTONSIZE MATRIX */

.btn-2-5,
.btn-3,
.btn-3-5 {
	border-radius: 4px; /* a 0.333333rem nem azt a görbületet adta, ami a design-ban volt*/
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	font-family: var(--font-ult);
	line-height: normal;
	outline: 0;
	padding-left: 1.83333rem;
	padding-right: 1.83333rem;
	position: relative;
	text-decoration: none;
	text-indent: 0;
	text-transform: uppercase;
	transition: all 0.3s ease-out;
}

.btn-2-5 {
	font-size: 1rem;
	padding-bottom: 0.333333rem;
	padding-top: 0.25rem;

	@media (--desktop-xl) {
		padding-bottom: 0.583333rem;
		padding-top: 0.5rem;
	}
}

.btn-3 {
	font-size: 1rem;
	padding-bottom: 0.583333rem;
	padding-top: 0.5rem;

	@media (--desktop-xl) {
		font-size: 1.25rem;
		padding-bottom: 0.666667rem;
		padding-top: 0.583333rem;
	}
}

.btn-3-5 {
	font-size: 1.25rem;
	padding-bottom: 0.666667rem;
	padding-top: 0.583333rem;

	@media (--desktop-xl) {
		font-size: 1.5rem;
		padding-bottom: 0.833333rem;
		padding-top: 0.666667rem;
	}
}
.device-card-item__text-wrap-bottom {
	a + a {
		margin: 0 0 5px 5px;
	}
}

/* BUTTON SIZE MATRIX ENDS */

/* BORDERED BUTTONS + COLORS */

.btn-event-trigger {
	cursor: pointer;
}

.btn-brd-magenta,
.btn-brd-grey,
.btn-brd-white {
	&,
	&:visited {
		background-color: transparent;
		border-style: solid;
		border-width: 2px;
	}
}

.btn-brd-magenta {
	&,
	&:visited {
		border-color: var(--primary-color);
		color: var(--primary-color);
	}

	&:hover,
	&:focus,
	.btn-event-trigger:hover &.btn-primary,
	.btn-event-trigger:focus &.btn-primary {
		background-color: var(--primary-color);
		color: #fff;
	}

	&:active,
	.btn-event-trigger:active &.btn-primary {
		background-color: #bf0a67;
		border-color: transparent;
		color: #fff;
	}
}

.btn-brd-grey {
	&,
	&:visited {
		border-color: #939290;
		color: #939290;
	}

	&:hover,
	&:focus,
	.btn-event-trigger:hover &.btn-primary,
	.btn-event-trigger:focus &.btn-primary {
		background-color: #939290;
		border-color: transparent;
		color: #fff;
	}

	&:active,
	.btn-event-trigger:active &.btn-primary {
		background-color: #767574;
		border-color: transparent;
		color: #fff;
	}
}

.btn-brd-white {
	&,
	&:visited {
		border-color: #fff;
		color: #fff;
	}

	&:hover,
	&:focus,
	.btn-event-trigger:hover &.btn-primary,
	.btn-event-trigger:focus &.btn-primary {
		background-color: #fff;
		border-color: #fff;
		color: var(--secondary-color);
	}

	&:active,
	.btn-event-trigger:active &.btn-primary {
		background-color: #d6d6d6;
		border-color: #d6d6d6;
		color: var(--secondary-color);
	}
}

/* BORDERED BUTTONS + COLORS ENDS */

/* FILLED BUTTONS + COLORS */

.btn-full-magenta,
.btn-full-grey,
.btn-full-white {
	&,
	&:visited {
		border: 2px solid transparent;
	}
}

.btn-full-magenta {
	&,
	&:visited {
		background-color: var(--primary-color);
		color: #fff;
	}

	&:hover,
	&:focus,
	.btn-event-trigger:hover &,
	.btn-event-trigger:active & {
		background-color: #bf0a67;
		color: #fff;
	}

	&:active,
	.btn-event-trigger:active & {
		background-color: #ad0f60;
		color: #fff;
	}
}

.btn-full-grey {
	&,
	&:visited {
		background-color: #939290;
		color: #fff;
	}

	&:hover,
	&:focus,
	.btn-event-trigger:hover &,
	.btn-event-trigger:focus & {
		background-color: #807f7d;
		color: #fff;
	}

	&:active,
	.btn-event-trigger:active & {
		background-color: #767574;
		color: #fff;
	}
}

.btn-full-white {
	&,
	&:visited {
		background-color: #fff;
		color: var(--secondary-color);
	}

	&:hover,
	&:focus,
	.btn-event-trigger:hover &,
	.btn-event-trigger:focus & {
		background-color: #d6d6d6;
		color: var(--secondary-color);
	}

	&:active,
	.btn-event-trigger:active & {
		background-color: #c1c1c1;
		color: var(--secondary-color);
	}
}

/* FILLED BUTTONS + COLORS ENDS */

/* DISABLED FUNCTIONS  */

.btn-disabled {
	cursor: default;
	opacity: 0.4;

	&.btn-brd-magenta,
	&.btn-brd-grey,
	&.btn-brd-white {
		&:hover,
		&:active,
		&:visited,
		&:focus {
			background-color: transparent;
		}
	}

	&.btn-brd-magenta {
		&:hover,
		&:active,
		&:visited,
		&:focus {
			color: var(--secondary-color);
		}
	}

	&.btn-brd-grey {
		&:hover,
		&:active,
		&:visited,
		&:focus {
			border-color: #939290;
			color: var(--secondary-color);
		}
	}

	&.btn-brd-white {
		&:hover,
		&:active,
		&:visited,
		&:focus {
			color: #fff;
		}
	}

	&.btn-full-magenta {
		&:hover,
		&:active,
		&:visited,
		&:focus {
			background-color: var(--secondary-color);
		}
	}

	&.btn-full-grey {
		&:hover,
		&:active,
		&:visited,
		&:focus {
			background-color: #939290;
		}
	}

	&.btn-full-white {
		&:hover,
		&:active,
		&:visited,
		&:focus {
			background-color: #fff;
			border-color: #fff;
		}
	}
}
/* DISABLED FUNCTIONS ENDS */

/* LINK STYLE BUTTON */
.btn-link {
	background: none;
	border: none;
	cursor: pointer;
	text-decoration: underline;
}
/* LINK STYLE BUTTON ENDS */
