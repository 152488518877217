/*OVERRIDE DEFAULT BOOTSTRAP GRID*/
.device-card .col-sm-6 {
	@media (--tablet) {
		width: 49.93%;
	}
}
/*OVERRIDE DEFAULT BOOTSTRAP GRID*/

.device-card .row {
	display: flex;
	flex-direction: column;

	@media (--desktop) {
		flex-direction: row;
	}
}

.parent-container .device-card > .row {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}

.device-card__title {
	/* color: var(--primary-color); */
	color: var(--secondary-color);
	padding: 0 1.667rem 3rem;
}

.parent-container .device-card__title {
	padding-left: 0;
	padding-right: 0;
}

.device-card-item {
	display: flex;
	flex-shrink: 1;
	padding: 0 0.5rem 1rem;
}

.device-card-item.btn-event-trigger:hover .module-link-single,
.device-card-item.pointer-event-trigger:hover .module-link-single {
	text-decoration: none;
}

.device-card-item.btn-event-trigger .module-link-single:active,
.device-card-item.pointer-event-trigger .module-link-single:active {
	background: transparent;
}

.device-card--slider .device-card-item {
	flex-shrink: 0;
}

.device-card-item__panel {
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px var(--box-shadow);
	display: flex;
	flex-direction: column;
	padding: 2rem 1.5rem;
	transition: all 0.3s ease;
	width: 100%;

	&:hover {
		/* box-shadow: 0 10px 10px rgba(0,0,0,.19); */
		box-shadow: 0 10px 10px var(--box-shadow-hover);
	}

	&:active {
		/* box-shadow: 0 3px 6px rgba(0,0,0,.19); */
		box-shadow: 0 10px 10px var(--box-shadow-hover);
	}

	&::after {
		clear: both;
		content: '';
		display: table;
	}

	@media (--tabletOnly) {
		border-radius: 2px;
	}
}

.device-card-item__title {
	color: var(--secondary-color);
	padding-bottom: 2.1rem;
}

.device-card-item__panel-wrap {
	display: flex;
	flex: 1 1 auto;
}

/*.device-card-item__img-wrap img,*/
.device-card-item__img-wrap object {
	height: 100%;
	width: 100%;
}

/* responsive image fixed*/
.device-card-item__img-wrap {
	align-content: stretch;
	display: flex;
	justify-content: center;
	position: relative;

	&::before {
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.device-card-item__img-wrap div {
	display: flex;
}
/* responsive image fixed*/

.device-card-item__text-wrap-top {
	flex-direction: column;
}

.device-card-item__text-wrap-bottom > div {
	display: block;
	width: 100%;
}

.device-card-item__text {
	color: var(--secondary-color);
	padding: 0 0 1.5rem;
}

.device-card-item__text_larger {
	color: var(--secondary-color);
	padding: 0 0 0.66667rem;
}

.device-card .list-bullet-points {
	padding-bottom: 2.1rem;
}

.device-card .list-bullet-points li {
	padding: 0 0 0.7rem 1.25rem;
}

.device-card-item .device-price,
.device-card-item .service-price {
	margin: 0 0 1.5rem;
	width: 100%;
}

/* MODULE-LINK*/

.device-card-item--a .device-card-item__text-wrap-bottom a + a,
.device-card--service-f .device-card-item__text-wrap-bottom a + a,
.device-card-item--b .device-card-item__text-wrap-bottom a + a {
	display: block;
	margin: 1.5rem 0 5px 0;
}
