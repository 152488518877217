/* CARD B-2 TYPE (4 CARDS)*/
.device-card--b-4 .row {
	@media (--desktopOnly) {
		align-content: flex-end;
		align-items: stretch;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	@media (--desktop) {
		flex-direction: row;
	}
}

.device-card--b-4 *[class*='btn-2'],
.device-card--b-4 *[class*='btn-2-5'],
.device-card--b-4 *[class*='btn-3'] {
	align-self: center;

	@media (--tabletOnly) {
		align-self: flex-start;
	}
}

.device-card--b-4 .device-card-item__panel {
	flex-direction: column;
}

.device-card--b-4 .device-card-item__panel-wrap {
	@media (--desktopOnly) {
		flex-direction: column;
	}
	flex-wrap: wrap;
}

.device-card--b-4 .device-card-item--b {
	@media (--desktopOnly) {
		width: 49.9%;
	}
}

.device-card--b-4 .device-card-item__text-wrap {
	@media (--desktopOnly) {
		align-items: stretch;
		flex: auto;
		flex-grow: 1;
		width: 100%;
	}
}

.device-card--b-4 .device-card-item__text-wrap-bottom {
	align-items: stretch;
}
/* CARD B-2 TYPE (4 CARDS)*/
