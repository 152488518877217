.tertiary-link-collection {
	h3 {
		color: var(--secondary-color);
		padding: 0 2rem 2.083rem 2rem;

		.parent-container & {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&--item {
		background: #fff;
		border-radius: 4px;
		margin: 0 0.5rem 0.5rem 0.5rem;
		padding: 1.5rem 1.5rem 0 1.5rem;
		width: 100%;

		.parent-container & {
			margin-left: 0;
			margin-right: 0;
		}

		&.closed {
			ul {
				display: none;
			}
			.tertiary-link-collection--title {
				.tertiary-link-collection--arrow {
					transform: rotate(0deg);
				}
			}
		}

		@media (--tablet) {
			background: none;
			border-radius: 0;
			margin: 0;
			padding: 0 2rem 0 2rem;

			&.closed {
				ul {
					display: block;
				}
			}
		}

		a {
			color: var(--secondary-color);
		}
	}

	&--title {
		color: var(--secondary-color);
		display: inline-block;
		padding: 0 0 1.5rem 0;
		width: 100%;

		@media (--desktop-xl) {
			padding: 0 0 1rem 0;
		}
	}

	&--arrow {
		color: #262626;
		font-family: var(--font-icon-o);
		font-size: 1.0909rem;
		height: 0.4166rem;
		line-height: 0.6rem;
		position: absolute;
		right: 2.5rem;
		top: 1.8333rem;
		transform: rotate(180deg);
		transition: transform 0.3s ease-in-out;
		width: 0.833rem;

		@media (--tablet) {
			display: none;
		}
	}

	ul li {
		display: inline-block;
		list-style-type: none;
		width: 100%;

		a {
			display: block;
			padding: 0 0 2rem 0;
			width: 100%;

			&:hover {
				text-decoration: none;
			}

			@media (--tablet) {
				padding: 0 0 2rem 0;
			}

			@media (--desktop) {
				padding: 0 0 2rem 0;
			}

			@media (--desktop-xl) {
				padding: 1rem 0 1rem 0;
			}
		}
	}

	.row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		&::before,
		&::after {
			content: initial;
		}

		> div {
			display: flex;
		}
	}

	.parent-container & .row > div .tertiary-link-collection--item {
		@media (--tablet) {
			padding-left: 0;
		}
	}

	.parent-container & .row > div:nth-child(2n) .tertiary-link-collection--item {
		@media (--tablet) {
			padding-right: 0;
		}
	}

	@media (--tablet) {
		.row:last-of-type [class*='col-']:nth-child(5) ul li:last-child a,
		.row:last-of-type [class*='col-']:nth-child(6) ul li:last-child a {
			padding-bottom: 0;
		}
	}

	@media (--desktop) {
		.row:last-of-type [class*='col-']:nth-child(4) ul li:last-child a,
		.row:last-of-type [class*='col-']:nth-child(5) ul li:last-child a,
		.row:last-of-type [class*='col-']:nth-child(6) ul li:last-child a {
			padding-bottom: 0;
		}
	}

	@media (--desktop-xl) {
		.row:last-of-type [class*='col-'] ul li:last-child a {
			padding-bottom: 0;
		}
	}
}
