.module-wrapper .module-container,
.frameconfig-roaming-app-hero-title--full-width .module-container {
	margin-left: auto;
	margin-right: auto;
	max-width: 1152px;
}

.roaming-app-hero-title {
	background-color: var(--primary-color);
	background-image: url('/static/c3/g/roaming-app-bg.png');
	background-position: top right;
	background-repeat: no-repeat;
	margin-top: -64px;
	max-width: 100% !important; /* stylelint-disable */
	position: relative;
	width: 100%;

	@media (--l-size) {
		margin-top: -152px;
	}

	@media (--xl-size) {
		margin-top: -152px;
	}

	&__text-container {
		padding-bottom: 12px;
		padding-top: 97px;

		@media (--l-size) {
			padding-top: 160px;
		}

		@media (--xl-size) {
			padding-top: 160px;
		}

		&-headline {
			color: #fff;
			font-family: 'TeleGroteskNext-Ultra';
			font-size: 40px;
			line-height: 44px;
			margin: 0 12px 36px;
			max-width: 100%;
			width: auto;

			@media (--xl-size) {
				margin: 0 auto 42px;
				max-width: 1108px;
				padding: 0 32px;
			}
		}

		&-subline {
			color: #fff;
			font-family: 'TeleGroteskNext-Regular';
			font-size: 20px;
			line-height: 24px;
			margin: 0 12px 42px;
			max-width: 100%;

			@media (--xl-size) {
				margin: 0 auto 32px;
				max-width: 1108px;
				padding: 0 32px;
			}
			a {
				color: #fff;
				text-decoration: none;
				border-bottom: 1px solid #fff;
			}
		}
	}
	&::before {
		content: '';
		width: 100%;
		height: 112px;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(24, 33, 77, 0.24) 0%, rgba(24, 33, 77, 0) 89.73%);
	}
	&::after {
		content: '';
		width: 100%;
		height: 12px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		background: var(--background-color);
		position: absolute;
		bottom: 0;
		left: 0;
	}
}
