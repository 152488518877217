section {
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;

	.inner_section {
		margin: 0 auto;
		max-width: 1140px;
		padding: 20px;
	}

	.inner_section .section-label {
		color: #8088ab;
		font-family: 'TeleNeoWeb', var(--font-ult);
		font-kerning: normal;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		margin: 0;
		margin-bottom: 12px;
		opacity: 0.64;
		text-transform: uppercase;
	}

	.inner_section .section-title {
		color: var(--secondary-color);
		font-family: 'TeleNeoWeb', var(--font-med);
		font-kerning: normal;
		font-size: 32px;
		font-weight: 400;
		line-height: 32px;
		margin: 0;
		padding: 0;
	}

	.inner_section .morelink {
		color: var(--primary-color);
		display: block;
		float: right;
		font-family: 'TeleNeoWeb', var(--font-ult);
		font-kerning: normal;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		position: relative;
		text-align: right;
		text-decoration: none;
		text-transform: uppercase;
		top: -24px;
	}

	.inner_section .morelink:hover {
		text-decoration: underline;
	}
}

.third-party-module-full-width .m28_devices_carousel_cont.module-container,
.frameconfig-hero-title--full-width .m28_devices_carousel_cont.module-container {
	max-width: 100%;
}

.third-party-module-full-width .m28_devices_carousel_inner_cont,
.frameconfig-hero-title--full-width .m28_devices_carousel_inner_cont {
	overflow: hidden;
}

.m28_devices_carousel_cont {
	box-sizing: border-box;
	max-width: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.m28_devices_carousel_cont * {
	box-sizing: border-box;
}

.m28_devices_carousel_cont .m28_devices_carousel_overlay_left {
	background: linear-gradient(90deg, var(--background-color) 0%, rgba(239, 242, 244, 0) 100%); /* a színt ki kell cserélni, ha sikerül updatelni a global hátteret */
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: ~'calc((100% - 1140px) / 2)';/* stylelint-disable-line */
}

.m28_devices_carousel_cont .m28_devices_carousel_overlay_right {
	background: linear-gradient(270deg, var(--background-color) 0%, rgba(239, 242, 244, 0) 100%); /* a színt ki kell cserélni, ha sikerül updatelni a global hátteret */
	display: block;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: ~'calc((100% - 1140px) / 2)'; /* stylelint-disable-line */
}

.m28_devices_carousel_inner_cont {
	margin: 0 auto;
	max-width: 1140px;
	position: relative;
	width: 100%;
}

.m28_devices_carousel {
	margin-left: -12px;
	max-width: 1108px;
	padding-top: 20px;
	width: 100%;
}

.m28_devices_carousel .slick-list {
	overflow: visible;
}

.m28_devices_carousel .slick-slide {
	overflow: visible;
}

.m28_devices_carousel .slick-dots {
	line-height: 0px;
	list-style: none;
	margin: 20px 0 0;
	padding: 0;
	text-align: center;
	width: 100%;
}

.m28_devices_carousel .slick-dots li {
	cursor: pointer;
	display: inline-block;
	padding: 0;
	position: relative;
}

.m28_devices_carousel .slick-dots li button {
	background: #d3dfeb;
	border: none;
	border-radius: 4px;
	color: transparent;
	cursor: pointer;
	display: block;
	float: left;
	font-size: 0;
	height: 8px;
	line-height: 0;
	margin: 4px;
	outline: none;
	padding: 0;
	text-indent: -9999em;
	width: 8px;
}

.m28_devices_carousel .slick-dots li.slick-active button {
	background: var(--primary-color);
	box-shadow: 0 4px 12px rgba(226, 0, 116, 0.24);
}

.m28_devices_carousel_inner_cont .slick-prev,
.m28_devices_carousel_inner_cont .slick-next {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);/* stylelint-disable-line */
	background-color: transparent;
	background-image: url('/static/c3/g/modules/m28-personalized-device-pager/m28-personalized-device-pager-arrow-magenta.png'); /* stylelint-disable-line */
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border: none;
	color: transparent;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 72px;
	line-height: 0;
	outline: none;
	padding: 0;
	position: absolute;
	top: 50%;
	width: 72px;
	z-index: 1;
}

.m28_devices_carousel_inner_cont .slick-prev.slick-disabled,
.m28_devices_carousel_inner_cont .slick-next.slick-disabled {
	display: none !important;/* stylelint-disable-line */
}

.m28_devices_carousel_inner_cont .slick-prev {
	left: 0;
	right: unset;
}

.m28_devices_carousel_inner_cont .slick-next {
	left: unset;
	right: 0;
	transform: scaleX(-1);
}

.m28_devices_carousel .m28_devices_carousel_item {
	background: #fff;
	border: 2px solid #fff;
	border-radius: 8px;
	box-shadow: 0 4px 4px rgba(93, 100, 130, 0.12);
	margin: 90px 12px 0;
	max-width: 253px;
	min-width: 253px;
	position: relative;
	text-align: center;
	user-select: none;
	width: 253px;
}

.m28_devices_carousel .m28_devices_carousel_item a {
	text-decoration: none;
}

.m28_devices_carousel .m28_devices_carousel_item .img_cont {
	height: 160px;
	margin-top: -90px;
	position: relative;
	width: 100%;
}

.m28_devices_carousel .m28_devices_carousel_item .img_cont img {
	display: block;
	height: 100%;
	object-fit: contain;
	object-position: center center;
	width: 100%;
}

.m28_devices_carousel .m28_devices_carousel_item .img_cont .img_badge {
	background: #ff9a1e;
	border-radius: 12px;
	bottom: 16px;
	color: #fff;
	font-family: 'TeleNeoWeb', var(--font-ult);
	font-size: 12px;
	left: 16px;
	line-height: 12px;
	padding: 5px 12px;
	position: absolute;
	text-transform: uppercase;
}

.m28_devices_carousel .m28_devices_carousel_item .text_cont {
	align-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 196px;
	justify-content: center;
	/* margin-top: -20px; */
	padding: 0 20px;
	width: 100%;
}

.m28_devices_carousel .m28_devices_carousel_item .text_cont h3.text_title {
	align-items: center;
	color: var(--secondary-color);
	display: flex;
	font-family: 'TeleNeoWeb', var(--font-ult);
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	height: 60px;
	justify-content: center;
	line-height: 20px;
	margin-bottom: 16px;
}

.m28_devices_carousel .m28_devices_carousel_item .text_cont span.text_package {
	color: var(--default-text);
	font-family: 'TeleNeoWeb', var(--font-reg);
	font-size: 16px;
	font-weight: normal;
	line-height: 20px;
}

.m28_devices_carousel .m28_devices_carousel_item .text_prices {
	height: 80px;
	text-align: center;
	width: 100%;
}

.m28_devices_carousel .m28_devices_carousel_item .text_prices span.primary-price {
	color: var(--secondary-color);
	display: block;
	font-family: 'TeleNeoWeb', var(--font-ult);
	font-size: 30px;
	font-style: normal;
	font-weight: 800;
	line-height: 36px;
}

.m28_devices_carousel .m28_devices_carousel_item .text_prices span.secondary-price {
	color: var(--default-text);
	display: block;
	font-family: 'TeleNeoWeb', var(--font-reg);
	font-size: 16px;
	font-weight: normal;
	line-height: 20px;
}

.m28_devices_carousel .m28_devices_carousel_item .card_button {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top: 1px solid var(--modul-bg-active);
	cursor: pointer;
	display: block;
	padding: 16px 20px;
}

.m28_devices_carousel .m28_devices_carousel_item .m28_devices_carousel_item_btn {
	border: 2px solid var(--primary-color);
	border-radius: 4px;
	box-sizing: border-box;
	color: var(--primary-color);
	display: block;
	font-family: 'TeleNeoWeb', var(--font-ult);
	font-size: 16px;
	font-weight: 800;
	line-height: 20px;
	padding: 12px 16px;
	text-align: center;
	text-decoration: inherit;
	text-transform: uppercase;
	transition: all 0.3s ease-out;
}

.m28_devices_carousel .m28_devices_carousel_item .m28_devices_carousel_item_btn:hover {
	background: var(--primary-color);
	color: #fff;
	transition: all 0.3s ease-out;
}

.m28_devices_carousel .m28_devices_carousel_item:hover {
	/* box-shadow: 0px 12px 20px rgba(93, 100, 130, 0.36); */
	box-shadow: 0 12px 20px #5d64825c;
	cursor: pointer;
	transition: all 0.3s ease-out;
	/* border: 2px solid var(--primary-color); */
}

.m28_devices_carousel .m28_devices_carousel_item:hover .img_cont {
	transition: all 0.1s ease-out;
	/* transform:scale(1.1); */
}

.m28_devices_carousel_cont .indicator {
	background: #e5e8eb;
	border-radius: 2px;
	height: 4px;
	margin: 25px auto;
	position: relative;
	width: 1084px;
}

.m28_devices_carousel_cont .scrollbar {
	border-radius: 8px;
	cursor: pointer;
	height: 30px;
	position: relative;
	top: -15px;
	width: 210px;
}

.m28_devices_carousel_cont .scrollbar p {
	background: var(--primary-color);
	border-radius: 4px;
	box-shadow: 0 4px 12px rgba(226, 0, 116, 0.24);
	height: 4px;
	margin: 0;
	position: relative;
	top: 15px;
	width: 100%;
}

@media only screen and (max-width: 1324px) {
	.m28_devices_carousel_inner_cont .slick-prev {
		left: 0;
	}

	.m28_devices_carousel_inner_cont .slick-next {
		right: 0;
	}
}

@media only screen and (max-width: 1280px) {
	.m28_devices_carousel {
		max-width: 831px;
	}
}

@media only screen and (max-width: 1140px) {
	.m28_devices_carousel_inner_cont {
		padding: 0 20px;
	}

	.m28_devices_carousel_cont .m28_devices_carousel_overlay_left {
		display: none;
	}

	.m28_devices_carousel_cont .m28_devices_carousel_overlay_right {
		display: none;
	}
}

@media only screen and (max-width: 990px) {
	.m28_devices_carousel {
		max-width: 554px;
	}
}

@media only screen and (max-width: 576px) {
	section .inner_section .section-label {
		font-size: 12px;
		line-height: 16px;
		margin-bottom: 8px;
	}

	section .inner_section .section-title {
		font-size: 24px;
		line-height: 32px;
	}

	section .inner_section .morelink {
		float: none;
		font-size: 16px;
		line-height: 20px;
		text-align: left;
		top: 8px;
	}

	.m28_devices_carousel {
		margin: 0 auto;
		max-width: 200px;
	}

	.m28_devices_carousel .m28_devices_carousel_item {
		margin: 60px 8px 0;
		max-width: 184px;
		min-width: 184px;
		opacity: 0.6;
		transition: all 0.3s ease-out;
		width: 184px;
	}
	.m28_devices_carousel .slick-active .m28_devices_carousel_item {
		box-shadow: 0 12px 20px rgba(93, 100, 130, 0.36);
		cursor: pointer;
		/* border: 2px solid var(--primary-color); */
		opacity: 1;
	}
	.m28_devices_carousel .m28_devices_carousel_item .img_cont {
		height: 120px;
		margin-top: -60px;
	}
	.m28_devices_carousel .m28_devices_carousel_item .text_cont {
		height: 156px;
		margin-top: 0;
		padding: 0 15px;
	}
	.m28_devices_carousel .m28_devices_carousel_item .text_cont h3.text_title {
		font-size: 16px;
		line-height: 20px;
	}
	.m28_devices_carousel .m28_devices_carousel_item .text_cont span.text_package {
		font-size: 12px;
		line-height: 14px;
	}
	.m28_devices_carousel .m28_devices_carousel_item .text_prices span.primary-price {
		font-size: 24px;
		line-height: 30px;
	}
	.m28_devices_carousel .m28_devices_carousel_item .text_prices span.secondary-price {
		font-size: 12px;
		line-height: 20px;
	}
	.m28_devices_carousel .m28_devices_carousel_item .m28_devices_carousel_item_btn {
		font-size: 14px;
		/* line-height: 52px; */
	}

	.m28_devices_carousel .m28_devices_carousel_item:hover {
		border: 2px solid #fff;
		box-shadow: none;
		cursor: default;
		transition: none;
	}
	.m28_devices_carousel .slick-active .m28_devices_carousel_item:hover {
		box-shadow: 0 12px 20px var(--box-shadow-hover);
		cursor: pointer;
		transition: none;
		/* border: 2px solid var(--primary-color); */
	}
	.m28_devices_carousel .m28_devices_carousel_item:hover .img_cont {
		transform: none;
		transition: none;
	}

	.m28_devices_carousel_cont .indicator {
		left: -9999;
		position: absolute;
		top: -9999;
	}
}

/* Slider */
.slick-slider {
	-webkit-tap-highlight-color: transparent; /* stylelint-disable-line */
	box-sizing: border-box; /* stylelint-disable-line */
	display: block;
	position: relative;
	touch-action: pan-y;
	user-select: none;
}

.slick-list {
	display: block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	display: block;
	left: 0;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	top: 0;
}

.slick-track::before,
.slick-track::after {
	content: '';
	display: table;
}

.slick-track::after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}

[dir='rtl'] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	border: 1px solid transparent;
	display: block;
	height: auto;
}

.slick-arrow.slick-hidden {
	display: none;
}
