/* stylelint-disable */
@font-face {
	font-family: 'thin';
	src: url('/static/c3/f/TeleGroteskScreen-Thin.woff2') format('woff2'), url('/static/c3/f/TeleGroteskScreen-Thin.woff') format('woff'),
		url('/static/c3/f/TeleGroteskScreen-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'regular';
	src: url('/static/c3/f/TeleGroteskScreen-Regular.woff2') format('woff2'), url('/static/c3/f/TeleGroteskScreen-Regular.woff') format('woff'),
		url('/static/c3/f/TeleGroteskScreen-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'regular-italic';
	src: url('/static/c3/f/TeleGroteskScreen-RegularItalic.woff2') format('woff2'), url('/static/c3/f/TeleGroteskScreen-RegularItalic.woff') format('woff'),
		url('/static/c3/f/TeleGroteskScreen-RegularItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'medium';
	src: url('/static/c3/f/TeleGroteskScreen-Medium.woff2') format('woff2'), url('/static/c3/f/TeleGroteskScreen-Medium.woff') format('woff'),
		url('/static/c3/f/TeleGroteskScreen-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'medium-italic';
	src: url('/static/c3/f/TeleGroteskScreen-MediumItalic.woff2') format('woff2'), url('/static/c3/f/TeleGroteskScreen-MediumItalic.woff') format('woff'),
		url('/static/c3/f/TeleGroteskScreen-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'bold';
	src: url('/static/c3/f/TeleGroteskScreen-Bold.woff2') format('woff2'), url('/static/c3/f/TeleGroteskScreen-Bold.woff') format('woff'),
		url('/static/c3/f/TeleGroteskScreen-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'ultra';
	src: url('/static/c3/f/TeleGroteskScreen-Ultra.woff2') format('woff2'), url('/static/c3/f/TeleGroteskScreen-Ultra.woff') format('woff'),
		url('/static/c3/f/TeleGroteskScreen-Ultra.ttf') format('truetype');
}

@font-face {
	font-family: 'teleicon-solid';
	src: url('/static/c3/f/TeleIcon-Solid.ttf') format('truetype');
}

@font-face {
	font-family: 'teleicon-outline';
	src: url('/static/c3/f/TeleIcon-Outline.ttf') format('truetype');
}

@font-face {
	font-family: 'akziden-super';
	src: url('../f/AkzidenzGrotesk-Super.eot');
	src: url('../f/AkzidenzGrotesk-Super.eot?#iefix') format('embedded-opentype'), url('../f/AkzidenzGrotesk-Super.woff') format('woff'),
		url('../f/AkzidenzGrotesk-Super.ttf') format('truetype'), url('../f/AkzidenzGrotesk-Super.svg') format('svg');
}

@font-face {
	font-family: 'TeleGroteskNext-Thin';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleGroteskNext-Thin.woff2') format('woff2'), url('/static/c3/f/TeleGroteskNext-Thin.woff') format('woff'),
		url('/static/c3/f/TeleGroteskNext-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleGroteskNext-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleGroteskNext-Regular.woff2') format('woff2'), url('/static/c3/f/TeleGroteskNext-Regular.woff') format('woff'),
		url('/static/c3/f/TeleGroteskNext-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleGroteskNext-RegularItalic';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleGroteskNext-RegularItalic.woff2') format('woff2'), url('/static/c3/f/TeleGroteskNext-RegularItalic.woff') format('woff'),
		url('/static/c3/f/TeleGroteskNext-RegularItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleGroteskNext-Medium';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleGroteskNext-Medium.woff2') format('woff2'), url('/static/c3/f/TeleGroteskNext-Medium.woff') format('woff'),
		url('/static/c3/f/TeleGroteskNext-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleGroteskNext-MediumItalic';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleGroteskNext-MediumItalic.woff2') format('woff2'), url('/static/c3/f/TeleGroteskNext-MediumItalic.woff') format('woff'),
		url('/static/c3/f/TeleGroteskNext-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleGroteskNext-Bold';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleGroteskNext-Bold.woff2') format('woff2'), url('/static/c3/f/TeleGroteskNext-Bold.woff') format('woff'),
		url('/static/c3/f/TeleGroteskNext-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleGroteskNext-Ultra';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleGroteskNext-Ultra.woff2') format('woff2'), url('/static/c3/f/TeleGroteskNext-Ultra.woff') format('woff'),
		url('/static/c3/f/TeleGroteskNext-Ultra.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleNeo-Thin';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleNeo-Thin.woff2') format('woff2'), url('/static/c3/f/TeleNeo-Thin.woff') format('woff'),
		url('/static/c3/f/TeleNeo-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleNeo-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleNeo-Regular.woff2') format('woff2'), url('/static/c3/f/TeleNeo-Regular.woff') format('woff'),
		url('/static/c3/f/TeleNeo-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleNeo-RegularItalic';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleNeo-RegularItalic.woff2') format('woff2'), url('/static/c3/f/TeleNeo-RegularItalic.woff') format('woff'),
		url('/static/c3/f/TeleNeo-RegularItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleNeo-Medium';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleNeo-Medium.woff2') format('woff2'), url('/static/c3/f/TeleNeo-Medium.woff') format('woff'),
		url('/static/c3/f/TeleNeo-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleNeo-MediumItalic';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleNeo-MediumItalic.woff2') format('woff2'), url('/static/c3/f/TeleNeo-MediumItalic.woff') format('woff'),
		url('/static/c3/f/TeleNeo-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleNeo-Bold';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleNeo-Bold.woff2') format('woff2'), url('/static/c3/f/TeleNeo-Bold.woff') format('woff'),
		url('/static/c3/f/TeleNeo-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'TeleNeo-ExtraBold';
	font-style: normal;
	font-weight: normal;
	src: url('/static/c3/f/TeleNeo-ExtraBold.woff2') format('woff2'), url('/static/c3/f/TeleNeo-ExtraBold.woff') format('woff'),
		url('/static/c3/f/TeleNeo-ExtraBold.ttf') format('truetype');
}
/* stylelint-enable */
