/* SERVICE CARD E (MODIFIER CLASS FOR A TABLET VIEW WITHOUT IMG)*/
.device-card--b.device-card--e .row {
	@media (--tabletOnly) {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.device-card--b.device-card--e-3 .device-card-item__text-wrap {
	@media (--tabletOnly) {
		flex: 1 1 100%;
		flex-wrap: wrap;
		padding: 0;
		width: 100%;
	}
}

.device-card-item--b.device-card--service-e {
	@media (--tabletOnly) {
		text-align: center;
	}
}

.device-card-item--b.device-card--service-e .device-card-item__panel-wrap {
	@media (--tabletOnly) {
		flex-direction: column;
		webkit-box-direction: column;
	}
}

.device-card-item--b.device-card--service-e .device-card-item__text-wrap {
	@media (--tabletOnly) {
		flex-grow: 1;
		flex-shrink: 1;
		width: 100%;
	}
}

.device-card--b .device-card-item--b.device-card--service-e .device-card-item__text-wrap-bottom > div {
	@media (--tabletOnly) {
		align-items: center;
	}
}

.device-card--b-4 .device-card-item--b.device-card--service-e *[class*='btn-2'],
.device-card--b-4 .device-card-item--b.device-card--service-e *[class*='btn-2-5'],
.device-card--b-4 .device-card-item--b.device-card--service-e *[class*='btn-3'] {
	@media (--tabletOnly) {
		align-self: center;
	}
}

.device-card-item--b.device-card--service-e .device-card-item__title--tablet-only {
	@media (--tabletOnly) {
		display: none;
	}
}

.device-card-item--b.device-card--service-e .device-card-item__title--hide-tablet {
	@media (--tabletOnly) {
		display: block;
	}
}

.device-card--e.device-card--b-4 .device-card-item__text-wrap-top {
	@media (--tabletOnly) {
		width: auto;
	}
}
/* SERVICE CARD E*/
