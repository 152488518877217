/* CARD A TYPE*/
.device-card-item--a .device-card-item__img-wrap {
	@media (--tablet) {
		align-items: flex-start;
		flex: 0 1 50%;
		padding: 0 1.5rem 0 0;
		width: 50%;
	}
}

.device-card-item--a .device-card-item__img-wrap.is-img-content {
	@media (--tablet) {
		display: block;
		overflow: hidden;
	}
}

.device-card-item--a .device-card-item__text-wrap {
	display: flex;
	flex-direction: column;

	@media (--mobileOnly) {
		align-items: stretch;
		flex-basis: 100%;
	}

	@media (--tablet) {
		flex: 0 1 50%;
		padding: 0 1rem 0 0;
		width: 50%;
	}
}

.device-card-item--a .device-card-item__img-wrap + .device-card-item__text-wrap {
	@media (--tablet) {
		padding: 0 0 0 1.5rem;
	}
}

.device-card-item--a .device-card-item__text-wrap-top {
	display: flex;
	width: 100%;

	@media (--tablet) {
		flex: 1 1 auto;
	}
}

.device-card-item--a .device-card-item__text-wrap-top p:last-child {
	@media (--tablet) {
		padding-bottom: 2rem;
	}
}

.device-card-item--a .device-card-item__text-wrap-bottom {
	display: flex;
	/*flex: 1 1 50%;*/
	@media (--tablet) {
		align-items: flex-end;
		justify-content: flex-end;
		width: 100%;
	}
}
/* CARD A TYPE*/
