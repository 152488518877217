/* CARD B TYPE (3 CARDS)*/
.device-card-item--b {
	text-align: center;
}

.device-card-item--b .device-card-item__panel {
	flex-direction: column;
	flex-wrap: wrap;

	@media (--tabletOnly) {
		border-radius: 2px;
	}
}

.device-card-item--b .device-card-item__panel-wrap {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@media (--tabletOnly) {
		flex-direction: row;
	}

	@media (--desktop) {
		display: flex;
		flex-direction: column;
		webkit-box-direction: column;
	}
}

.device-card-item--b .device-card-item__img-wrap {
	margin: 0 0 2rem;
	width: 100%;
}

.device-card-item--b.col-md-4 .device-card-item__img-wrap img {
	max-width: 100%;
}

.device-card--b .row {
	@media (--desktop) {
		flex-direction: row;
	}
}

.device-card--slider.device-card--b-3 .row {
	flex-direction: row;
}

.device-card--b-3 .device-card-item--b,
.device-card--b-4 .device-card-item--b {
	@media (--tabletOnly) {
		text-align: left;
	}
}

.device-card--b-3 .device-card-item__img-wrap,
.device-card--b-4 .device-card-item__img-wrap {
	@media (--tabletOnly) {
		align-items: flex-start;
		flex: 0 1 50%;
		justify-content: center;
		margin: 0;
		max-width: 184px;
		padding: 0 1.5rem 0 0;
		width: 50%;
	}

	&.is-img-content {
		display: block;
		overflow: hidden;
	}
}

.device-card--b .device-card-item__text-wrap {
	align-items: stretch;
	display: flex;

	@media (--mobileOnly) {
		flex-basis: 100%;
	}

	@media (--tabletOnly) {
		flex-wrap: wrap;
		padding: 0 1rem 0 1.5rem;
		width: 50%;
	}

	@media (--desktop) {
		align-items: stretch;
		display: flex;
		flex-grow: 1;
	}
}

.device-card--b .device-card-item__text-wrap.is-img-content {
	display: block;

	@media (--desktop) {
		display: block;
	}
}

.device-card--b .device-card-item__text-wrap-bottom {
	display: flex;
	flex: 1 1 100%;

	@media (--tabletOnly) {
		align-items: flex-end;
	}
}

.device-card--b .device-card-item__text_larger {
	padding-bottom: 1.5rem;
}

.device-card--b .device-card-item__text-wrap-bottom > div {
	align-items: center;
	display: flex;
	flex-flow: column wrap;
	width: 100%;

	@media (--tabletOnly) {
		align-items: flex-start;
	}
}

.device-card--b-3 .service-price,
.device-card--b-4 .service-price,
.device-card--b-3 .value-molecule,
.device-card--b-4 .value-molecule {
	margin-top: auto;
	width: 100%;
}

.device-card--b-3 *[class*='btn-2'],
.device-card--b-3 *[class*='btn-2-5'],
.device-card--b-3 *[class*='btn-3'] {
	align-self: center;
	margin: 0 auto;

	@media (--tabletOnly) {
		align-self: flex-start;
		margin: 0;
	}

	@media (--desktop) {
		align-self: flex-end;
	}
}

.device-card--b-4 *[class*='btn-2'],
.device-card--b-4 *[class*='btn-3'],
.device-card--b-4 *[class*='btn-2-5'] {
	@media (--tabletOnly) {
		align-self: flex-start;
		margin: 0;
	}
}

.device-card-item__title--hide-tablet {
	display: block;

	@media (--tabletOnly) {
		display: none;
	}
}

.device-card-item__title--tablet-only {
	display: none;

	@media (--tabletOnly) {
		display: block;
	}
}

.device-card--b-3 .device-card-item__text-wrap-top,
.device-card--b-4 .device-card-item__text-wrap-top {
	@media (--tabletOnly) {
		display: flex;
		/*flex: 1 1 50%;*/
		width: 100%;
	}
}

.device-card--b-3 .device-card-item__text-wrap-top p:last-child,
.device-card--b-4 .device-card-item__text-wrap-top p:last-child {
	@media (--tabletOnly) {
		padding-bottom: 2rem;
	}
}

.device-card--b-3 .device-card-item__text-wrap-bottom div:not(.device-price):not(.service-price):not(.value-molecule):first-child > p:last-of-type,
.device-card--b-4 .device-card-item__text-wrap-bottom div:not(.device-price):not(.service-price):not(.value-molecule):first-child > p:last-of-type {
	padding-bottom: 2rem;
}

.device-card--b-3 .device-card-item__img-wrap + .device-card-item__text-wrap .device-card-item__text-wrap-bottom,
.device-card--b-4 .device-card-item__img-wrap + .device-card-item__text-wrap .device-card-item__text-wrap-bottom {
	@media (--tabletOnly) {
		align-items: flex-end;
		display: flex;
		flex: 1 1 50%;
		/*justify-content: flex-end;*/
		width: 100%;
	}
}

.device-card--b-3 .device-card-item__img-wrap + .device-card-item__text-wrap .device-card-item__text-wrap-top + .device-card-item__text-wrap-bottom,
.device-card--b-4 .device-card-item__img-wrap + .device-card-item__text-wrap .device-card-item__text-wrap-top + .device-card-item__text-wrap-bottom {
	@media (--tabletOnly) {
		align-items: flex-end;
		display: flex;
		flex: 1 1 50%;
		/*justify-content: flex-end;*/
		width: 100%;
	}
}
/* CARD B TYPE (3 CARDS)*/
