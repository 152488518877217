.frameconfig--sticky-hero #frameHeader {
	@media (--xs-size) {
		position: sticky;
		top: 0;
		z-index: 1;
	}
}

.hero-title--sticky {
	@media (--xs-size) {
		position: sticky!important;/* stylelint-disable-line */
		top: 0;
		z-index: -1;
	}
}

.module-wrapper {
	background: var(--background-color);
	margin-bottom: -60px; /* a z-index miatt a sticky menü rácsúszik a footerre, így elcsaljuk a wrapper alja és a footer teteje közötti sávot az alsó paddinggal, mínusz margóval */
	padding-bottom: 60px;
	padding-top: 24px;

	@media (--xs-size) {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		margin-top: -36px;
	}
}

.module-wrapper .module-container,
.frameconfig-hero-title--full-width .module-container {
	margin-left: auto;
	margin-right: auto;
	max-width: 1152px;
}

.rwform.frameconfig-hero-title--full-width .noAside {
	max-width: 100%!important;
}

/* a z-index miatt a sticky menü rácsúszik a footerre, így elcsaljuk a footer tetejét */
.frameconfig--sticky-hero footer#frameFooter {
	position: relative;
	z-index: 2;
}

.hero-title {
	background-color: var(--primary-color);
	background-position: top right;
	background-repeat: no-repeat;
	margin-top: -108px;
	max-width: 100%!important;/* stylelint-disable-line */
	min-height: 252px;
	position: relative;
	width: 100%;

	@media (--l-size) {
		margin-top: -152px;
		min-height: 316px;
	}

	@media (--xl-size) {
		margin-top: -152px;
		min-height: 316px;
	}

	&.hero-title--pattern-1 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-1-mage.png');
	}

	&.hero-title--pattern-2 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-2-mage.png');
	}

	&.hero-title--pattern-3 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-3-mage.png');
	}

	&.hero-title--pattern-4 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-4-mage.png');
	}

	&.hero-title--pattern-5 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-5-mage.png');
	}

	&.hero-title--pattern-6 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-6-mage.png');
	}

	&__text-container {
		padding-bottom: 19px;
		padding-top: 97px;

		@media (--l-size) {
			padding-top: 160px;
		}

		@media (--xl-size) {
			padding-top: 160px;
		}

		&-headline {
			color: #fff;
			font-family: 'TeleGroteskNext-Ultra';
			font-size: 40px;
			line-height: 44px;
			margin: 0 auto 19px;
			width: auto;

			@media (--xs-size) {
				margin: 0 12px 19px;
				max-width: 100%;
			}
			@media (--s-size) {
				margin: 0 12px 19px;
				max-width: 100%;
			}
			@media (--m-size) {
				margin: 0 auto 19px;
				max-width: 1108px;
				padding: 0 32px;
			}
			@media (--l-size) {
				margin: 0 auto 19px;
				max-width: 1108px;
				padding: 0 32px;
			}
			@media (--xl-size) {
				margin: 0 auto 19px;
				max-width: 1108px;
				padding: 0 32px;
			}
		}

		&-subline {
			color: #fff;
			font-family: 'TeleGroteskNext-Regular';
			font-size: 20px;
			line-height: 24px;

			@media (--xs-size) {
				margin: 0 12px 19px;
				max-width: 100%;
			}
			@media (--s-size) {
				margin: 0 12px 19px;
				max-width: 100%;
			}
			@media (--m-size) {
				margin: 0 auto 19px;
				max-width: 1108px;
				padding: 0 32px;
			}
			@media (--l-size) {
				margin: 0 auto 19px;
				max-width: 1108px;
				padding: 0 32px;
			}
			@media (--xl-size) {
				margin: 0 auto 19px;
				max-width: 1108px;
				padding: 0 32px;
			}
		}
	}
	&::after {
		background: var(--background-color);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		bottom: 0;
		content: '';
		height: 12px;
		left: 0;
		position: absolute;
		width: 100%;
	}
}

.frameconfig-noframe .hero-title {
	margin-top: 0;

	@media (--l-size) {
		margin-top: 0;
	}

	@media (--xl-size) {
		margin-top: 0;
	}
}

.hero-title--gray {
	background-color: var(--background-color);

	h1,
	p {
		color: var(--secondary-color);
	}

	&.hero-title--pattern-1 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-1-gray.png');
	}

	&.hero-title--pattern-2 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-2-gray.png');
	}

	&.hero-title--pattern-3 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-3-gray.png');
	}

	&.hero-title--pattern-4 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-4-gray.png');
	}

	&.hero-title--pattern-5 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-5-gray.png');
	}

	&.hero-title--pattern-6 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-6-gray.png');
	}
}

.hero-title--blue {
	background-color: var(--secondary-color);

	&.hero-title--pattern-1 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-1-blue.png');
	}

	&.hero-title--pattern-2 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-2-blue.png');
	}

	&.hero-title--pattern-3 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-3-blue.png');
	}

	&.hero-title--pattern-4 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-4-blue.png');
	}

	&.hero-title--pattern-5 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-5-blue.png');
	}

	&.hero-title--pattern-6 {
		background-image: url('/static/c3/g/modules/m32-hero-title/pattern-6-blue.png');
	}
}
