@import '_device-price-fixed.pcss';
@import '_value-molecule-fixed.pcss';
@import '_m13p-loader.pcss';

/* LAYOUT*/
/* BACKGROUND*/
/* CONTROLS (ARROWS)*/
/* CONTROLS (PAGER)*/
/* TEXT ELEMENTS*/

/* LAYOUT*/
.m13p-inline-promo {
	display: block;
	position: relative;
	width: 100%;
}

.m13p-inline-promo-wrap {
	display: block;
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.m13p-inline-promo__content {
	display: flex;
	min-height: 240px;
	position: relative;
	width: 100%;

	@media (--desktop) {
		min-height: 256px;
	}

	&::before {
		@apply --aspect-8-9;

		@media (--desktop) {
			@apply --aspect-3-1;
		}
	}
}

.noAside .m13p-inline-promo__content {
	@media (--desktop-xl) {
		min-height: 384px;
	}
}

.m13p-inline-promo-slide {
	align-content: stretch;
	align-items: stretch;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	flex-wrap: nowrap;
	justify-content: flex-start;
	position: relative;
	width: 100%;

	@media (--desktop) {
		flex-direction: row;
		height: auto;
		min-height: 256px;
	}

	@media (--desktop-xl) {
		height: 100%;
	}
}

.noAside .m13p-inline-promo-slide {
	@media (--desktop-xl) {
		min-height: 384px;
	}
}

.m13p-inline-promo-slide__text-wrap {
	align-items: center;
	align-self: stretch;
	display: flex;
	flex: 1 1 auto;
	order: 1;
	padding: 2rem;
	position: relative;
	width: 100%;
	z-index: 1;

	@media (--desktop) {
		flex: 0 1 50%;
		order: 1;
		padding: 2rem 3.333rem 2rem 2rem;
		width: 50%;
	}
}

.m13p-inline-promo-slide__text-wrap:first-child,
.m13p-inline-promo-slide__background + .m13p-inline-promo-slide__text-wrap {
	@media (--desktop) {
		padding: 2rem 2.5rem;
	}
}

.m13p-inline-promo-slide__image-wrap {
	align-content: center;
	align-items: center;
	display: flex;
	flex: 10 1 auto;
	justify-content: center;
	order: 2;
	position: relative;
	width: 100%;
	z-index: 1;

	@media (--desktop) {
		flex: 0 1 50%;
		order: 1;
		width: 50%;
	}
}
.m13p-inline-promo-slide__image-wrap > div {
	align-self: stretch;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
}
.m13p-inline-promo-slide__image-wrap object {
	align-self: stretch;
	display: flex;
	flex: 1 1 auto;
}
.m13p-inline-promo-slide__image-wrap img {
	height: auto;
	max-width: 100%;
	position: absolute;
}

.m13p-inline-promo-slide__image-wrap .flex-reset {
	flex: initial;
}

/* LAYOUT*/

/* BACKGROUND*/
.m13p-inline-promo-slide object {
	display: block;
	height: 100%;
	margin: 0 auto;
	width: 100%;
}

.m13p-inline-promo-slide__background {
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 0;
}
/* BACKGROUND*/

/* CONTROLS*/
.m13p-inline-promo-controls-prev {
	background-color: rgba(51, 51, 51, 0.2);
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
	display: block;
	height: 60px;
	left: 0;
	position: absolute;
	text-indent: -9999px;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	z-index: 5;
}

.m13p-inline-promo-controls-prev::after {
	border: solid 3px #fff;
	border-right: none;
	border-top: none;
	content: '';
	display: block;
	height: 18px;
	left: 50%;
	margin: -9px 0 0 -4px;
	position: absolute;
	top: 50%;
	transform: rotate(45deg);
	width: 18px;
}

.m13p-inline-promo-controls-next {
	background-color: rgba(51, 51, 51, 0.2);
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
	display: block;
	height: 60px;
	position: absolute;
	right: 0;
	text-indent: -9999px;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	z-index: 5;
}

.m13p-inline-promo-controls-next::after {
	border: solid 3px #fff;
	border-bottom: none;
	border-left: none;
	content: '';
	display: block;
	height: 18px;
	margin: -9px -4px 0 0;
	position: absolute;
	right: 50%;
	top: 50%;
	transform: rotate(45deg);
	width: 18px;
}
/* CONTROLS*/

/* CONTROLS (PAGER)*/
.m13p-inline-promo-pager {
	color: var(--secondary-color);
	display: block;
	font-family: var(--font-reg);
	font-size: 15px;
	line-height: 18px;
	padding: 0.7rem 2rem 1rem;
}

.m13p-inline-promo-pager--desktop {
	display: none;
	margin: -4px 0 0 0;
	position: relative;
	z-index: 5;

	@media (--desktop) {
		display: block;
	}
}

.m13p-inline-promo-pager--mobile {
	display: block;

	@media (--desktop) {
		display: none;
	}
}

.m13p-inline-promo-pager__nav {
	display: table;
	table-layout: fixed;
	width: 100%;
}
.m13p-inline-promo-pager__nav a {
	cursor: auto;
	display: table-cell;
	overflow: hidden;
	padding: 1rem 0 0 0;
	position: relative;
	text-decoration: none;
	width: 2%;
}

.m13p-inline-promo-pager__nav a:first-child .m13p-inline-promo-pager__progress {
	margin-left: 0;
}

.m13p-inline-promo-pager__progress {
	background-color: var(--default-text);
	display: block;
	height: 2px;
	left: 0;
	margin-left: 2px;
	padding-right: 100%;
	position: absolute;
	top: 6px;
	transition: height 0.2s;
	width: 100%;
}

.m13p-inline-promo-pager__item:hover .m13p-inline-promo-pager__progress {
	height: 6px;
}

.m13p-inline-promo-pager__nav a:active .m13p-inline-promo-pager__progress {
	background-color: var(--default-text);
}

.m13p-inline-promo-pager__item--active .m13p-inline-promo-pager__progress {
	background-color: var(--primary-color);
	height: 12px;
	top: 0;

	&::before {
		background-color: var(--primary-color);
		content: '';
		display: block;
		height: 9px;
	}
}

.m13p-inline-promo-pager__item--active:hover .m13p-inline-promo-pager__progress {
	height: 12px;
}

.selected .m13p-inline-promo-pager {
	color: var(--primary-color);
	font-family: var(--font-bold);
}

.m13p-inline-promo-pager-select {
	background: #fff;
	border: 2px solid var(--default-text);
	border-radius: 4px;
	color: var(--primary-color);
	display: block;
	font-family: var(--font-bold);
	font-size: 13px;
	line-height: 16px;
	margin: 2px;
	outline: none;
	position: relative;
	white-space: nowrap;

	&::before {
		border: 2px solid var(--default-text);
		border-left: 0;
		border-top: 0;
		content: '';
		display: block;
		height: 10px;
		position: absolute;
		right: 10px;
		top: calc(50% - 8px);
		transform: rotate(45deg);
		width: 10px;
	}

	&::after {
		background: var(--default-text);
		content: '';
		display: block;
		height: 22px;
		margin: -11px 0 0 0;
		position: absolute;
		right: 30px;
		top: 50%;
		width: 1px;
	}
}

.m13p-inline-promo-pager-select select {
	appearance: none;
	background: none;
	border: none;
	font-family: var(--font-ult);
	font-size: 1rem;
	height: 35px;
	opacity: 0.99;
	outline: none;
	padding: 0 0 0 10px;
	width: 100%;

	option {
		color: var(--secondary-color);
		font-family: var(--font-reg);

		&[selected] {
			color: var(--primary-color);
		}
	}
}

.m13p-inline-promo-pager__text {
	color: var(--default-text);
	display: block;
	font-family: var(--font-reg);
	font-size: 14px;
	line-height: 16px;
	padding: 12px 10px 10px;
}

.m13p-inline-promo-pager__item--active .m13p-inline-promo-pager__text {
	color: var(--primary-color);
	font-family: var(--font-bold);
}
/* CONTROLS (PAGER)*/

/* TEXT ELEMENTS*/
.m13p-inline-promo__title-desc {
	color: var(--secondary-color);
	padding: 0 0 2rem;
}

.m13p-inline-promo__title {
	color: var(--secondary-color);
	padding: 0 0 2rem;
	text-transform: uppercase;
}

.m13p-inline-promo-text-1 {
	color: var(--default-text);
	padding: 0 0 2rem;
}

.m13p-inline-promo-text-2 {
	color: var(--default-text);
	padding: 0 0 2rem;
}

.m13p-inline-promo .device-price {
	margin-bottom: 2rem;
}

.m13p-inline-promo-button {
	margin: 0 2rem 0 0;
}
/* TEXT ELEMENTS*/

/* POSITIONS*/
.m13p-inline-promo-slide__image-wrap[data-bg-position='left top'] img {
	left: 0;
	top: 0;
}

.m13p-inline-promo-slide__image-wrap[data-bg-position='left center'] img {
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.m13p-inline-promo-slide__image-wrap[data-bg-position='left bottom'] img {
	bottom: 0;
	left: 0;
}

.m13p-inline-promo-slide__image-wrap[data-bg-position='right top'] img {
	right: 0;
	top: 0;
}

.m13p-inline-promo-slide__image-wrap[data-bg-position='right center'] img {
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.m13p-inline-promo-slide__image-wrap[data-bg-position='right bottom'] img {
	bottom: 0;
	right: 0;
}

.m13p-inline-promo-slide__image-wrap[data-bg-position='center top'] img {
	left: 50%;
	top: 0;
	transform: translateX(-50%);
}

.m13p-inline-promo-slide__image-wrap[data-bg-position='center center'] img {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.m13p-inline-promo-slide__image-wrap[data-bg-position='center bottom'] img {
	bottom: 0;
	transform: translateX(-50%);
}
