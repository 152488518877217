.roaming-app__dropdown {
	color: var(--secondary-color);
	font-family: var(--font-reg);
	margin: 20px auto;
	max-width: 1150px;
	width: 100%;
}

.s-hidden {
	padding: 12px 36px 12px 12px;
	visibility: hidden;
}

.select {
	cursor: pointer;
	display: inline-block;
	/* display: inline-flex; */
	height: 44px;
	margin: 0;
	position: relative;
	top: -2px;

	@media (--xs-size) {
		width: 100%;
		/* margin-bottom: 24px; */
	}
}
.styledSelect {
	background: #fff;
	border-radius: 4px;
	bottom: 0;
	box-shadow: inset 0 -1px 0 var(--default-text);
	box-sizing: border-box;
	color: var(--modul-border-hover);
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
	height: 44px;
	left: 0;
	line-height: 20px;
	padding: 12px 36px 12px 12px;
	position: absolute;
	right: 0;
	top: 0;

	@media (--xs-size) {
		overflow: hidden;
		white-space: nowrap;
	}

	&.carrier-select {
		top: 48px;
	}
}

.styledSelect.selected {
	color: var(--secondary-color);
	font-weight: bold;
}

.styledSelect::before {
	@media (--xs-size) {
		background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%); /* stylelint-disable-line */
		background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%); /* stylelint-disable-line */
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%); /* stylelint-disable-line */
		background: #fff; /* stylelint-disable-line */
		border-radius: 4px;
		content: '';
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fff",endColorstr="#fff",GradientType=1);
		height: 'calc(100% - 1px)';
		position: absolute;
		right: 0;
		top: 0;
		width: 70px;
	}
}

.styledSelect::after {
	background: url('/static/c3/g/roaming-app-dropdown.svg') no-repeat;
	content: '';
	height: 6px;
	position: absolute;
	right: 15px;
	top: 20px;
	width: 8px;
}

.styledSelect:active,
.styledSelect.active {
	background-color: #fff;
}

.options {
	display: block;
	list-style-type: none;
	margin: 0;
	padding-left: 0;
}

.options li {
	color: var(--default-text);
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
	line-height: 20px;
	padding: 6px 12px;
}

.options li:hover {
	background: var(--background-color);
}
