.tabbed-container-extended {
	.tab-list-mobile.tab-list-mobile--hasicon .tab-list .tab-list-item {
		.tab-list-item__icon {
			left: 1.41667rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 2.33333rem;
		}
		.tab-list-item__title {
			padding-left: 5.5rem;
			padding-right: 6.1rem;
		}

		.tab-list-item__icon--active {
			display: none;
		}
	}

	.tab-list-mobile.tab-list-mobile--hasicon .tab-list .tab-list-item.tab-list-item--active {
		.tab-list-item__icon {
			display: none;
		}
		.tab-list-item__icon--active {
			display: block;
		}
	}
} /* tabbed-container-extended */
