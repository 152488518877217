.legal-text {
	padding: 0 2rem;

	&__title {
		/* color: var(--primary-color); */
		color: var(--secondary-color);
		padding-bottom: 2rem;
	}

	&__text {
		/* color: var(--secondary-color); */
		color: var(--default-text);
		padding-bottom: 1.5rem;
	}

	*:last-child:not(.module-link):not(.module-link-single) {
		padding-bottom: 0;
	}
}
