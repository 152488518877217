.roaming-app__content {
	margin: 0 24px 64px;

	@media (--xl-size) {
		margin: 0 auto;
		max-width: 1152px;
		width: 100%;
	}
}

.roaming-app__select-country {
	margin-bottom: 32px;
	margin-top: 24px;

	@media (--xl-size) {
		margin-bottom: 27px;
		margin-top: 27px;
	}
}

.roaming-app__select-carrier {
	margin-bottom: 32px;
	margin-top: 24px;

	@media (--xl-size) {
		margin-bottom: 81px;
		margin-top: 27px;
	}

	.scroll-wrapper {
		margin-top: 52px;
	}
}

.roaming-app__dropdown-text {
	display: block;
	font-size: 21px;
	font-style: normal;
	font-weight: normal;
	line-height: 24px;
	margin-bottom: 12px;
	margin-right: 0;
	margin-top: 0;

	@media (--xl-size) {
		display: inline-block;
		margin-bottom: 36px;
		margin-right: 12px;
	}
}

.roaming-app__dropdown label {
	margin-right: 12px;
}

.roaming-app__dropdown-text--bold-text {
	font-size: 24px;
	font-style: normal;
	font-weight: bold;
	line-height: 28px;
	margin-bottom: 12px;
}

.roaming-app__dropdown-text--small-text {
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
	line-height: 20px;
	margin-bottom: 42px;

	@media (--xs-size) {
		font-size: 20px;
		font-style: normal;
		font-weight: normal;
		line-height: 24px;
	}
}

.button--listing {
	background: var(--primary-color);
	border: none;
	border-radius: 4px;
	box-shadow: 0 4px 12px rgba(226, 0, 116, 0.24);
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	line-height: 20px;
	margin-left: 12px;
	padding: 12px 20px;
	text-transform: uppercase;

	@media (--xs-size) {
		margin-left: 0;
		margin-top: 24px;
		width: 100%;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}

	&.button--choose-country {
		font-size: 15px;
		font-weight: bold;
		line-height: 17px;
		margin-bottom: 28px;
		margin-left: 0;
		margin-top: 64px;

		@media (--l-size) {
			margin-bottom: 64px;
		}
	}
}

.roaming-app__link {
	border-bottom: 1px solid var(--secondary-color);
	color: var(--secondary-color);
	display: inline-block;
	font-size: 20px;
	font-style: normal;
	font-weight: normal;
	line-height: 24px;
	text-decoration: none;
}
