.notification-bar {
	background-color: #fff;
	border-left-style: solid;
	border-left-width: 6px;
	border-radius: 8px;
	margin: 0 0.5rem;
	padding: 2rem 3rem 2rem 5rem;
	position: relative;

	.parent-container & {
		border-bottom: 1px solid #d6d6d6;
		border-right: 1px solid #d6d6d6;
		border-top: 1px solid #d6d6d6;
		margin-left: 0;
		margin-right: 0;
	}

	&--warning::before,
	&--info::before {
		background-color: transparent;
		background-position: 0 0;
		background-repeat: no-repeat;
		content: '';
		height: 36px;
		left: 12px;
		position: absolute;
		top: 12px;
		width: 36px;
	}

	&--warning {
		border-left-color: #ffd400;

		&::before {
			background-image: url('/static/c3/g/modules/notification-bar/icon-warning-yellow.svg');
		}
	}

	&--info {
		border-left-color: #00a1de;

		&::before {
			background-image: url('/static/c3/g/modules/notification-bar/icon-info.svg');
		}
	}

	&--success::before,
	&--error::before {
		font-family: var(--font-icon-s);
		font-size: 2.8rem;
		left: 13px;
		position: absolute;
		top: 14px;
	}

	&--success {
		border-left-color: #59b300;

		&::before {
			color: #59b300;
			content: 'v';
		}
	}

	&--error {
		border-left-color: #e3000f;

		&::before {
			color: #e3000f;
			content: 'x';
		}
	}

	&__title {
		color: var(--secondary-color);
		padding-bottom: 2rem;
	}

	&__description {
		color: var(--default-text);
		padding-bottom: 1.5rem;
	}

	&__link {
		color: var(--secondary-color);
		display: table;
		margin-right: 1.75rem;
		width: auto;

		@media (--tablet) {
			display: inline;
		}
	}

	&__close {
		border-radius: 2px;
		height: 24px;
		padding: 0.3rem;
		position: absolute;
		right: 15px;
		text-decoration: none;
		text-indent: -9999px;
		top: 15px;
		width: 24px;

		&::after {
			content: 'X';
			display: inline-block;
			font-family: var(--font-icon-o);
			font-size: 1.5rem;
			height: auto;
			left: 4px;
			position: absolute;
			text-indent: 0;
			top: 3px;
			width: auto;
		}
		&:hover {
			background-color: #eaeaea;
			color: #333;
		}
		&:active {
			background-color: #d6d6d6;
			color: #333;
		}
		&:visited {
			color: #333;
		}
	}

	.btn-2-5,
	.btn-3,
	.btn-3-5 {
		margin-bottom: 1.5rem;
		margin-right: 1.75rem;

		@media (--tablet) {
			margin-bottom: 0;
		}
	}

	.btn-brd-grey {
		border-color: var(--secondary-color);
		color: var(--secondary-color);
		&:hover {
			background: var(--modul-bg-hover);
		}
	}
}

.reset-padding .notification-bar h3 {
	padding-bottom: 0;
}
