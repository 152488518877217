.download-link {
	color: var(--secondary-color);
	display: block;
	padding: 6px 2rem 2px;

	.parent-container & {
		padding-left: 0;
		padding-right: 0;
	}

	@media (--desktop-xl) {
		padding-bottom: 0.416667rem;
		padding-top: 0.666667rem;
	}

	&.smallsize {
		padding-bottom: 0.083333rem;
		padding-top: 0.416667rem;

		@media (--desktop-xl) {
			padding-bottom: 0.166667rem;
			padding-top: 0.5rem;
		}
	}

	&-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	&-item {
		padding: 0 0 3rem 3rem;
		position: relative;

		@media (--desktop-xl) {
			padding-left: 3.66667rem;
		}

		&:last-child {
			padding-bottom: 0;
		}

		&::before {
			background: transparent url('/static/c3/g/modules/download-link/icon-download-circular-antracit.svg') no-repeat 0 0;
			background-size: contain;
			content: '';
			display: inline-block;
			height: 2rem;
			left: 0;
			position: absolute;
			top: -1px;
			width: 2rem;

			@media (--desktop-xl) {
				height: 2.666rem;
				left: 0;
				top: -3px;
				width: 2.666rem;
			}
		}

		.smallsize & {
			padding: 0 0 2rem 2.5rem;

			@media (--desktop-xl) {
				padding-left: 3rem;
			}

			&:last-child {
				padding-bottom: 0;
			}

			&::before {
				height: 1.5rem;
				top: 0;
				width: 1.5rem;

				@media (--desktop-xl) {
					height: 2rem;
					top: -1px;
					width: 2rem;
				}
			}
		}

		&--magenta {
			color: var(--primary-color);

			&::before {
				background-image: url('/static/c3/g/modules/download-link/icon-download-circular-magenta.svg');
			}
		}

		&--white {
			color: #fff;

			&::before {
				background-image: url('/static/c3/g/modules/download-link/icon-download-circular-white.svg');
			}
		}

		&__link {
			display: inline;
			margin-right: 0.5rem;
			position: relative;

			&.module-link:active {
				background-color: transparent;
			}
		}

		&__filetype {
			text-transform: uppercase;
			white-space: nowrap;
			width: auto;
		}
	}
}
