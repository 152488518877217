.flowtext-content {
	margin: 0 2rem;

	.parent-container & {
		margin-left: 0;
		margin-right: 0;
	}

	> *:not(.flowtext_content__button):first-child {
		padding-top: 0;
	}

	> *:first-child {
		margin-top: 0;
	}
}

.flowtext-content__title {
	color: var(--secondary-color);
	padding-top: 4rem;
}

.flowtext-content__title--center {
	text-align: center;
}

.flowtext-content__subtitle {
	color: var(--secondary-color);
	padding-top: 2.5rem;
	text-transform: none;
}

.flowtext-content__subtitle--center {
	text-align: center;
}

.flowtext-content__sub-subtitle {
	color: var(--secondary-color);
	padding-top: 2.5rem;
	text-transform: none;
}

.flowtext-content__sub-subtitle--center {
	text-align: center;
}

.flowtext-content__text {
	color: var(--default-text);
	padding-top: 2rem;
}

.flowtext_content__button {
	margin-top: 2rem;
}

.interactive-content + .flowtext_content__button {
	margin-top: 0;
}

.flowtext-content .list-bullet-points,
.flowtext-content .list-numbered-points {
	padding-bottom: 0;
	padding-top: 2rem;
}

.flowtext-content .list-numbered-points {
	counter-reset: szamozas;
	display: block;
	list-style-type: none;
	margin-bottom: -0.333333rem;
	padding-bottom: 2rem;
}

.flowtext-content .list-numbered-points li {
	color: var(--default-text);
	counter-increment: szamozas;
	padding: 0 0 2rem 1.5em;
	position: relative;

	&::before {
		color: var(--secondary-color);
		content: counter(szamozas) '.';
		display: block;
		font-family: var(--font-bold);
		left: 0;
		position: absolute;
		top: 0;
	}
}

.flowtext-content .list-numbered-points li ol {
	counter-reset: subszamozas;
	list-style-type: none;
	padding: 2rem 0 1rem 0;
}

.flowtext-content .list-numbered-points li ol li {
	counter-increment: subszamozas;
	list-style-type: none;
	padding-bottom: 1.33334rem;
	padding-left: 5rem;

	&::before {
		color: var(--secondary-color);
		content: counter(szamozas) '.' counter(subszamozas) '. ';
		font-family: var(--font-bold);
	}
}
