.module-container .device-price {
	&__description {
		color: var(--secondary-color);
		padding-bottom: 1.41667rem;
	}

	&__old-price {
		color: var(--secondary-color);
		padding-bottom: 0.833333rem;
	}

	&__price {
		color: var(--primary-color);
		padding-bottom: 0.666667rem;
	}

	&__netto-brutto-text {
		color: var(--secondary-color);
		padding-bottom: 0.75rem;
	}

	&__additional-text {
		color: var(--secondary-color);
		padding-bottom: 0.666667rem;

		&--marked {
			color: var(--primary-color);
		}
	}

	> *:last-child {
		padding-bottom: 0;
	}
}
