/* SERVICE CARD F*/
.device-card--service-f .device-card-item__panel-wrap {
	display: block;
}

.device-card--service-f .device-card-item__text-wrap {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: 0;
}

.device-card--service-f .device-card-item__img-wrap {
	display: flex;
	flex: 0 1 100%;
	max-width: 248px;
	padding: 0 1.5rem 0 0;
}

.device-card--service-f .device-card-item__img-wrap.is-img-content {
	display: block;
	overflow: hidden;
}

.device-card--service-f .device-card-item__img-wrap + .device-card-item__text-wrap {
	padding: 0 0 0 1.5rem;
}

.device-card--service-f .device-card-item__text-wrap-top {
	display: flex;
}

.device-card--service-f .device-card-item__text-wrap-bottom {
	align-items: flex-end;
	display: flex;
}

.device-card--service-f .device-card-item__text-wrap-bottom > div {
	display: flex;
	flex-direction: column;

	@media (--desktop-960) {
		flex-direction: row;
		font-size: 0;
	}
}

.device-card--service-f .device-card-item__text-wrap-bottom > div > div {
	width: 100%;

	@media (--desktop-960) {
		width: 33%;
	}
}

.device-card--service-f .device-card-item__title--mobile {
	display: block;

	@media (--desktop-960) {
		display: none;
	}
}

.device-card--service-f .device-card-item__title--tablet {
	display: none;

	@media (--desktop-960) {
		display: block;
	}
}

.device-card--service-f .device-card-item__text-wrap-bottom div:not(.device-price):not(.service-price):not(.value-molecule):first-child > p:last-of-type {
	padding-bottom: 2rem;
}

/* SERVICE CARD F*/
