/* SERVICE CARD C*/
.device-card--service-c .device-card-item {
	text-align: center;

	@media (--desktop) {
		text-align: left;
	}
}

.device-card--service-c .device-card-item .device-card-item__panel {
	flex-direction: column;
	flex-wrap: wrap;

	@media (--desktop) {
		flex-direction: row;
	}
}

.device-card--service-c .device-card-item .device-card-item__panel-wrap {
	flex-direction: column;

	@media (--desktop) {
		flex-direction: row;
	}
}

.device-card--service-c .device-card-item__img-wrap {
	margin: 0 0 2rem;
	width: 100%;

	@media (--desktop) {
		display: flex;
		flex: 0 1 100%;
		margin: 0;
		max-width: 248px;
		padding: 0 1.5rem 0 0;
		width: auto;
	}
}

.device-card--service-c .device-card-item__img-wrap.is-img-content {
	@media (--desktop) {
		display: block;
		overflow: hidden;
	}
}

.device-card--service-c .device-card-item__img-wrap img {
	max-width: 184px;

	@media (--desktop) {
		align-self: center;
		max-width: 230px;
	}
}

.device-card--service-c .device-card-item__text-wrap {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;

	@media (--tabletOnly) {
		padding: 0 1rem 0 1.5rem;
		width: 100%;
	}

	@media (--desktop) {
		flex: 0 1 100%;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 0 0 1.5rem;
	}
}

.device-card--service-c .device-card-item__text-wrap-top {
	@media (--tabletOnly) {
		display: flex;
		width: 100%;
	}

	@media (--desktop) {
		padding-bottom: 2rem;
	}
}

.device-card--service-c .device-card-item__text-wrap-bottom {
	width: 100%;

	@media (--desktop) {
		display: flex;
		width: auto;
	}
}

.device-card--service-c .device-card-item__text-wrap-bottom > div {
	@media (--desktop) {
		align-items: stretch;
		display: flex;
		flex-direction: row;
		font-size: 0;
		width: 100%;
	}
}

.device-card--service-c .service-price {
	margin-top: auto;
	width: 100%;

	@media (--desktop) {
		margin-top: 0;
	}
}

.device-card--service-c *[class*='btn-2'],
.device-card--service-c *[class*='btn-2-5'],
.device-card--service-c *[class*='btn-3'] {
	align-self: center;
	margin: 0 auto;

	@media (--tabletOnly) {
		align-self: flex-start;
		margin: 0;
	}
}

.device-card--service-c .device-card-item__text-wrap-bottom > div > div {
	@media (--desktop) {
		width: 33%;
	}
}

.device-card--service-c .device-card-item__text-wrap-bottom > div > div:nth-child(1) {
	@media (--desktop) {
		padding-right: 1.5rem;
	}
}

.device-card--service-c .device-card-item__text-wrap-bottom > div > div:nth-child(2) {
	@media (--desktop) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

.device-card--service-c .device-card-item__text-wrap-bottom > div > div:nth-child(3) {
	@media (--desktop) {
		padding-left: 1.5rem;
	}
}

.device-card--service-c .device-card-item__title--mobile {
	display: block;

	@media (--desktop) {
		display: none;
	}
}

.device-card--service-c .device-card-item__title--tablet {
	display: none;

	@media (--desktop) {
		display: block;
	}
}

.device-card--service-c .device-card-item__text-wrap-bottom div:not(.device-price):not(.service-price):not(.value-molecule):first-child > p:last-of-type {
	padding-bottom: 2rem;
}

.device-card--service-c .device-card-item__text_larger,
.device-card--service-c .device-card-item__text {
	@media (--desktop) {
		padding-right: 1rem;
	}
}
/* SERVICE CARD C*/
