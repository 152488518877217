.parent-container .connection > .row {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}

.connection .row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media (min-width: 769px) {
		flex-wrap: nowrap;
	}
}

.connection__card {
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	padding: 0 0.5rem 1rem;
	transition: margin 0.3s ease;
}

.swiper-container-horizontal .connection__card.col-sm-4 {
	margin-left: 8% !important; /* stylelint-disable-line */
	margin-right: 8% !important; /* stylelint-disable-line */
}

.connection__title-main {
	color: var(--secondary-color);
	padding: 0 1.667rem 2.5rem;
}

.connection__panel {
	text-align: center;
	transition: all 0.3s ease;

	&::after {
		clear: both;
		content: '';
		display: table;
	}
}

.connection__panel--white {
	background: rgba(255, 255, 255, 1);
	border-radius: 8px;
	box-shadow: 0 1px 2px var(--box-shadow);
	flex: 1 1 auto;

	&:hover {
		box-shadow: 0 10px 10px var(--box-shadow-hover);
	}
}

.connection__card--tablet-12 .connection__panel {
	@media (--tabletOnly) {
		display: table;
		text-align: left;
		vertical-align: top;
	}
}

.connection__card--tablet-12 .connection__text-wrap {
	@media (--tabletOnly) {
		display: table-cell;
		padding: 2rem 1.5rem;
		vertical-align: top;
	}
}

.connection__img-wrap,
.connection__icon-wrap {
	position: relative;

	&::before {
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.connection__card--tablet-12 .connection__img-wrap,
.connection__card--tablet-12 .connection__icon-wrap {
	@media (--tabletOnly) {
		display: table-cell;
		padding: 2rem 0 2rem 1.5rem;
		vertical-align: top;
		width: 100px;
	}
}
.connection__card--tablet-12 .connection__img-wrap {
	@media (--tabletOnly) {
		width: 33%;
	}
}
.connection__card--tablet-12 .connection__img-wrap object {
	width: 100%;
}

.connection__img-wrap object {
	display: block;
	height: 100%;
	min-width: 175px;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.connection__img-wrap.aspect-ratio-16-9::before {
	@apply --aspect-16-9;
	position: relative;
}

.connection__img-wrap.aspect-ratio-4-3::before {
	@apply --aspect-4-3;
	position: relative;
}

.connection__img-wrap.aspect-ratio-16-9 object,
.connection__img-wrap.aspect-ratio-4-3 object {
	display: block;
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
}

.connection__img-wrap object svg {
	background-position: center;
	background-size: contain;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.connection__card--tablet-12 .connection__img-wrap img {
	@media (--tabletOnly) {
		max-width: 175px;
	}
}

.connection__icon-wrap {
	display: block;
	padding: 2rem 0 0 0;
}

.connection__text-wrap {
	padding: 1.5rem 1.5rem 2rem;
}

.connection__text-wrap--align-left {
	text-align: left;
}

.connection__text-wrap--align-left .module-link-single {
	padding-left: 0;
	width: 100%;
}

.connection__title {
	/* color: var(--primary-color); */
	color: var(--secondary-color);
	padding: 0 0 1.5rem;

	&:last-child {
		padding: 0;
	}
}

.connection__text {
	/* color: var(--secondary-color); */
	color: var(--default-text);
	padding: 0 0 1.5rem;

	&:last-child {
		padding: 0;
	}
}

.connection__text a {
	/* color: var(--secondary-color); */
	color: var(--default-text);

	&:hover {
		text-decoration: none;
	}
}

.connection__card .module-link-single,
.tab-list-content .connection__card .module-link-single {
	display: table;
	margin-left: auto;
	margin-right: auto;

	@media (--tabletOnly) {
		margin-left: -0.3333rem;
		margin-right: -0.3333rem;
	}
}

.connection__card .module-link-single + .module-link-single,
.tab-list-content .connection__card .module-link-single + .module-link-single {
	margin-top: 1rem;
}

.connection__card.pointer-event-trigger:hover .module-link-single {
	text-decoration: none;
}

.connection__card.pointer-event-trigger .module-link-single:active {
	background: transparent;
}

/* CARD SCROLLER*/
.connection--slider .row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.connection--slider .connection__card {
	flex-shrink: 0;

	@media (--desktop) {
		flex-shrink: 1;
	}
}

.connection-wrap {
	position: relative;
}

.connection--slider-enabled .connection-wrap {
	padding: 0 40px;
}

.connection__content {
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.connection--slider-enabled .connection__content {
	padding-bottom: 20px;
}

.connection-controls-prev {
	background-color: rgba(51, 51, 51, 0.2);
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
	display: none;
	height: 60px;
	left: 0;
	margin: -50px 0 0 0;
	position: absolute;
	text-indent: -9999px;
	top: 50%;
	width: 30px;
	z-index: 5;

	&:hover {
		background-color: rgba(51, 51, 51, 0.3);
	}

	&:active {
		background-color: rgba(51, 51, 51, 0.4);
	}
}

.connection--slider-enabled .connection-controls-prev {
	display: block;
}

.connection-controls-prev::after {
	border: solid 3px #fff;
	border-right: none;
	border-top: none;
	content: '';
	display: block;
	height: 18px;
	left: 50%;
	margin: -9px 0 0 -4px;
	position: absolute;
	top: 50%;
	transform: rotate(45deg);
	width: 18px;
}

.connection-controls-next {
	/* background-color: rgba(51, 51, 51, 0.2); */
	background: var(--modul-border);
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
	display: none;
	height: 60px;
	margin: -50px 0 0 0;
	position: absolute;
	right: 0;
	text-indent: -9999px;
	top: 50%;
	width: 30px;
	z-index: 5;

	&:hover {
		/* background-color: rgba(51, 51, 51, 0.3); */
		background: var(--modul-border-hover);
	}

	&:active {
		/* background-color: rgba(51, 51, 51, 0.4); */
		background: var(--modul-border-active);
	}
}

.connection--slider-enabled .connection-controls-next {
	display: block;
}

.connection-controls-next::after {
	border: solid 3px #fff;
	border-bottom: none;
	border-left: none;
	content: '';
	display: block;
	height: 18px;
	margin: -9px -4px 0 0;
	position: absolute;
	right: 50%;
	top: 50%;
	transform: rotate(45deg);
	width: 18px;
}

.connection-scrollbar {
	/* background: #cdccc8; */
	background: var(--modul-border);
	display: none;
	height: 1px;
	margin: 0 -5px;
	position: relative;
}

.connection--slider-enabled .connection-scrollbar {
	display: block;
}

.swiper-scrollbar-drag {
	border-radius: 4px;
	cursor: -webkit-grab;
	cursor: drag; /* stylelint-disable-line */
	display: block;
	height: 2.5rem;
	margin: -1.25rem 0 0 0;
	position: absolute;
	top: 50%;

	&::before {
		/* background: #cdccc8; */
		background: var(--modul-border);
		border-radius: 3px;
		content: '';
		display: block;
		height: 5px;
		left: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all 0.3s ease;
		width: 100%;
	}

	&:hover {
		&::before {
			/* background: #bab8b5; */
			background: var(--modul-border-hover);
			height: 9px;
		}
	}

	&:active {
		&::before {
			/* background: #a7a6a3; */
			background: var(--modul-border-active);
			height: 9px;
		}
	}
}
