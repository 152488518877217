.module-link,
.module-link-single {
	text-decoration: underline;
	width: auto;

	&:hover {
		text-decoration: none;
	}

	&:active {
		background-color: rgba(51, 51, 51, 0.2);
		text-decoration: none;
	}

	&:visited {
		text-decoration: underline;
	}
}

.module-link {
	&,
	&:last-child {
		margin-bottom: -0.166667rem;
		padding-bottom: 0.166667rem;

		&:active {
			margin-bottom: -0.166667rem;
			padding-bottom: 0.166667rem;
		}
	}

	color: inherit;
	display: inline-block;
	font-family: inherit;
	font-size: inherit;

	&:visited {
		color: inherit;
		font-family: inherit;
		font-size: inherit;
	}
}

.module-link-single {
	&,
	&:last-child {
		padding-bottom: 0.166667rem;

		&:active {
			padding-bottom: 0.166667rem;
		}
	}

	color: var(--secondary-color);
	font-family: var(--font-reg);
	margin-left: -0.3333rem;
	margin-right: -0.3333rem;
	padding-left: 0.3333rem;
	padding-right: 0.3333rem;

	&:active {
		margin-left: -0.3333rem;
		margin-right: -0.3333rem;
		padding-left: 0.3333rem;
		padding-right: 0.3333rem;
	}
}

.pointer-event-trigger {
	cursor: pointer;
}
