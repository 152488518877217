.video-gallery {
	h3 {
		color: var(--secondary-color);
		padding: 0 2rem 2.5rem 2rem;
	}

	.row {
		margin-bottom: -0.5rem;

		@media (--desktop-xl) {
			margin-bottom: 0;
		}
	}

	&__wrapper {
		cursor: pointer;
		margin: 0 0.5rem 1rem 0.5rem;
		position: relative;

		@media (--desktop-xl) {
			margin: 0 0.5rem;
		}

		iframe:first-child {
			z-index: 0;
		}

		&:hover {
			a {
				text-decoration: none;
			}
		}

		&.ratio4-3 {
			&::before {
				@apply --aspect-4-3;
			}
		}

		&.ratio16-9 {
			&::before {
				@apply --aspect-16-9;
			}
		}
	}

	&__overlay {
		background-color: rgba(51, 51, 51, 0.8);
		bottom: 0;
		color: #fff;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		transition: all 0.3s ease;

		&:hover {
			background-color: rgba(51, 51, 51, 0.3);
		}
	}

	&__info {
		padding: 0 4rem;
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		&::before {
			background-image: url('/static/c3/g/modules/video-gallery/video_player_white.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
			content: '';
			display: block;
			height: 50px;
			margin: 0 auto 1.5rem;
			width: 50px;
		}

		a {
			padding-bottom: 1.5rem;
		}
	}

	iframe {
		background-color: #000;
		border: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -2;
	}

	img {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	&__description {
		background-color: var(--modul-bg-hover);
		color: var(--secondary-color);
		margin: 0 0.5rem 1rem;
		padding: 1.5rem 1rem;

		@media (--tablet) {
			margin: 0 0.5rem 1rem;
		}

		@media (--desktop) {
			margin: 0 0.5rem 1rem;
		}

		p {
			color: var(--secondary-color);
		}
	}
}
