/* DEFAULT VARIABLES */
:root {
	--primary-color: #e20074;
	--secondary-color: #18214d;

	--default-text: #5d6482;

	--background-color: #eff2f4;
	/* --background-color: #F7F8F9; */
	--modul-bg-hover: #e8ebee;
	--modul-bg-active: #d2d6dd;

	--modul-border: #d2d6dd;
	--modul-border-hover: #b5b9c6;
	--modul-border-active: #979daf;

	--box-shadow: rgba(0, 0, 0, 0.12);
	--box-shadow-hover: rgba(93, 100, 130, 0.36);

	--font-thin: 'TeleGroteskNext-Thin', 'Arial', sans-serif;
	--font-reg: 'TeleGroteskNext-Regular', 'Arial', sans-serif;
	--font-regit: 'TeleGroteskNext-RegularItalic', 'Arial', sans-serif;
	--font-med: 'TeleGroteskNext-Medium', 'Arial', sans-serif;
	--font-medit: 'TeleGroteskNext-MediumItalic', 'Arial', sans-serif;
	--font-bold: 'TeleGroteskNext-Bold', 'Arial', sans-serif;
	--font-ult: 'TeleGroteskNext-ExtraBold', 'Arial', sans-serif; /* Ultra = extra bold */

	--font-icon-s: 'teleicon-solid';
	--font-icon-o: 'teleicon-outline';
	--font-akziden-super: 'akziden-super', 'Arial', sans-serif;

	/* 	--neo-thin: 'TeleNeo-Thin';
	--neo-reg: 'TeleNeo-Regular';
	--neo-regit: 'TeleNeo-RegularItalic';
	--neo-med: 'TeleNeo-Medium';
	--neo-medit: 'TeleNeo-MediumItalic';
	--neo-bold: 'TeleNeo-Bold';
	--neo-ultra: 'TeleNeo-ExtraBold'; */

	/* stylelint-disable */
	--aspect-3-1: {
		content: '';
		display: block;
		padding-top: calc(1 / 3 * 100%);
		width: 100%;
	}

	--aspect-4-3: {
		content: '';
		display: block;
		padding-top: calc(3 / 4 * 100%);
		width: 100%;
	}

	--aspect-8-9: {
		content: '';
		display: block;
		padding-top: calc(9 / 8 * 100%);
		width: 100%;
	}

	--aspect-16-9: {
		content: '';
		display: block;
		padding-top: calc(9 / 16 * 100%);
		width: 100%;
	}

	--aspect-21-9: {
		content: '';
		display: block;
		padding-top: calc(9 / 21 * 100%);
		width: 100%;
	}
	/* stylelint-enable */
}

/* MEDIA */
@custom-media --mobile all and (min-width: 0);
@custom-media --mobileOnly all and (max-width: 480px);
@custom-media --tablet all and (min-width: 481px);
@custom-media --tabletOnly all and (min-width: 481px) and (max-width: 768px);
@custom-media --desktop all and (min-width: 769px);
@custom-media --desktopOnly all and (min-width: 769px) and (max-width: 959px);
@custom-media --desktop-xl all and (min-width: 1152px);
@custom-media --desktop-promo all and (max-width: 1296px);
@custom-media --desktop-960 all and (min-width: 960px);

/* Új pólóméretes media queryk */
@custom-media --xs-size all and (max-width: 575px);
@custom-media --s-size all and (min-width: 576px) and (max-width: 767px);
@custom-media --m-size all and (min-width: 768px) and (max-width: 959px);
@custom-media --l-size all and (min-width: 960px) and (max-width: 1199px);
@custom-media --xl-size all and (min-width: 1200px);
