.device-card .device-card-item--a .list-bullet-points {
	@media (--mobileOnly) {
		display: flex;
		flex-direction: column;
		text-align: left;
	}
}
.device-card .device-card-item--a .list-bullet-points li {
	@media (--mobileOnly) {
		align-self: center;
		width: auto;
	}
}
