/* Magenta1 */
.inline-promo-custom-magenta1__title {
	color: var(--secondary-color);
	padding: 0 0 2.5rem 1.5rem;
}

.inline-promo-custom-magenta1__container {
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	margin: 0 0.5rem;
	max-height: 753px;
	padding: 2rem;
	position: relative;

	@media (--tabletOnly) {
		max-height: 376px;
	}

	@media (--desktop) {
		display: flex;
		height: 205px;
		max-height: none;
		padding: 2rem 1.5rem;
	}

	@media (--desktop-xl) {
		height: 214px;
		max-height: none;
	}
}

.inline-promo-custom-magenta1__content .row {
	height: 100%;

	@media (--desktop) {
		display: flex;
	}
}

.inline-promo-custom-magenta1__content .row div[class^='col-'] {
	padding-bottom: 3rem;

	@media (--desktop) {
		align-items: baseline;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 0;
	}

	@media (--desktop-xl) {
		justify-content: space-between;
	}
}

.inline-promo-custom-magenta1__content .row div[class^='col-'] img {
	margin: 0 auto;
}

.inline-promo-custom-magenta1__content__text {
	color: var(--default-text);
	padding: 0 0 2.5rem;
}

.inline-promo-custom-magenta1__illustration {
	@media (--desktop) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

.inline-promo-custom-magenta1__illustration object {
	height: auto;
	max-height: 156px;
	position: relative;
	width: 100%;

	@media (--tablet) {
		max-height: 144px;
	}

	@media (--desktop) {
		max-height: 175px;
	}

	@media (--desktop-xl) {
		max-height: 166px;
	}
}

.inline-promo-custom-magenta1__content__title {
	color: var(--secondary-color);
	padding: 0 0 1.5rem;
}

.inline-promo-custom-magenta1__cards {
	@media (--tablet) {
		display: flex;
	}

	@media (--desktop) {
		padding-left: 1.5rem;
	}
}

.inline-promo-custom-magenta1__cards > .row {
	@media (--tablet) {
		display: flex;
		flex: 1 auto;
	}
}

.inline-promo-custom-magenta1__cards > .row > div[class^='col-'] {
	@media (--tablet) {
		display: flex;
	}
}

.inline-promo-custom-magenta1__cards__item {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px var(--box-shadow);
	height: 160px;
	padding: 1.8333rem 0;
	text-align: center;
	transition: all 0.3s ease;

	@media (--tablet) {
		height: 148px;
		position: relative;
		width: 100%;
	}

	@media (--desktop) {
		height: 157px;
	}

	@media (--desktop-xl) {
		height: 166px;
	}

	&:hover {
		box-shadow: 0 10px 10px var(--box-shadow-hover);
	}
}

.inline-promo-custom-magenta1__cards__item img,
.inline-promo-custom-magenta1__cards__item object {
	height: 68px;
	width: 68px;

	@media (--desktop) {
		height: 80px;
		width: 80px;
	}
}

.inline-promo-custom-magenta1__cards__item[data-swhref] {
	cursor: pointer;
}

.inline-promo-custom-magenta1__cards__item__text {
	padding: 1.5rem 0 0;
	width: 100%;
}

.inline-promo-custom-magenta1__cards img {
	max-width: 100%;
}

/* stylelint-disable */
.inline-promo-custom-magenta1__cards div[class^='col-'] {
	&:nth-child(1) {
		padding: 0 0.5rem 1rem 0;

		@media (--mobileOnly) (--tabletOnly) {
			padding: 0 0.5rem 1rem 0;
		}

		@media (--tablet) {
			padding: 0 0.5rem 0 0;
		}
	}

	&:nth-child(2) {
		padding: 0 0 1rem 0.5rem;

		@media (--mobileOnly) (--tabletOnly) {
			padding: 0 0 1rem 0.5rem;
		}

		@media (--tablet) {
			padding: 0 0.5rem 0 0.5rem;
		}
	}

	&:nth-child(3) {
		padding: 0 0.5rem 0 0;

		@media (--mobileOnly) (--tabletOnly) {
			padding: 0 0.5rem 0 0;
		}

		@media (--tablet) {
			padding: 0 0.5rem 0 0.5rem;
		}
	}

	&:nth-child(4) {
		padding: 0 0 0 0.5rem;

		@media (--mobileOnly) (--tabletOnly) {
			padding: 0 0 0 0.5rem;
		}

		@media (--tablet) {
			padding: 0 0 0 0.5rem;
		}
	}
}
/* stylelint-enable */
.inline-promo-custom-magenta1__img-wrap {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.inline-promo-custom-magenta1__img-wrap object {
	height: 100%;
	width: 100%;
}
/* Magenta1 */

/* Szolgáltatások */
.inline-promo-custom-services-wrapper {
	background: var(--modul-bg-hover);
	border-radius: 8px;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	position: relative;
	transition: all 0.3s ease;
	width: 'calc(100% - 12px)';
}

.inline-promo-custom-services-wrapper.inline-promo-custom-services-wrapper--shadow {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.18);
	padding-top: 2rem;
}

.inline-promo-custom-services__wrap-top {
	padding: 3rem 1.5rem 3rem;

	@media (--desktop-xl) {
		display: flex;
		justify-content: space-between;
		padding: 2rem 1.5rem 0;
	}
}

.inline-promo-custom-services__wrap-top::before {
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.18);
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	transition: all 0.3s ease;
	width: 100%;
	z-index: -1;
}

.inline-promo-custom-services__wrap-top:hover::before {
	box-shadow: 0 10px 10px var(--box-shadow-hover);
}

.inline-promo-custom-services__wrap-top:active::before {
	box-shadow: 0 10px 10px var(--box-shadow-hover);
}

.inline-promo-custom-services__title {
	color: var(--secondary-color);
	padding: 0 0 2rem 2rem;

	@media (--desktop-xl) {
		flex-basis: 0;
		min-width: 350px;
		overflow: hidden;
		padding: 1rem 0 2rem 2rem;
	}
}

.inline-promo-custom-services__title.inline-promo-custom-services__title--wrap-top {
	padding: 0 0 2rem 0;

	@media (--desktop-xl) {
		padding: 1rem 0 2rem 0;
	}
}

.inline-promo-custom-services__wrap-top-button {
	padding: 0 0 0 6px;
	text-align: left;

	@media (--desktop-xl) {
		padding: 8px 0 0;
	}
}

.inline-promo-custom-services__wrap-bottom {
	padding: 0 0 1rem;

	@media (--desktop) {
		padding: 0 1rem 1rem;
	}
}

.inline-promo-custom-services__item {
	display: flex;
	flex-shrink: 0;
	padding: 0 1.5rem 1rem;
}

.inline-promo-custom-services__item:first-child,
.inline-promo-custom-services__item:nth-child(3) {
	@media (--tablet) {
		padding: 0 0.5rem 1rem 1.5rem;
	}

	@media (--desktop) {
		padding: 0 0.5rem 1rem;
	}
}

.inline-promo-custom-services__item:nth-child(2),
.inline-promo-custom-services__item:nth-child(4) {
	@media (--tablet) {
		padding: 0 1.5rem 1rem 0.5rem;
	}

	@media (--desktop) {
		padding: 0 0.5rem 1rem;
	}
}

.inline-promo-custom-services__panel {
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 var(--box-shadow), 0 1px 1px 0 var(--box-shadow), 0 0 1px 0 var(--box-shadow);
	display: block;
	padding: 2rem 1.5rem;
	text-align: center;
	transition: all 0.3s ease;
	width: 100%;

	&:hover {
		box-shadow: 0 10px 10px var(--box-shadow-hover);
	}

	&:active {
		box-shadow: 0 3px 3px 0 var(--box-shadow-hover), 0 3px 3px 0 var(--box-shadow-hover), 0 3px 3px 0 var(--box-shadow-hover);
	}
}

.inline-promo-custom-services__img-wrap {
	display: block;
	height: 5rem;
	margin: 0 auto 2rem;
	padding: 0;
	position: relative;
	width: 5rem;

	@media (--desktop-xl) {
		height: 7rem;
		width: 7rem;
	}
}

.inline-promo-custom-services__img {
	left: 50%;
	max-height: 100%;
	max-width: 100%;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.inline-promo-custom-services__text-wrap-top {
	color: var(--secondary-color);
	padding: 0 0 21px;
}
/* Szolgáltatások */
