.page-title {
	padding: 0 2rem;

	.parent-container & {
		padding-left: 0;
		padding-right: 0;
	}

	&__title {
		color: var(--secondary-color);
		display: inline-block;
		font-kerning: normal;
		letter-spacing: -0.2px;
		padding-bottom: 3rem;
		text-transform: uppercase;

		.page-title--social-like & {
			position: relative;

			@media (--mobile) {
				padding-right: 10rem;
			}

			@media (--desktop) {
				padding-right: 12rem;
			}
		}
	}

	&__moduletitle {
		color: var(--secondary-color);
		display: inline-block;
		font-kerning: normal;
		letter-spacing: -0.2px;
		padding-bottom: 3rem;
	}

	&__subtitle {
		color: var(--default-text);
		display: inline-block;
		padding-bottom: 3rem;
	}

	&__date {
		color: var(--default-text);
		display: inline-block;
		padding-bottom: 1rem;
		width: auto;
	}

	&__author {
		color: var(--default-text);
		display: inline-block;
		padding-left: 1.58333rem;
		position: relative;
		width: auto;

		@media (--desktop-xl) {
			padding-left: 2rem;
		}

		&::before {
			color: var(--secondary-color);
			content: '\2022';
			display: block;
			left: 0.333333rem;
			position: absolute;
			top: 0;

			@media (--desktop-xl) {
				left: 0.416667rem;
			}
		}
	}

	h1:last-child {
		padding-bottom: 0;
	}

	p:last-child {
		padding-bottom: 0;
	}
}
