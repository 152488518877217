.flowtext {
	position: relative;

	&-content__text,
	&-content__title,
	&-content__subtitle,
	&-content__sub-subtitle {
		display: inherit;
	}
}
