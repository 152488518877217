/*CARD TYPE B (TABLET VIEW IN SLIDER)*/

.device-card--slider.device-card--b-4 .device-card-item--b {
	@media (--desktopOnly) {
		width: 33.333333%;
	}

	@media (--desktop-960) {
		width: 25%;
	}
}

.device-card--slider .device-card-item--b {
	@media (--tabletOnly) {
		text-align: center;
	}
}

.device-card--slider.device-card--b.device-card--b-4 .row {
	@media (--tablet) {
		flex-wrap: nowrap;
	}
}

.device-card--slider .device-card-item--b .device-card-item__panel {
	@media (--tabletOnly) {
		flex-direction: column;
		flex-wrap: wrap;
	}
}

.device-card--slider .device-card-item--b .device-card-item__panel-wrap {
	@media (--tabletOnly) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
	}
}

.device-card--slider .device-card-item--b .device-card-item__img-wrap {
	@media (--tabletOnly) {
		flex: 0 1 100%;
		margin: 0 0 2rem;
		max-width: 100%;
		padding: 0;
		width: 100%;
	}
}

.device-card--slider .device-card-item--b .device-card-item__img-wrap.is-img-content {
	@media (--tabletOnly) {
		display: block;
		overflow: hidden;
	}
}

.device-card--slider .device-card-item--b .device-card-item__img-wrap img {
	@media (--tabletOnly) {
		max-width: 184px;
	}
}

.device-card--slider .device-card-item--b .device-card-item__text-wrap {
	align-items: stretch;
	display: flex;

	@media (--tabletOnly) {
		align-items: stretch;
		display: flex;
		flex-wrap: wrap;
		padding: 0 1rem 0 1.5rem;
		width: 100%;
	}
}

.device-card--slider .device-card-item--b .device-card-item__text-wrap-bottom {
	@media (--tabletOnly) {
		align-items: flex-end;
		display: flex;
	}
}

.device-card--slider .device-card-item--b .device-card-item__text-wrap-bottom > div {
	@media (--tabletOnly) {
		align-items: center;
		display: flex;
		flex-flow: column wrap;
		width: 100%;
	}
}

.device-card--slider .device-card-item--b *[class*='btn-2'],
.device-card--slider .device-card-item--b *[class*='btn-2-5'],
.device-card--slider .device-card-item--b *[class*='btn-3'] {
	@media (--tabletOnly) {
		align-self: center;
		margin: 0 auto;
	}
}

/*CARD TYPE B (TABLET VIEW IN SLIDER)*/
