.m09p-device-card .row {
	display: flex;
	flex-direction: column;

	@media (--desktop) {
		flex-direction: row;
	}
}

.m09p-device-card__title {
	color: var(--secondary-color);
	padding: 0 1.667rem 3rem;
}

.m09p-device-card-item {
	display: flex;
	flex-shrink: 1;
	padding: 0 0.5rem 1rem;
}

.m09p-device-card--slider .m09p-device-card-item {
	flex-shrink: 0;
}

.m09p-device-card-item__panel {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
	display: flex;
	flex-direction: column;
	padding: 2rem 1.5rem;
	transition: all 0.3s ease;
	width: 100%;

	&:hover {
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.19);
	}

	&::after {
		clear: both;
		content: '';
		display: table;
	}
}

.m09p-device-card-item__title {
	color: var(--secondary-color);
	padding-bottom: 2.1rem;
}

.m09p-device-card-item__panel-wrap {
	@media (--tabletOnly) {
		display: flex;
	}
}

.m09p-device-card-item__img-wrap object {
	height: 100%;
	width: 100%;
}

.m09p-device-card-item__img-wrap img {
	height: auto;
	width: 100%;
}

/* responsive image fixed*/
.m09p-device-card-item__img-wrap {
	align-content: stretch;
	display: flex;
	justify-content: center;
	position: relative;

	&::before {
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.m09p-device-card-item__img-wrap div {
	display: flex;
}
/* responsive image fixed*/

.m09p-device-card-item__text-wrap-top {
	flex-direction: column;
}

.m09p-device-card-item__text-wrap-bottom > div {
	display: block;
	width: 100%;
}

.m09p-device-card-item__text {
	padding: 0 0 1.5rem;
}

.m09p-device-card .list-bullet-points {
	padding-bottom: 2.1rem;
}

.m09p-device-card .list-bullet-points li {
	padding: 0 0 0.7rem 1.25rem;
}

.m09p-device-card-item .device-price,
.m09p-device-card-item .service-price {
	margin: 0 0 1.5rem;
	width: 100%;
}

/* CARD A TYPE*/
.m09p-device-card-item--a .m09p-device-card-item__img-wrap {
	flex: 0 1 50%;
	padding: 0 1.5rem 0 0;
	width: 50%;
}

.m09p-device-card-item--a .m09p-device-card-item__text-wrap {
	display: flex;
	flex: 0 1 50%;
	flex-direction: column;
	padding: 0 1rem 0 0;
	width: 50%;
}

.m09p-device-card-item--a .m09p-device-card-item__img-wrap + .m09p-device-card-item__text-wrap {
	padding: 0 1rem 0 1.5rem;
}

.m09p-device-card-item--a .m09p-device-card-item__text-wrap-top {
	display: flex;
	width: 100%;

	@media (--tablet) {
		flex: 1 1 auto;
	}
}
.m09p-device-card-item--a .m09p-device-card-item__text-wrap-bottom {
	align-items: flex-end;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
/* CARD A TYPE*/

/* CARD B TYPE (3 CARDS)*/
.m09p-device-card-item--b {
	text-align: center;
}

.m09p-device-card-item--b .m09p-device-card-item__img-wrap {
	margin: 0 0 2rem;
}

.m09p-device-card-item--b.col-md-4 .m09p-device-card-item__img-wrap img {
	max-width: 100%;
}

.m09p-device-card-item--b .m09p-device-card-item__img-wrap img {
	align-self: center;
	display: flex;
}

.m09p-device-card--b-3 .row {
	flex-direction: column;

	@media (--desktop) {
		flex-direction: row;
	}
}

.m09p-device-card--slider.m09p-device-card--b-3 .row {
	flex-direction: row;
}

.m09p-device-card--b-3 .m09p-device-card-item--b {
	@media (--tabletOnly) {
		text-align: left;
	}
}

.m09p-device-card--b-3 .m09p-device-card-item__img-wrap {
	@media (--tabletOnly) {
		flex: 0 1 50%;
		margin: 0;
		max-width: 184px;
		padding: 0 1.5rem 0 0;
		width: 50%;
	}
}

.m09p-device-card--b-3 .m09p-device-card-item__text-wrap {
	@media (--tabletOnly) {
		display: flex;
		flex: 0 1 50%;
		flex-direction: column;
		padding: 0 1rem 0 1.5rem;
		width: 50%;
	}
}

.m09p-device-card-item__title--hide-tablet {
	display: block;

	@media (--tabletOnly) {
		display: none;
	}
}

.m09p-device-card-item__title--tablet-only {
	display: none;

	@media (--tabletOnly) {
		display: block;
	}
}

.m09p-device-card--b-3 .m09p-device-card-item__text-wrap-top {
	@media (--tabletOnly) {
		display: flex;
		width: 100%;
	}
}

.m09p-device-card--b-3 .m09p-device-card-item__img-wrap + .m09p-device-card-item__text-wrap .m09p-device-card-item__text-wrap-bottom {
	@media (--tabletOnly) {
		align-items: flex-end;
		display: flex;
		flex: 1 1 50%;
		width: 100%;
	}
}
/* CARD B TYPE (3 CARDS)*/

/* CARD B-2 TYPE (4 CARDS)*/
.m09p-device-card--b-4 .row {
	flex-direction: column;

	@media (--tabletOnly) {
		align-content: flex-end;
		align-items: flex-start;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	@media (--desktop) {
		flex-direction: row;
	}
}

.m09p-device-card--b-4 .m09p-device-card-item--b {
	@media (--tabletOnly) {
		width: 49.9%;
	}
}
/* CARD B-2 TYPE (4 CARDS)*/

/* SERVICE CARD C*/
.m09p-device-card--service-c .m09p-device-card-item__img-wrap {
	display: flex;
	flex: 0 1 100%;
	max-width: 248px;
	padding: 0 1.5rem 0 0;
}

.m09p-device-card--service-c .m09p-device-card-item__text-wrap {
	display: flex;
	flex: 0 1 100%;
	flex-direction: column;
	padding: 0 0 0 1.5rem;
}

.m09p-device-card--service-c .m09p-device-card-item__text-wrap-top {
	display: flex;
	flex: 1 1 50%;
}

.m09p-device-card--service-c .m09p-device-card-item__text-wrap-bottom {
	align-items: flex-end;
	display: flex;
	flex: 1 1 50%;
}

.m09p-device-card--service-c .m09p-device-card-item__text-wrap-bottom > div {
	display: flex;
	flex-direction: column;

	@media (--desktop) {
		flex-direction: row;
	}
}

.m09p-device-card--service-c .m09p-device-card-item__text-wrap-bottom > div > div {
	width: 100%;

	@media (--desktop) {
		width: 33%;
	}
}

.m09p-device-card--service-c .m09p-device-card-item__title--mobile {
	display: block;

	@media (--desktop) {
		display: none;
	}
}

.m09p-device-card--service-c .m09p-device-card-item__title--tablet {
	display: none;

	@media (--desktop) {
		display: block;
	}
}
/* SERVICE CARD C*/

/* SERVICE CARD D*/
.m09p-device-card--service-d .m09p-device-card-item__text-wrap {
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
	padding: 0;
	width: 100%;
}

.m09p-device-card--service-d .m09p-device-card-item__text-wrap-top {
	flex: 0 1 auto;
}
/* SERVICE CARD D*/

/* SERVICE CARD E*/
.m09p-device-card--service-e .m09p-device-card-item__text-wrap {
	display: flex;
	flex-direction: column;
	padding: 0;
	width: 100%;
}
/* SERVICE CARD E*/

/*/ SERVICE CARD F*/
.m09p-device-card--service-f .m09p-device-card-item__panel-wrap {
	display: block;
}

.m09p-device-card--service-f .m09p-device-card-item__text-wrap {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: 0;
}

.m09p-device-card--service-f .m09p-device-card-item__img-wrap {
	display: flex;
	flex: 0 1 100%;
	max-width: 248px;
	padding: 0 1.5rem 0 0;
}

.m09p-device-card--service-f .m09p-device-card-item__img-wrap + .m09p-device-card-item__text-wrap {
	padding: 0 0 0 1.5rem;
}

.m09p-device-card--service-f .m09p-device-card-item__text-wrap-top {
	display: flex;
}

.m09p-device-card--service-f .m09p-device-card-item__text-wrap-bottom {
	align-items: flex-end;
	display: flex;
}

.m09p-device-card--service-f .m09p-device-card-item__text-wrap-bottom > div {
	display: flex;
	flex-direction: column;

	@media (--desktop) {
		flex-direction: row;
	}
}

.m09p-device-card--service-f .m09p-device-card-item__text-wrap-bottom > div > div {
	width: 100%;

	@media (--desktop) {
		width: 33%;
	}
}

.m09p-device-card--service-f .m09p-device-card-item__title--mobile {
	display: block;

	@media (--desktop) {
		display: none;
	}
}

.m09p-device-card--service-f .m09p-device-card-item__title--tablet {
	display: none;

	@media (--desktop) {
		display: block;
	}
}
/* SERVICE CARD F*/

/* CARD SCROLLER*/
.m09p-device-card-wrap {
	position: relative;
}

.m09p-device-card--slider-enabled .m09p-device-card-wrap {
	padding: 0 40px;
}

.m09p-device-card__content {
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.m09p-device-card--slider-enabled .m09p-device-card__content {
	padding-bottom: 20px;
}

.m09p-device-card-controls-prev {
	background-color: rgba(51, 51, 51, 0.2);
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
	display: none;
	height: 60px;
	left: 0;
	margin: -50px 0 0 0;
	position: absolute;
	text-indent: -9999px;
	top: 50%;
	width: 30px;
	z-index: 5;

	&:hover {
		background-color: rgba(51, 51, 51, 0.3);
	}

	&:active {
		background-color: rgba(51, 51, 51, 0.4);
	}
}

.m09p-device-card--slider-enabled .m09p-device-card-controls-prev {
	display: block;
}

.m09p-device-card-controls-prev::after {
	border: solid 3px #fff;
	border-right: none;
	border-top: none;
	content: '';
	display: block;
	height: 18px;
	left: 50%;
	margin: -9px 0 0 -4px;
	position: absolute;
	top: 50%;
	transform: rotate(45deg);
	width: 18px;
}

.m09p-device-card-controls-next {
	background-color: rgba(51, 51, 51, 0.2);
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
	display: none;
	height: 60px;
	margin: -50px 0 0 0;
	position: absolute;
	right: 0;
	text-indent: -9999px;
	top: 50%;
	width: 30px;
	z-index: 5;

	&:hover {
		background-color: rgba(51, 51, 51, 0.3);
	}

	&:active {
		background-color: rgba(51, 51, 51, 0.4);
	}
}

.m09p-device-card--slider-enabled .m09p-device-card-controls-next {
	display: block;
}

.m09p-device-card-controls-next::after {
	border: solid 3px #fff;
	border-bottom: none;
	border-left: none;
	content: '';
	display: block;
	height: 18px;
	margin: -9px -4px 0 0;
	position: absolute;
	right: 50%;
	top: 50%;
	transform: rotate(45deg);
	width: 18px;
}

.m09p-device-card-scrollbar {
	background: #cdccc8;
	display: none;
	height: 1px;
	margin: 0 -5px;
	position: relative;
}

.m09p-device-card--slider-enabled .m09p-device-card-scrollbar {
	display: block;
}

.swiper-scrollbar-drag {
	border-radius: 4px;
	cursor: grab;
	display: block;
	height: 2.5rem;
	margin: -1.25rem 0 0 0;
	position: absolute;
	top: 50%;

	&::before {
		background: #cdccc8;
		border-radius: 3px;
		content: '';
		display: block;
		height: 5px;
		left: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all 0.3s ease;
		width: 100%;
	}

	&:hover {
		&::before {
			background: #bab8b5;
			height: 9px;
		}
	}

	&:active {
		&::before {
			background: #a7a6a3;
			height: 9px;
		}
	}
}
/* CARD SCROLLER*/
