.tabbed-container {
	.tab-list-desktop {
		border-bottom: 0.083333rem solid rgba(38, 38, 38, 0.4);
		display: none;

		.tab-list {
			width: 100%;
			display: table;

			.tab-list-item {
				color: var(--secondary-color);
				display: inline-block;
				max-width: 16.666%;
				padding: 0.75rem 1.5rem;
				position: relative;
				text-align: left;
				text-decoration: none;

				&:hover {
					border-bottom: 0.5rem solid var(--modul-border-hover);
					cursor: pointer;
				}

				&:active {
					border-bottom: 0.5rem solid var(--modul-border-active);
				}

				a {
					color: inherit;
					text-decoration: none;
				}

				&--active {
					border-bottom: 0.5rem solid var(--primary-color);
					border-top: none;
					color: var(--primary-color);

					&:hover {
						border-bottom: 0.5rem solid var(--primary-color);
					}

					a {
						font-family: var(--font-bold);
					}
				}
			}
			/* lénia */
			&::after {
				background-color: rgba(38, 38, 38, 0.4);
				content: '';
				display: block;
				height: 0.083333rem;
				position: relative;
				top: -0.083333rem;
				width: 100%;
				z-index: -1;
			}
		}

		.tab-list-content {
			color: var(--default-text);
			font-family: var(--font-reg);
			padding: 2rem;
		}

		.tab-list-content .module-container {
			padding: 0;
		}
	}

	@media (--desktop) {
		.tab-list-mobile {
			display: none;
		}
		.tab-list-desktop {
			display: block;
		}
	}
}
