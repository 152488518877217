/* CARD A TYPE MOBILE-ONLY TYPE B*/
.device-card-item--a {
	@media (--mobileOnly) {
		text-align: center;
	}
}

.device-card-item--a .device-card-item__panel {
	@media (--mobileOnly) {
		flex-direction: column;
		flex-wrap: wrap;
	}
}

.device-card-item--a .device-card-item__panel-wrap {
	@media (--mobileOnly) {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
}

.device-card-item--a .device-card-item__img-wrap {
	@media (--mobileOnly) {
		margin: 0 0 2rem;
		width: 100%;
	}
}

.device-card-item--a .device-card-item__img-wrap img {
	@media (--mobileOnly) {
		max-width: 184px;
	}
}

.device-card-item--a .device-card-item__text-wrap {
	@media (--mobileOnly) {
		align-items: stretch;
		flex-basis: 100%;
	}
}

.device-card--a .device-card-item__text-wrap-bottom > div {
	@media (--mobileOnly) {
		align-items: center;
		display: flex;
		flex-flow: column wrap;
		width: 100%;
	}
}
/* CARD A TYPE MOBILE-ONLY TYPE B*/
