.roaming-app__table {
	border-collapse: collapse;
	color: var(--secondary-color);
	font-family: 'Tele-Grotesk-NextRegular';
	font-size: 20px;
	font-style: normal;
	font-weight: bold;
	line-height: 24px;
	margin: 0 24px;
	max-width: 100%;
	width: 'calc(100% - 48px)';
}

.roaming-app__table tr {
	border-bottom: 1px solid #b5b9c6;
}

.roaming-app__table tr:last-child {
	border-bottom: none;
}

.roaming-app__table td {
	padding: 24px 0;
	width: 50%;
}

.roaming-app__table td:nth-child(even) {
	font-weight: 400;
}

.roaming-app__table td:nth-child(odd) {
	font-weight: 600;
}

@media (--xs-size) {
	.roaming-app__table {
		font-size: 16px;
		line-height: 20px;
	}

	.roaming-app__table td {
		display: block;
		width: 100%;
	}

	.roaming-app__table td:nth-child(odd) {
		padding-bottom: 6px;
	}

	.roaming-app__table td:nth-child(even) {
		padding-top: 6px;
	}
}

@media (--xl-size) {
	.roaming-app__table {
		margin: 0 auto;
		max-width: 1150px;
		width: 100%;
	}
}
