.horizontal-redirector {
	height: auto;
	padding: 0.5rem;
	transition: 0.3s;

	.parent-container & {
		padding-left: 0;
		padding-right: 0;
	}
}

.horizontal-redirector .horizontal-redirector-wrapper {
	box-shadow: 0 0.0833rem 0.1667rem 0 var(--box-shadow);
	padding: 2rem 1.5rem;
	transition: all 0.3s ease;
	width: 100%;

	@media (--tablet) {
		display: flex;
	}

	&:hover {
		box-shadow: 0 0.8333rem 0.8333rem 0 var(--box-shadow-hover);
	}

	&:active {
		box-shadow: 0 0.25rem 0.25rem 0 var(--box-shadow-hover);
	}

	> img,
	> object {
		display: block;
		height: 100%;
		left: 0;
		margin: 0 auto;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
	}
}

/* IMAGE */
.horizontal-redirector .horizontal-redirector-image-wrapper {
	display: block;
	padding-bottom: 2rem;
	width: 120px;

	@media (--tablet) {
		margin-right: 3rem;
		padding-bottom: 0;
	}

	@media (--desktop) {
		align-self: baseline;
		display: flex;
		width: 154px;
	}

	object {
		max-height: 7rem;
		min-width: 120px;
		width: auto;

		@media (--desktop) {
			min-width: 154px;
		}
	}
}
.horizontal-redirector .horizontal-redirector-image-wrapper__image {
	max-height: 7rem;
	max-width: 120px;
	width: auto;

	@media (--desktop) {
		align-self: baseline;
		display: flex;
		max-width: 154px;
	}
}

/* CONTENT */
.horizontal-redirector .horizontal-redirector-content-wrapper {
	display: block;
	width: 100%;

	@media (--tablet) {
		display: inline-block;
		padding-top: 0;
	}

	@media (--desktop) {
		display: flex;
		flex: 1 1 100%;
		flex-direction: row;
		width: auto;
	}
}
.horizontal-redirector .horizontal-redirector-text {
	display: block;
	vertical-align: middle;
	width: 100%;

	@media (--tablet) {
		display: block;
	}

	@media (--desktop) {
		align-self: center;
		display: flex;
		flex-direction: column;
		padding-bottom: 0;
		padding-right: 10px;
	}

	&--only-title {
		@media (--desktop) {
			padding-bottom: 0;
		}
	}
}

.horizontal-redirector .horizontal-redirector-text__title {
	color: var(--secondary-color);
	padding-bottom: 1.5rem;

	&--only-title {
		@media (--desktop) {
			padding-bottom: 0;
		}
	}
}

.horizontal-redirector .horizontal-redirector-text__text {
	color: var(--default-text);
	padding-bottom: 1.5rem;

	@media (--desktop) {
		padding-bottom: 0;
	}
}

/* BUTTON */
.horizontal-redirector .horizontal-redirector-button {
	width: auto;

	@media (--tablet) {
		display: block;
	}

	@media (--desktop) {
		align-self: center;
		display: flex;
		flex: 0 0 auto;
		flex-direction: column;
		text-align: right;
	}
}

.tabbed-container .horizontal-redirector .horizontal-redirector-wrapper > object,
.tabbed-container-extended .horizontal-redirector .horizontal-redirector-wrapper > object,
.tabbed-container .horizontal-redirector .horizontal-redirector-image-wrapper object,
.tabbed-container-extended .horizontal-redirector .horizontal-redirector-image-wrapper object {
	z-index: auto;
}

.tabbed-container .horizontal-redirector-content-wrapper,
.tabbed-container-extended .horizontal-redirector-content-wrapper {
	z-index: 1;
}
