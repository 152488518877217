@import '_value-molecule-fixed.pcss';

.prod-offer {
	border-bottom: 0.083rem solid #d7d6d1;
	clear: both;
	color: var(--secondary-color);
	display: block;
	margin: 0 2rem 3rem 2rem;
	padding-top: 0.5rem;

	.parent-container & {
		margin-left: 0;
		margin-right: 0;
	}

	@media (--tablet) {
		border-bottom: 0.083rem solid #d7d6d1;
	}

	[class*='btn'] {
		margin-bottom: 2rem;
		margin-top: 1.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.prod-tags {
		padding: 0;

		&__title {
			clear: both;
			color: var(--secondary-color);
			padding-bottom: 2rem;

			a {
				color: var(--secondary-color);
				text-decoration: none;
			}
		}

		&__subtitle {
			border-radius: 0.25rem;
			display: table-cell;
			padding-right: 1rem;
			vertical-align: baseline;
			white-space: nowrap;
			width: auto;
		}

		&__holder {
			display: table;
			margin-bottom: 1.5rem;
		}

		&__tag {
			background-color: #fff;
			border-color: #fff;
			border-radius: 0.25rem;
			box-shadow: 0 0.083rem 0.083rem rgba(0, 0, 0, 0.13);
			display: inline-block;
			margin: 0 1.166rem 1rem 0;
			padding: 0.5rem 0.916rem 0.25rem 0.916rem;
			text-transform: none;
			transition: all 0.3s;
			white-space: nowrap;
			width: auto;

			&--magenta {
				color: var(--primary-color);
			}
			&--blue {
				color: rgba(41, 112, 184, 1);
			}
			&--green {
				color: rgba(17, 173, 108, 1);
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.prod-image {
		margin-bottom: 2.5rem;

		img {
			display: block;
			height: auto;
			margin: 0 auto;
			width: 100%;
		}

		object {
			height: 100%;
			width: 100%;
		}

		&[data-swhref] {
			cursor: pointer;
		}
	}

	.prod-desc {
		padding: 0;

		@media (--tablet) {
			padding: 0.5rem 0 0 2.25rem;
		}
		@media (--desktop) {
			padding: 0.5rem 0 0 1.75rem;
		}
		@media (--desktop-xl) {
			padding: 0.5rem 0 0 2.25rem;
		}
	}

	.prod-desc .list-bullet-points {
		padding-bottom: 1.5rem;
	}

	.prod-desc .list-bullet-points li {
		padding: 0 0 1.25rem 1.5rem;
	}

	.prod-colors {
		display: table;
		margin-bottom: 2.5rem;

		&__title {
			padding-bottom: 1rem;
		}

		&__picker {
			border: 0.083rem solid var(--secondary-color);
			border-radius: 8.333rem;
			cursor: pointer;
			float: left;
			height: 2.5rem;
			margin: 0 1.5rem 0 0;
			width: 2.5rem;
		}
	}

	.prod-package {
		margin-bottom: 3rem;
		padding: 0;

		@media (--tablet) {
			padding-left: 3.5rem;
		}
		@media (--desktop) {
			padding-left: 2.5rem;
		}
		@media (--desktop-xl) {
			padding-left: 3.5rem;
		}

		&__title {
			padding-bottom: 2rem;
		}

		&__description {
			padding-bottom: 2rem;
		}

		:not([class*='btn']):last-child {
			padding-bottom: 0;
		}
	}
}
