.interactive-content {
	box-sizing: border-box;
	display: inline-block;
	margin-bottom: 1.666rem;
	min-width: 20rem;
	padding: 0;
	position: relative;
	top: 0;

	+ * {
		margin-top: 0;
	}

	@media (--mobileOnly) {
		width: 100%;
	}

	@media (--tablet) {
		margin-bottom: 0;
	}

	&.right {
		margin-left: 0;

		@media (--tablet) {
			float: right;
			margin-left: 2rem;
		}
	}

	&.left {
		margin-right: 0;

		@media (--tablet) {
			float: left;
			margin-right: 2rem;
		}
	}

	&.full-width {
		margin-bottom: 1.666rem;
		width: 100%;
	}

	&.half-width {
		width: 100%;

		@media (--tablet) {
			width: 50%;
		}
	}

	&.third-width {
		width: 100%;

		@media (--tablet) {
			width: 33%;
		}
	}

	&.fixed-width {
		width: 26.667rem;
	}

	&.is-video {
		min-width: 26.667rem;
	}

	object {
		width: auto;
	}

	img,
	iframe {
		width: 100%;
	}

	&__footer {
		background-color: rgba(51, 51, 51, 0.05);
		display: block;
		float: left;
		padding: 1.666rem;
		width: 100%;

		.interactive-content__text {
			margin: 0;
			padding: 0;
		}
	}

	&__video-container {
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		padding-top: 2.5rem;
		position: relative;

		& iframe,
		& object,
		& embed {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	.video-preview {
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 10;

		&:hover {
			cursor: pointer;

			.video-preview-content-text a {
				text-decoration: none;
			}
		}

		.interactive-content__title,
		.interactive-content__subtitle,
		a {
			text-align: center;
		}

		.video-preview-content {
			background-color: rgba(51, 51, 51, 0.9);
			display: block;
			height: 100%;
			min-height: 6.666rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;

			&:hover .video-preview-content-text a {
				text-decoration: none;
			}

			&-text {
				background-image: url('/static/c3/g/icon-play.png');
				background-position: top center;
				background-repeat: no-repeat;
				background-size: 50px;
				left: 50%;
				min-height: 6.666rem;
				padding: 20px;
				position: absolute;
				top: 54%;
				transform: translateY(-50%) translateX(-50%);
				width: 100%;

				*:first-child {
					margin-top: 3.333rem;
				}

				@media (--desktop) {
					background-size: 6.666rem;

					*:first-child {
						margin-top: 5.833rem;
					}
				}

				.flowtext__title {
					padding-bottom: 0;
					text-transform: none;
				}

				.flowtext__subtitle {
					margin-top: 0;
					text-transform: none;
				}
			}
		}
	}
}

/* aspect ratio kialakítása, úgy, hogy az eddig az aspect-ratio nélkül beszerkesztett elemek megjelenése ne sérüljön */
.interactive-content .interactive-media-element {
	overflow: hidden;
	position: relative;
}

.interactive-content .interactive-media-element.aspect-ratio-16-9 object,
.interactive-content .interactive-media-element.aspect-ratio-4-3 object,
.interactive-content .interactive-media-element.aspect-ratio-16-9 .interactive-content__video-container,
.interactive-content .interactive-media-element.aspect-ratio-4-3 .interactive-content__video-container,
.interactive-content .interactive-media-element.aspect-ratio-16-9 .interactive-content__map,
.interactive-content .interactive-media-element.aspect-ratio-4-3 .interactive-content__map {
	display: block;
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
}

.interactive-content .interactive-media-element.aspect-ratio-16-9 img,
.interactive-content .interactive-media-element.aspect-ratio-4-3 img {
	height: auto;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.interactive-content .interactive-media-element.aspect-ratio-16-9::before {
	@apply --aspect-16-9;
}

.interactive-content .interactive-media-element.aspect-ratio-4-3::before {
	@apply --aspect-4-3;
}

.interactive-content .interactive-media-element.aspect-ratio-no {
	margin-bottom: 20px;
}
